import Sidenav2 from '../components/Sidenav.js';
import SidenavSettings from '../components/SidenavSettings.js';
import CheckNotificationSubscription from '../components/CheckNotificationSubscription.js';
import React, {useEffect, useState, useContext, useRef, useCallback } from 'react';
import Select from 'react-select';
import moment from 'moment-timezone';
import { Helmet } from 'react-helmet';
import { UserContext } from '../user-context.js';
import { useNavigate } from "react-router-dom";
import { Bounce, ToastContainer, toast} from 'react-toastify';
import { API } from '../api-service.js';
import { FaArrowLeft } from "react-icons/fa";
import { PiLinkBreakBold } from "react-icons/pi";
import { AgGridReact} from 'ag-grid-react';
import './css/Settings.css';
import './css/SettingsAccount.css';
import './css/SettingsShortcut.css';
import './css/SettingsTemplate.css';
import './css/SettingsPayment.css';
import './css/SettingsPassword.css';
import './css/SettingsNotifications.css';
import "./css/Chatbot.css";
import "./css/PlansAndBilling.css";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import HelpButton from '../components/HelpButton.js';
import { Link } from 'react-router-dom';
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";
import { IoIosCheckmark } from "react-icons/io";


// Register the modules
ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    MenuModule,
    SetFilterModule
]);


// Main Settings Page ---------------------------------------------------------------
function Settings() {
    const [activeView, setActiveView] = useState('settings');
    const [selectedOption, setSelectedOption] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 867);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 867);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        setActiveView('content');
    };

    const handleBackClick = () => {
        setActiveView('settings');
        setSelectedOption(null);
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>StreamHost | Settings Page</title>
            </Helmet>
            <Sidenav2/>
            <SidenavSettings onSelect={handleOptionSelect} />
            
            {selectedOption === 'general' && <General />}
        </React.Fragment>
    )
}
//General function---------------------------------------------------------------------------------------------
function General() {
    // Keep state of variables
    const [settingsAbout, setAbout] = useState('');
    const [settingsAddress, setAddress] = useState('');
    const [settingsDescription, setDescription] = useState('');
    const [settingsEmail, setEmail] = useState('');
    const [settingsWebsite1, setWebsite1] = useState('');
    const [settingsWebsite2, setWebsite2] = useState('');
    const [settingsImageUrl, setSettingsImageUrl] = useState('');
    const [companyIndustry, setCompanyIndustry] = useState('');
    const industryOption = [ 'UNDEFINED', 'OTHER', 'AUTO', 'BEAUTY', 'APPAREL', 'EDU', 'ENTERTAIN', 'EVENT_PLAN', 'FINANCE', 'GROCERY', 'GOVT', 'HOTEL', 'HEALTH', 'NONPROFIT', 'PROF_SERVICES', 'RETAIL', 'TRAVEL', 'RESTAURANT', 'NOT_A_BIZ']

    const { userToken, profileId, userId, setImageUrl, wabaAccessToken, waPhoneNumberId, about, SystemUserToken } = useContext(UserContext);

    const [activeView, setActiveView] = useState('settings');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 867);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 867);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        // after userToken is set, fetch user profile
		const fetchProfile = async () => {
			try {
				// Fetch user profile
				if (userToken) {
					const data = await API.fetchProfile(userToken);
                    // Set data
                    setSettingsImageUrl(data['image']);
                    setAbout(data['about']);
                    setAddress(data['address']);
                    setDescription(data['company_description']);
                    setEmail(data['email']);
                    setWebsite1(data['company_website1']);
                    setWebsite2(data['company_website2']);
                    setCompanyIndustry(data['company_industry']);

				} else {
					console.error('User token not found');
				}
			} catch (error) {
				console.error('An error occurred while loading user profile:', error);
			}
		};

        fetchProfile();
    }, [userToken])

    // Handle save button (onClick function)
    const saveClick = async() => {
        if (SystemUserToken) {
            const data = {
                'token': userToken,
                'phone_number_id': waPhoneNumberId,
                'waba_access_token': SystemUserToken,
                'id': profileId,
                'about': settingsAbout,
                'address': settingsAddress,
                'email': settingsEmail,
                'company_website1': settingsWebsite1,
                'company_website2': settingsWebsite2,
                'company_industry': companyIndustry,
                'company_description': settingsDescription,
            }
            await API.uploadWhatsappProfile(data);
            await API.updateProfile(data)

            console.log(wabaAccessToken.length);
            for (let i = 0; i < wabaAccessToken.length; i++) { 
                await API.uploadWhatsappProfile({'phone_number_id': waPhoneNumberId[i], 'about': about, 'waba_access_token': SystemUserToken});
            }
            toast.success('Profile updated successfully')
        } else {
            toast.error('Please connect your WhatsApp account first');
            return;
        }
    }
    // Handle image upload
    const handleImageUpload = async (event) => {

        if (SystemUserToken) {
            const file = event.target.files[0];
            const formData = new FormData();
            formData.append('id', profileId);
            formData.append('user_id', userId);
            formData.append('token', userToken);
            formData.append('image', file, file['name']);
            // Send image to backend
            const response = await API.uploadImage(formData);
            setSettingsImageUrl(response['image']);
            setImageUrl(response['image']);
    
            // Update all the whatsapp profile picture user has
            for (let i = 0; i < wabaAccessToken.length; i++) {

                // 1. Create WhatsApp resumable upload session
                const uploadResponse = await API.resumableUpload({'file_name': file['name'], 'file_length':file['size'], 'file_type':file['type'], token: SystemUserToken});
                const uploadSessionId = JSON.parse(uploadResponse)['id'].replace(',', '');
    
                // 2. Upload file to WhatsApp resumable upload session to get the resumable upload id
                const handleData = new FormData()
                handleData.append('file', file)
                handleData.append('upload_id', uploadSessionId)
                handleData.append('waba_access_token', SystemUserToken)
                const uploadHandleResponse = await API.whatsappUploadHandle(handleData);
                const uploadHandle = uploadHandleResponse['h'];

                // 3. Update WhatsApp profile picture 
                const updateProfilePic = await API.whatsappUpdateProfilePic({'phone_number_id': waPhoneNumberId[i], 'waba_access_token': SystemUserToken, 'handle': uploadHandle});
                console.log(updateProfilePic);
                toast.success('Profile picture updated successfully')
            }
        } else {
            toast.error('Please connect your WhatsApp account first');
            return;
        }
    };
    return (
        <React.Fragment>
            <Helmet>
                <title>StreamHost | General </title>
            </Helmet>
            <Sidenav2 />
            <div className = 'sides'><SidenavSettings /></div>
            <main className="settings-main">        
                <div className={`settings-column2 ${(!isMobile || activeView === 'content') ? 'settings-main-active' : ''}`}>
                    {isMobile &&  (
                        <div className="settings-header">
                                <Link to="/main/settings" className="sett-link1">
                                    <FaArrowLeft />
                                </Link>
                            <h1>General</h1>
                        </div>
                    )}
                    <div className="settings-content-area">
                        <div className="main-content-settings">
                            <h2>Profile Picture</h2>
                            <div className="settings-profile-pic">
                                <div className="settings-profile-img">
                                    <img src={settingsImageUrl} alt="" width='120rem'/>
                                    <div className="upload-sec">
                                        <label className='custom-file-upload'>
                                            Choose file
                                            <input type="file" accept="image/*" onChange={handleImageUpload} />
                                        </label>
                                        <span className="upload-text">Maximum upload size: 65 MB</span>
                                    </div>
                                </div>
                            </div>
                
                            <div className="setting-info">                        
                                <div className="input-box-settings">
                                    <div className='input-container'>
                                        <textarea id='about' placeholder="About your business" value={settingsAbout} onChange={(evt) => setAbout(evt.target.value)}></textarea>
                                        <label htmlFor='about'>About</label>
                                    </div>
                                </div>

                                <div className='input-box-select-flex'>
                                    <div className="input-box-address">
                                        <div className='input-container'>
                                            <input id='address' className='settings-address' placeholder="Address" value={settingsAddress} onChange={(evt) => setAddress(evt.target.value)}/>
                                            <label htmlFor='address'>Address</label>
                                        </div>
                                    </div>

                                    <div className="input-box-address">
                                        <div className='input-container'>
                                            <input id='description' className='settings-address' placeholder="Business Description" value={settingsDescription} onChange={(evt) => setDescription(evt.target.value)}/>
                                            <label htmlFor='description'>Description</label>
                                        </div>
                                    </div>
                                </div>

                                <div className='input-box-select-flex'>
                                    <div className="input-box-address">
                                        <div className='input-container'>
                                            <select id='industry' className='settings-address' placeholder="Industry" value={companyIndustry} onChange={(e) => setCompanyIndustry(e.target.value)}>
                                                {industryOption.map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                            </select>
                                            <label htmlFor='industry'>Industry</label>
                                        </div>
                                    </div>

                                    <div className="input-box-address">
                                        <div className='input-container'>
                                            <input id='email' className='settings-address' placeholder="Email" value={settingsEmail} onChange={(evt) => setEmail(evt.target.value)}/>
                                            <label htmlFor='email'>E-mail</label>
                                        </div>
                                    </div>
                                </div>
                
                                <div className="input-box-settings settings-website">
                                    <div className='input-container'>
                                        <input id='website1' placeholder="Website 1" value={settingsWebsite1} onChange={(evt) => setWebsite1(evt.target.value)}/>
                                        <label htmlFor='website1'>Website 1</label>
                                    </div>
                                    <div className='input-container'>
                                        <input id='website2' placeholder="Website2" value={settingsWebsite2} onChange={(evt) => setWebsite2(evt.target.value)}/>
                                        <label htmlFor='website2'>Website 2</label>
                                    </div>
                                </div>
                                
                                <div className="settings-button">
                                    <button className="save-button" onClick={saveClick}>Save</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <HelpButton />
            <ToastContainer position='bottom-center' autoClose={5000} hideProgreeBar={false} closeOnClick pauseOnFocusLoss draggable rtl={false} pauseOnHover theme='colored' transition={Bounce} />
        </React.Fragment>
    )
}     
// ------------------------------------------------------------------------------------


function AccountManagement() {

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedBuilding, setSelectedBuilding] = useState(null);
    const [email, setEmail] = useState('');
    const [userName, setUserName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordType, setPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const [eye_url, setEyeUrl] = useState(require('../assets/password_eye_closed.png'));
    const [confirmEyeUrl, setConfirmEyeUrl] = useState(require('../assets/password_eye_closed.png'));
    const [massFlowOption, setMassFlowOption] = useState([{value: 'All', label: 'All'}]);
    const { userToken, userId, subscriptionPlan, todoListNotification } = useContext(UserContext);
    const gridRef = useRef(null);
    const [gridKey, setGridKey] = useState(0);
    const planUserLimit = {
        'Free': 1,
        'Small': 2,
        'Medium': 5,
        'Large': 10,
    }

    const [activeView, setActiveView] = useState('settings');
    const [selectedOption, setSelectedOption] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 867);

    const [taskTypes, setTaskTypes] = useState([]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 867);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchTaskTypes = async () => {
          try {
            const types = await API.getTaskTypes({'token': userToken});
            setTaskTypes(types);
            console.log(todoListNotification);
          } catch (error) {
            console.error('An error occurred while fetching task types:', error);
            toast.error('Failed to fetch task types');
          }
        };
    
        fetchTaskTypes();
      }, []);

    useEffect(() => {
        if(userToken) {
            const fetchFlowList = async() => {
                try {
                    const data= await API.getChatFlowList(userToken);
                    // Set option for massflow send window
                    setMassFlowOption([...massFlowOption, ...data.filter(chatflow => chatflow.type === 'massflow').map(chatflow => ({value: chatflow.chatflow_name, label: chatflow.chatflow_name}))]);
                } catch(error) {
                    console.error(error);
                    throw(error);
                }
            }
            fetchFlowList();
        }
    }, []);

    useEffect(() => {
        // Fetch created user
        const fetchSecondaryProfile = async() => {
            try {
                const response = await API.fetchSecondaryProfile({'user_id': userId, 'token': userToken});
                const formatData = response.map((data) => (
                    {
                        'id': data.id,
                        'username': data.user.username,
                        'email': data.user.email,
                        'building': data.building,
                        'allow_todo_notifications': data.allow_todo_notifications,
                        'allow_whatsapp_notifications': data.allow_whatsapp_notifications
                    }
                ))

                setRowData(formatData);
            } catch(error) {
                console.error(error);
            }
        }

        fetchSecondaryProfile();
    }, [userId]);

     // Create new account window
     const openModal = (e) => {
        e.stopPropagation();

        if (rowData.length >= planUserLimit[subscriptionPlan] - 1) {
            toast.error('You have reached the maximum number of users for your subscription plan');
            return;
        }
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };


    // AgGrid componenets
    const editAccountBtn = params => {
        const editAccount = async() => {
            const data = {
                'token': userToken,
                'profile_id': params.data.id,
                'building': params.data.building
            }

            try {
                await API.updateSecondaryProfile(data);
                toast.success('Account has been updated');
            } catch(error) {
                console.error(error);
            }

        }
        return <button className='editAccountBtn' onClick={editAccount}>Edit</button>;
    };

    const BuildingCellRenderer = (params) => {
        const [options, setOptions] = useState([]);

        // Function to handle selection change
        const handleChange = (event) => {
            const selectedValue = event.target.value;
            params.data.building = selectedValue;
        };

        useEffect(() => {
            const fetchFlowList = async () => {
                try {
                    const data = await API.getChatFlowList(userToken);
                    // Set option for massflow send window
                    const option = [...massFlowOption, ...data.filter(chatflow => chatflow.type === 'massflow').map(chatflow => ({ value: chatflow.id, label: chatflow.chatflow_name }))];
                    setOptions(option);
                } catch (error) {
                    console.error(error);
                }
            };

            fetchFlowList();
        }, []); 

        return (
            <select defaultValue={params.value} onChange={handleChange}>
                <option key={params.value} value={params.value}>
                    {params.value}
                </option>
                {options.map((option) => (
                    <option key={option.label} value={option.label}>
                        {option.label}
                    </option>
                ))}
            </select>
        );
    };


    // register user button function
    const registerUser = async(event) => {
        event.preventDefault();
        if (!userName || !email || !password || !selectedBuilding.value || !confirmPassword) {
            toast.error('Please fill in all fields')
            return
        }

        if (!validatePassword(password)) {
            toast.error('Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character');
            return;
        }

        if (password !== confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }

        const response = await sendRegisterEmail(email, userName, password);
        if (response && typeof response === 'object') {
            if (response['username'] && response['username'][0] === 'A user with that username already exists.') {
                toast.error('A user with that username already exists.');
                return;
            } else if (response['password'] && response['password'][0] === 'The password is too similar to the email address.') {
                toast.error('The password is too similar to the email address.');
                return;
            }
        }
        toast.success('Registration email has been sent. Please check your email to verify your account.');

        // Creates a user profile based on the newly created user account
        const responseUserId = response['id'];
        const createProfile = await API.addProfile({
                                    'user_id': responseUserId,
                                    'building': selectedBuilding.value, 
                                    'related_user': userId,

                                });
    };

    // Validate password function
    function validatePassword(password) {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
        return regex.test(password);
    }
    // Send email API obtained from api-service.js
    const sendRegisterEmail = async(inputEmail, inputUserName, inputPassword) => {
        const response = await API.sendRegisterEmail({'email': inputEmail, 'username': inputUserName, 'password': inputPassword, 're_password': inputPassword});
        return response;
    }

    // Toggle password visibility
    const togglePasswordVisibility = () => {
        if (passwordType === "password") {
            setPasswordType("text");
            setEyeUrl(require('../assets/password_eye.png'))
        } else {
            setPasswordType("password");
            setEyeUrl(require('../assets/password_eye_closed.png'));
        }
    };

    // Toggle confirm password visibility
    const toggleConfirmPasswordVisibility = () => {
        if (confirmPasswordType === "password") {
            setConfirmPasswordType("text");
            setConfirmEyeUrl(require('../assets/password_eye.png'))
        } else {
            setConfirmPasswordType("password");
            setConfirmEyeUrl(require('../assets/password_eye_closed.png'));
        }
    };
    
    const [gridOptions, setGridOptions] = useState({
        animateRows: true,
        onCellClicked: (params) => {
            if (params.colDef.field) {
                const cell = params.event.target.closest('.ag-cell');
                if (cell) {
                    if (params.node.data.isExpanded) {
                        // Collapse the row back to the original height
                        params.node.setRowHeight(45);
                        params.node.data.isExpanded = false;
                        cell.classList.remove('expanded-cell');  // Remove expanded class
                    } else {
                        // Expand the row to fit the content
                        const cellHeight = cell.scrollHeight;
                        params.node.setRowHeight(cellHeight);
                        params.node.data.cellHeight = cellHeight;
                        params.node.data.isExpanded = true;
                        cell.classList.add('expanded-cell');  // Add expanded class
                    }

                    // Trigger a height recalculation
                    params.api.onRowHeightChanged();
                }
            }
        },
        getRowHeight: (params) => {
            return params.node.data.isExpanded ? params.node.data.cellHeight : 45;
        },
    });


    const [rowData, setRowData] = useState([]);
    const [columnDef, setColumnDef] = useState([
        {field: 'id', resizable: false, hide: true},
        {field: 'username', headerName: 'Username', resizable: true, flex: 1},
        {field: 'email', headerName: 'Email', resizable: true, flex: 1},
        {field: 'building', headerName: 'Building', cellRenderer: BuildingCellRenderer, editable: true, resizable: true, flex: 1},
        {field: 'edit_btn', headerName: 'Edit', cellRenderer: editAccountBtn, resizable: false, flex: 1},
    ].map(col => ({
        ...col,
        wrapText: true,
      })));

    const onFilterTextBoxChanged = useCallback(() => {
        if (gridRef.current && gridRef.current.api) {
            gridRef.current.api.setQuickFilter(
                document.getElementById("filter-text-box").value
            );
        }
    }, []);

    const onRowClicked = useCallback((params) => {
        params.node.setExpanded(!params.node.expanded);
        params.api.resetRowHeights();
    }, []);

    return (
        <React.Fragment>
            <Helmet>
                <title>StreamHost | Account Management </title>
            </Helmet>
            <Sidenav2/>
            <div className = 'sides'><SidenavSettings /></div>
            <div className={`settings-column2 ${(!isMobile || activeView === 'content') ? 'active' : ''}`}>
            <div className='settings-account-main '>
            <div className='password-and-security-header'>
                            {isMobile && (
                                    <Link to="/main/settings" className="sett-link">
                                        <FaArrowLeft />
                                    </Link>
                            )}
                            <h1>Account Management</h1>
                        </div>

                <hr/>
                <button className='settings-add-user-btn' onClick={openModal}>Add User</button>

                <div className='settings-no-account' style={{ display: rowData.length === 0 ? 'block' : 'none' }}>
                    <h2><PiLinkBreakBold /> <span>No account added yet</span></h2>
                </div>
                <div className='settings-account-list ag-theme-quartz' style={{ display: rowData.length === 0 ? 'none' : 'block' }} key={gridKey}>
                    <input
                        type="text"
                        id="filter-text-box"
                        className="quick-filter-input"
                        placeholder="Quick filter..."
                        onInput={onFilterTextBoxChanged}
                    />
                    <AgGridReact 
                    ref={gridRef}
                    rowData={rowData} 
                    columnDefs={columnDef} 
                    gridOptions={gridOptions}
                    onRowClicked={onRowClicked}
                    rowHeight={45}/>
                </div>
                <ToastContainer position='bottom-center' autoClose={5000} hideProgreeBar={false} closeOnClick pauseOnFocusLoss draggable rtl={false} pauseOnHover theme='colored' transition={Bounce} />
            </div>

            {isModalOpen && (
                <div className="account-modal">
                    <div className="account-modal-content">
                        <div className="modal-header">
                            <FaArrowLeft className='modal-backbtn' onClick={closeModal} size={'1.5rem'}/>
                            <h2>Create new user</h2>
                        </div>
                        <div className="modal-title">
                            <h2>Select Building</h2>
                            <div className="settings-account-select"> 
                                <Select className='modal-select' options={massFlowOption} value={selectedBuilding} onChange = {(selectedOption) => {setSelectedBuilding(selectedOption)}}/> 
                            </div>
                            <hr/>
                            <div className="settings-account-details">
                                <h2>Account Details</h2>
                                <div className="modal-inline">
                                    <input type="text" name="email" placeholder="Enter Email" value={email} onChange={(e)=>setEmail(e.target.value)}/>
                                </div>
                                <div className="modal-inline">
                                    <input type="text" name="username" placeholder="Enter Username" value={userName} onChange={(e)=>setUserName(e.target.value)}/>
                                </div>
                                <div className="modal-inline">
                                    <input type={passwordType} name="password" placeholder="Enter Password" value={password} onChange={(e)=>setPassword(e.target.value)}/>
                                    <img src={eye_url} alt="eyes_closed_password" onClick={togglePasswordVisibility} style={{width: '2rem'}}/>
                                </div>
                                <div className="modal-inline">
                                    {/* <label>Confirm Password:</label> */}
                                    <input type={confirmPasswordType} name="password" placeholder="Confirm Password" value={confirmPassword} onChange={(e)=>setConfirmPassword(e.target.value)}/>
                                    <img src={confirmEyeUrl} alt="eyes_closed_password" onClick={toggleConfirmPasswordVisibility} style={{width: '2rem'}}/>
                                </div>
                            </div>
                        </div>
                        <div className='settings-account-btn'>
                                <button onClick={registerUser}>Create User</button>    
                        </div>
                    </div>
                </div>
            )}
        </div>
        </React.Fragment>
    )
}



// Quick Response page --------------------------------------------------------------
function QuickResponse() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { userToken, userId, profileId } = useContext(UserContext);
    const gridRef = useRef(null);
    const [gridKey, setGridKey] = useState(0);
    const [shortcut, setShortcut] = useState('');
    const [file, setFile] = useState(null);
    const [message, setMessage] = useState('');
    const [textAreaHeight, setTextAreaHeight] = useState('auto');

    const [activeView, setActiveView] = useState('settings');
    const [selectedOption, setSelectedOption] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 867);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 867);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleOptionSelect = (option) => {
        setSelectedOption(option);
        if (isMobile) {
            setActiveView('content');
        }
    };

    const handleBackClick = () => {
        setActiveView('settings');
        setSelectedOption(null);
    };


    // Load data
    useEffect(() => {
        // Fetch created user
        const fetchCommand = async() => {
            try {
                const response = await API.fetchCommand({'token': userToken});
                const formatData = response.map((data) => (
                    {
                        'id': data.id,
                        'command': data.command,
                        'text_response': data.text_response,
                        'file_response': data.file_response.split('/').pop()==='null' ? 'null' : data.file_response
                    }
                ))

                setRowData(formatData);
            } catch(error) {
                console.error(error);
            }
        }

        fetchCommand();
    }, [userId]);


    // Create new account window
    const openModal = (e) => {
        e.stopPropagation();
        setIsModalOpen(true);
        setShortcuts([{ id: Date.now(), shortcut: '', message: '' }]);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleInput = (e) => {
        let target = e.target;
        target.style.height = 'auto';
        target.style.height = (target.scrollHeight) + 'px';
    };

    // Display uploaded file
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFile(file);
    };

    // Remove uploaded file
    const handleRemoveFile = () => {
        setFile(null);
    };

    let fileUrl;
    if (file) {
        fileUrl = URL.createObjectURL(file);
    }

    const saveQuickResposne = async() => {
        const commandId = 'COMMAND-'+Date.now();
        const data = {
            'token': userToken,
            'command_id': commandId,
            'command': shortcut,
            'text_response': message,
            'file_response': file,
        }

        const addQuickResponse = await API.addQuickResponse(data);
        console.log('RESPONSE');
        console.log(addQuickResponse);
        if ('error' in addQuickResponse){
            toast.error('Command already exists');
        } else {
            toast.success('Command added successfully');
        }
    }

    const deleteCommandBtn = params => {
        const onClick = async () => {
            try {
                await API.deleteCommand({'token': userToken, 'id': params.data.id})
                
                setRowData(currentRowData => {
                    // Correctly return the filtered array to update the state
                    return currentRowData.filter(object => object.id !== params.data.id);
                });
            } catch(error) {
                console.error(error);
            }
        };
        return <button className='commandDeleteBtn' onClick={ onClick }>Delete</button>;
    };

    const [gridOptions, setGridOptions] = useState({
        animateRows: true,
        onCellClicked: (params) => {
            if (params.colDef.field) {
                const cell = params.event.target.closest('.ag-cell');
                if (cell) {
                    if (params.node.data.isExpanded) {
                        // Collapse the row back to the original height
                        params.node.setRowHeight(45);
                        params.node.data.isExpanded = false;
                        cell.classList.remove('expanded-cell');  // Remove expanded class
                    } else {
                        // Expand the row to fit the content
                        const cellHeight = cell.scrollHeight;
                        params.node.setRowHeight(cellHeight);
                        params.node.data.cellHeight = cellHeight;
                        params.node.data.isExpanded = true;
                        cell.classList.add('expanded-cell');  // Add expanded class
                    }

                    // Trigger a height recalculation
                    params.api.onRowHeightChanged();
                }
            }
        },
        getRowHeight: (params) => {
            return params.node.data.isExpanded ? params.node.data.cellHeight : 45;
        },
    });
    

    const [rowData, setRowData] = useState([]);
    const [columnDef, setColumnDef] = useState([
        {field: 'id', resizable: false, hide: true},
        {field: 'command', headerName: 'Command', resizable: true, flex: 1},
        {field: 'text_response', headerName: 'Text Response', resizable: true, flex: 1},
        {field: 'file_response', headerName: 'File Response', resizable: true, flex: 1},
        {field: 'delete_btn', headerName: 'Delete', cellRenderer: deleteCommandBtn, resizable: false, flex: 1},
    ].map(col => ({
        ...col,
        wrapText: true,
      })));

    const onFilterTextBoxChanged = useCallback(() => {
        if (gridRef.current && gridRef.current.api) {
            gridRef.current.api.setQuickFilter(
                document.getElementById("filter-text-box").value
            );
        }
    }, []);

    const onRowClicked = useCallback((params) => {
        params.node.setExpanded(!params.node.expanded);
        params.api.resetRowHeights();
    }, []);

        const [shortcuts, setShortcuts] = useState([]);
        const textareaRefs = useRef([]);
      
        useEffect(() => {
          textareaRefs.current = textareaRefs.current.slice(0, shortcuts.length);
        }, [shortcuts]);
      
      
        const handleShortcutChange = (index, field, value) => {
          const updatedShortcuts = [...shortcuts];
          updatedShortcuts[index][field] = value;
          setShortcuts(updatedShortcuts);
        };
      
    
        return (
            <React.Fragment>
            <Helmet>
                <title>StreamHost | Quick Response </title>
            </Helmet>
                <Sidenav2/>
                <div className="sides"><SidenavSettings /></div>
                <div className={`settings-column2 ${(!isMobile || activeView === 'content') ? 'active' : ''}`}>
                    <div className='settings-account-main'>
                        <div className='password-and-security-header'>
                            {isMobile && (
                                <Link to="/main/settings" className="sett-link">
                                    <FaArrowLeft />
                                </Link>
                            )}
                            <h1>Quick Response Commands</h1>
                        </div>
                        <hr/>
                        <button className='settings-add-user-btn' onClick={openModal}>Add Command</button>
                        <div className='settings-account-list ag-theme-quartz' style={{ display: rowData.length === 0 ? 'none' : 'block' }} key={gridKey}>
                            <input
                                type="text"
                                id="filter-text-box"
                                className="quick-filter-input"
                                placeholder="Quick filter..."
                                onInput={onFilterTextBoxChanged}
                            />
                            <AgGridReact 
                                ref={gridRef}
                                rowData={rowData} 
                                columnDefs={columnDef} 
                                gridOptions={gridOptions}
                                onRowClicked={onRowClicked}
                                rowHeight={45}
                            />
                        </div>
                        <ToastContainer position='bottom-center' autoClose={5000} hideProgressBar={false} closeOnClick pauseOnFocusLoss draggable rtl={false} pauseOnHover theme='colored' transition={Bounce} />
                    </div>
    
                    {isModalOpen && (
                        <main className={`test-modal-main ${isModalOpen ? 'open' : ''}`}>
                            <div className='modal-container'>
                                <div className='modal'>
                                    <div className='test-modal-header'>
                                        <FaArrowLeft style={{'fontSize': '1.5rem'}} className='test-modal-icon' onClick={closeModal}/>
                                        <h2>New shortcut</h2>
                                    </div>
                                    <div className='test-modal-shortcut'>
                                        <div className='shortcut-div visible'>
                                            <div className='shortcut-info'>
                                                <h3>Shortcut</h3>
                                                <div className="modal-quick-response">
                                                    <input 
                                                        type="text" 
                                                        name="shortcut" 
                                                        placeholder="Enter Shortcut" 
                                                        value={shortcut}
                                                        onChange={(e) => setShortcut(e.target.value)}
                                                    />
                                                </div>
                                                <div className="modal-quick-response">
                                                    <textarea 
                                                        id="msg" 
                                                        name="msg" 
                                                        placeholder="Enter Message" 
                                                        value={message}
                                                        onChange={(e) => setMessage(e.target.value)}
                                                        style={{ height: textAreaHeight, resize: 'none', userSelect: 'none' }} 
                                                        onInput={handleInput} 
                                                        draggable='false'
                                                    />
                                                </div>
                                            </div>
                                            <label>File (Optional):</label>
                                            <div className="modal-quick-response quick-response-file">
                                                <input type="file" onChange={handleFileChange} />
                                                {file && file.type.startsWith('image/') && <img src={URL.createObjectURL(file)} alt="Preview" className="mediaFile-style" width="65%"/>}
                                                {file && file.type === 'application/pdf' && <object data={URL.createObjectURL(file)} type="application/pdf" className="borderRadius" width="65%"></object>}
                                                {file && file.type.startsWith('video/') && <video controls src={URL.createObjectURL(file)} className="borderRadius" width="65%"></video>}
                                                {file && <button onClick={handleRemoveFile} className="removeFileButton">Remove File</button>}
                                            </div>
                                        </div>
    
                                        <div className='test-modal-add-btn'>
                                            <button className='test-modal-btn' onClick={saveQuickResposne}>Save shortcut</button>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        </main>
                    )}
                </div>
            </React.Fragment>
        )
    }
// ------------------------------------------------------------------------------------


// WhatsApp Template page -----------------------------------------------------------
function WhatsAppTemplate() {

    const { wabaId, userId, SystemUserToken } = useContext(UserContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [templateName, setTemplateName] = useState('');
    const [templateHeader, setTemplateHeader] = useState('');
    const [headerParameter, setHeaderParameter] = useState('');
    const [actualHeaderParameter, setActualHeaderParameter] = useState('');
    const [templateBody, setTemplateBody] = useState('');
    const [bodyParameters, setBodyParameters] = useState([]);
    const [file, setFile] = useState(null);
    const [headerTextVisibility, setHeaderTextVisibility] = useState(true);
    const [headerFileVisibility, setHeaderFileVisibility] = useState(true);

    const [activeView, setActiveView] = useState('settings');
    const [selectedOption, setSelectedOption] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 867);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 867);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const [gridOptions, setGridOptions] = useState({
        animateRows: true,
        onCellClicked: (params) => {
            if (params.colDef.field) {
                const cell = params.event.target.closest('.ag-cell');
                if (cell) {
                    if (params.node.data.isExpanded) {
                        // Collapse the row back to the original height
                        params.node.setRowHeight(45);
                        params.node.data.isExpanded = false;
                        cell.classList.remove('expanded-cell');  // Remove expanded class
                    } else {
                        // Expand the row to fit the content
                        const cellHeight = cell.scrollHeight;
                        params.node.setRowHeight(cellHeight);
                        params.node.data.cellHeight = cellHeight;
                        params.node.data.isExpanded = true;
                        cell.classList.add('expanded-cell');  // Add expanded class
                    }

                    // Trigger a height recalculation
                    params.api.onRowHeightChanged();
                }
            }
        },
        getRowHeight: (params) => {
            return params.node.data.isExpanded ? params.node.data.cellHeight : 45;
        },
    });

    const onRowClicked = useCallback((params) => {
        params.node.setExpanded(!params.node.expanded);
        params.api.resetRowHeights();
    }, []);

    useEffect(() => {
        const getWhatsAppTemplate = async() => {
            setIsLoading(true);
            try {
                const response = await fetch('https://graph.facebook.com/v20.0/'+ wabaId +'/message_templates?fields=quality_score,name,components,status', {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + SystemUserToken,
                    }
                });

                const resp = await response.json();
                console.log(resp);
                const templateList = resp.data?.map((template) => {
                    return {
                        'name': template.name,
                        'status': template.status,
                        'header text': template.components[0].text,
                        'body text': template.components[1].text,
                    }
                });
                setRowData(templateList);
            } catch (error) {
                console.error('Error fetching WhatsApp templates:', error);
                toast.error('Failed to fetch WhatsApp templates');
            } finally {
                setIsLoading(false);
            }
        }

        getWhatsAppTemplate();
    }, [userId, wabaId, SystemUserToken]);


    const createWhatsAppTemplate = async() => {
        setIsSaving(true);
        try {
        let components = [];

        // 1. Handle template file
        if (file !== null) {
            // Determine the file type and set the format accordingly
            const fileType = file.type.split('/')[0];
            let format = '';
            if (fileType === 'image') {
                format = 'IMAGE';
            } else if (fileType === 'video') {
                format = 'VIDEO';
            } else if (fileType === 'application') {
                format = 'DOCUMENT';
            }

            const uploadResponse = await API.resumableUpload({'file_name': file['name'], 'file_length':file['size'], 'file_type':file['type'], token: SystemUserToken});
            const uploadSessionId = JSON.parse(uploadResponse)['id'].replace(',', '');

            // 2. Upload file to WhatsApp resumable upload session to get the resumable upload id
            const handleData = new FormData()
            handleData.append('file', file)
            handleData.append('upload_id', uploadSessionId)
            handleData.append('waba_access_token', SystemUserToken)
            const uploadHandleResponse = await API.whatsappUploadHandle(handleData);
            const uploadHandle = uploadHandleResponse['h'];

            components.push({
                "type": "HEADER",
                "format": format,
                "example": {
                  "header_handle": [uploadHandle]
                }
            })
        }
        
        // 2. Handle template header
        
        if (templateHeader !== '') {
            let headerComponent = {
                "type": "HEADER",
                "format": "TEXT",
                "text": templateHeader
            };

            if (actualHeaderParameter !== '') {
                headerComponent['example'] = {
                    "header_text": [
                        actualHeaderParameter
                    ]
                }
            }
            components.push(headerComponent);
        }

        // 3. Handle template body
        let bodyComponent = {
            "type": "BODY",
            "text": templateBody
        };
        if (bodyParameters !== null && Array.isArray(bodyParameters)) {
            console.log(bodyParameters);
            bodyComponent['example'] = {
                "body_text": [bodyParameters]
            };
        }
        components.push(bodyComponent);
        console.log(JSON.stringify({
            "name": templateName,
            "language": "en_US",
            "category": "UTILITY",
            "components": JSON.stringify(components)
          }));

        // 4. Create whatsapp template
        const response = await fetch('https://graph.facebook.com/v20.0/'+ wabaId +'/message_templates', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + SystemUserToken,
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                "name": templateName,
                "language": "en_US",
                "category": "UTILITY",
                "components": JSON.stringify(components)
              })
        })
        const resp = await response.json();
        console.log(resp);
        if (resp['id']) {
            toast.success('Template added successfully');
            closeModal();
        } else {
            toast.error(resp['error']['error_user_msg']);
        }
    } catch (error) {
        console.error('Error creating template:', error);
        toast.error('Failed to create template');
    } finally {
        setIsSaving(false);
    }
};

    const openModal = (e) => {
        e.stopPropagation();
        setIsModalOpen(true);
    };
    const closeModal = () => {
        setIsModalOpen(false);
    };

    // Display uploaded file
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFile(file);

        if (file!==null) {
            setHeaderFileVisibility(true);
            setHeaderTextVisibility(false);
        }
    };

    // Remove uploaded file
    const handleRemoveFile = () => {
        setFile(null);
        setHeaderFileVisibility(true);
        setHeaderTextVisibility(true);
    };

    let fileUrl;
    if (file) {
        fileUrl = URL.createObjectURL(file);
    }
    
    const handleTemplateHeaderChange = (e) => {
        if (e.target.value !== '') {
            setHeaderTextVisibility(true);
            setHeaderFileVisibility(false);
        } else {
            setHeaderTextVisibility(true);
            setHeaderFileVisibility(true);
        }
        setTemplateHeader(e.target.value);
        const templateParameters = e.target.value.match(/{{(.*?)}}/g);
        if (templateParameters?.length < 2 || templateParameters === null) {
            setHeaderParameter(templateParameters);
        } else {
            toast.error('There can only be one parameter in the header text');
        }
    }

    const handleTemplateBodyChange = (e) => {
        setTemplateBody(e.target.value);
        const templateParameters = e.target.value.match(/{{(.*?)}}/g);
        setBodyParameters(templateParameters);
    }

    // Ag grid components -----------------------------------------------------------
    const deleteTemplateButton = params => {
        const onClick = async () => {
            try {
                const response = await fetch('https://graph.facebook.com/v20.0/'+ wabaId +'/message_templates?name='+params.data.name, {
                    method: 'DELETE',
                    headers: {
                        'Authorization': 'Bearer ' + SystemUserToken,
                    }
                })
                
                setRowData(currentRowData => {
                    // Correctly return the filtered array to update the state
                    return currentRowData.filter(object => object.name !== params.data.name);
                });
            } catch(error) {
                console.error(error);
            }
        };
        return <button className='template-delete-btn' onClick={ onClick }>Delete</button>;
    };

    const [rowData, setRowData] = useState([]);
    const gridRef = useRef(null);
    const [columnDef, setColumnDef] = useState([
        {field: 'name', headerName: 'Template Name', resizable: false, flex: 1},
        {field: 'header text', headerName: 'Header Text', resizable: true, flex: 2},
        {field: 'body text', headerName: 'Body Text', resizable: true, flex: 2},
        {field: 'status', headerName: 'Template Status', resizable: false, flex: 1},
        {field: 'dlt_btn', headerName: 'Delete', cellRenderer: deleteTemplateButton, resizable: false, flex: 1},
    ].map(col => ({
        ...col,
        wrapText: true,
      })));

    const onFilterTextBoxChanged = useCallback(() => {
        if (gridRef.current && gridRef.current.api) {
            gridRef.current.api.setQuickFilter(
                document.getElementById("filter-text-box").value
            );
        }
    }, []);
    // ------------------------------------------------------------------------------------

    return (
        <React.Fragment>
            <Helmet>
                <title>StreamHost | WhatsApp Template </title>
            </Helmet>
            <Sidenav2/>
            <div className = 'sides'><SidenavSettings /></div>
            <div className={`settings-column2 ${(!isMobile || activeView === 'content') ? 'active' : ''}`}>
            <div className='settings-account-main'>
            <div className="settings-header">
                {isMobile && (
                        <Link to="/main/settings" className="sett-link">
                            <FaArrowLeft />
                        </Link>
                )}
                <h1>WhatsApp Template Messages</h1>
            </div>
                <hr/>
                <button className='add-whatsapp-template-btn' onClick={openModal}>Add Template</button>
                {isLoading ? (
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                    ) : (
                        <div className='whatsapp-template-list ag-theme-quartz'>
                            <input type="text" id="filter-text-box" className="quick-filter-input" placeholder="Quick filter..." onInput={onFilterTextBoxChanged}/>
                            <AgGridReact ref={gridRef} rowData={rowData} columnDefs={columnDef} gridOptions={gridOptions} onRowClicked={onRowClicked} rowHeight={45}/>
                        </div>
                    )}
                <ToastContainer position='bottom-center' autoClose={5000} hideProgreeBar={false} closeOnClick pauseOnFocusLoss draggable rtl={false} pauseOnHover theme='colored' transition={Bounce} />
            </div>

            {isModalOpen && (
                <main className={`test-modal-main ${isModalOpen ? 'open' : ''}`}>
                    <div className='modal-container'>
                        <div className='modal'>
                            <div className='test-modal-header'>
                                <FaArrowLeft style={{ 'fontSize': '1.5rem' }} className='test-modal-icon' onClick={closeModal} />
                                <h2>New Template</h2>
                            </div>
                            <div className='test-modal-shortcut'>
                                <div className='shortcut-div visible'>
                                    <div className='shortcut-info'>
                                        <h3>Template Name</h3>
                                        <div className="modal-quick-response">
                                            <input type="text" name="templateName" placeholder="Enter Template Name" value={templateName} onChange={(e) => setTemplateName(e.target.value)} />
                                        </div>

                                        <h3 style={{'display': headerTextVisibility ? 'block': 'none'}}>Message Header</h3>
                                        <div style={{'display': headerTextVisibility ? 'block': 'none'}} className="modal-quick-response">
                                            <textarea placeholder="Welcome to {{1}}" value={templateHeader} onChange={handleTemplateHeaderChange} style={{ height: '100px', resize: 'vertical' }}/>
                                        </div>

                                        <h3 style={{'display': headerFileVisibility ? 'block': 'none'}}>File (Optional):</h3>
                                        <div style={{'display': headerFileVisibility ? 'block': 'none'}} className="modal-quick-response quick-response-file">
                                            <input type="file" onChange={handleFileChange} />
                                            {file && file.type.startsWith('image/') && <img src={fileUrl} alt="Preview" className="mediaFile-style" width="65%" />}
                                            {file && file.type === 'application/pdf' && <object data={fileUrl} type="application/pdf" className="borderRadius" width="65%"></object>}
                                            {file && file.type.startsWith('video/') && <video controls src={fileUrl} className="borderRadius" width="65%"></video>}
                                            {file && <button onClick={handleRemoveFile} className="removeFileButton">Remove File</button>}
                                        </div>
                                        {headerParameter?.length > 0 && (
                                            <div className="modal-param">
                                                <h3>Header Parameters:</h3>
                                                <input placeholder="value" onChange={(e) => setActualHeaderParameter(e.target.value)} />
                                            </div>
                                        )}
                                        <h3>Message Body</h3>
                                        <div className="modal-quick-response">
                                            <textarea placeholder="Hello {{1}}, We have received your bookings on {{2}}" value={templateBody} onChange={handleTemplateBodyChange} style={{ height: '100px', resize: 'vertical' }}/>
                                        </div>
                                        
                                        {bodyParameters?.length > 0 && (
                                            <div className="modal-param">
                                                <h3>Body Parameters:</h3>
                                                {bodyParameters && bodyParameters.map((parameter, index) => (
                                                    <input key={index} placeholder="value" onChange={(e) => { const newBodyParameters = [...bodyParameters]; newBodyParameters[index] = e.target.value; setBodyParameters(newBodyParameters);}}/>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                </div>
                                
                                <div className='test-modal-add-btn'>
                                <button 
                                    className='test-modal-btn' 
                                    onClick={createWhatsAppTemplate}
                                    disabled={isSaving}
                                >
                                    {isSaving ? 'Loading...' : 'Save Template'}
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            )}
        </div>
        </React.Fragment>
    )

}

// ------------------------------------------------------------------------------------

// Plans and Billing page -----------------------------------------------------------
function PlansAndBilling() {

    const [activeTab, setActiveTab] = useState('plans');
    const { userToken, userId, contactsCount, subscriptionPlan, translateFunction, chatGPTFunction } = useContext(UserContext);
    const [contactsLength, setContactsLength] = useState(0) ;
    const [wabaCount, setWabaCount] = useState(0);
    const [currentPlan, setCurrentPlan] = useState(null);
    const [userCount, setUserCount] = useState(0);
    const [userData, setUserData] = useState([]);
    const [activeView, setActiveView] = useState('settings');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 867);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 867);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const fetchAccountData = async () => {
            try {
                // Fetch the number of customer contacts
                const data = await API.getClientUserList(userToken);
                const dataLength = data.length;
                setContactsLength(dataLength);

                // Fetch user data
                const userDataResponse = await API.fetchSecondaryProfile({'user_id': userId, 'token': userToken});
                setUserData(userDataResponse);
                setUserCount(userDataResponse.length);
                
                // Fetch the number of WABA
                const wabaData = await API.getWabaId(userToken);
                setWabaCount(wabaData.length);

                if (subscriptionPlan) {
                    setCurrentPlan(subscriptionPlan);
                } else {
                    setCurrentPlan("Free"); // Default to "Free" if no main subscription is found
                }

            } catch (error) {
                console.error('Error fetching account data:', error);
                setCurrentPlan("Free"); // Set to "Free" if there's an error
            }
        };
    
        fetchAccountData();
    }, [userToken]);

    // Account Data Section -----------------------------------------------------------------
    const AccountData = () => {
        const plans = {
            "Free": { name: "Free", contacts: 100,users: 1, whatsappAccounts: 1, },
            "Small": { name: "Small", contacts: 5000, users: 2, whatsappAccounts: 2, onboardingSupport: true, freeTrial: true, },
            "Medium": { name: "Medium", contacts: 10000, users: 5, whatsappAccounts: 2, onboardingSupport: true, customerSupport: "WhatsApp chat and email for customer support", freeTrial: true,},
            "Large": { name: "Large", contacts: 20000, users: 10, whatsappAccounts: 2, onboardingSupport: true, customerSupport: "WhatsApp chat and email for customer support", freeTrial: true,},
        };
          
        const renderProgressBar = (current, max) => {
            const percentage = (current / max) * 100;
            return (
                <div className="progress-bar">
                    <div className="progress" style={{width: `${percentage}%`}}></div>
                </div>
            );
        };

        if (!currentPlan || !plans[currentPlan]) {
            return <div>Loading plan data...</div>;
        }

        return (
            <div className="account-data">
                <h2>Account Data</h2>
                <p>Current Plan: {currentPlan ? plans[currentPlan].name : 'Loading...'}{currentPlan === "Free" && " (Free Plan)"}</p>
                <div className="plan-data">
                    <h3>{currentPlan ? plans[currentPlan].name : 'Loading...'} <span>Current Plan</span></h3>
                    
                    <div class="plan-data-item">
                        <div class="plan-data-item-header">
                            <div class="plan-data-item-title">Customer Contacts</div>
                                <div class="plan-data-item-bar">
                                    <div class="progress-bar">
                                        {renderProgressBar(contactsLength, plans[currentPlan]?.contacts)}
                                    </div>
                                    <div class="plan-data-item-details">
                                        Contact Saved: {contactsLength} / {plans[currentPlan]?.contacts}
                                    </div>
                                </div>
                            </div>
                        </div>  
    
                    <div className="plan-data-item">
                        <div class="plan-data-item-header">
                            <div class="plan-data-item-title">User</div>
                                <div class="plan-data-item-bar">
                                    <div class="progress-bar">
                                        {renderProgressBar(userCount, plans[currentPlan]?.users)}
                                    </div>
                                    <div className="plan-data-item-details">
                                        User Account Created: {userCount} / {plans[currentPlan]?.users}
                                    </div>
                                </div>
                            </div>
                        </div>  
    
                    <div className="plan-data-item">
                        <div class="plan-data-item-header">
                            <div className="plan-data-item-title">WhatsApp Business Account</div>
                                <div class="plan-data-item-bar">
                                    <div class="progress-bar">
                                        {renderProgressBar(wabaCount, plans[currentPlan]?.whatsappAccounts)}
                                    </div>
                                    <div className="plan-data-item-details">
                                        WhatsApp Business Account Created: {wabaCount} / {plans[currentPlan]?.whatsappAccounts}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        );
    };
    // ------------------------------------------------------------------------------------


    // Plans Section ---------------------------------------------------------------------
    const Plans = () => {
        const planOrder = ["Free", "Small", "Medium", "Large"];
        const translatePrice = {'Free': 75, 'Small': 75, 'Medium': 150, 'Large': 300};
        const translatePlan = {'Free': 'small_translate', 'Small': 'small_translate', 'Medium': 'medium_translate', 'Large': 'large_translate'};
        const gptPlan = {'Free': 'small_chatgpt', 'Small': 'small_chatgpt', 'Medium': 'medium_chatgpt', 'Large': 'large_chatgpt'};
        const gptPrice = {'Free': 500, 'Small': 500, 'Medium': 1000, 'Large': 1800};
        const [userAddOn, setUserAddOn] = useState(1);
        const [accountAddOn, setAccountAddOn] = useState(1);
        const [contactAddOn, setContactAddOn] = useState(1);
        const [smallPrice, setSmallPrice] = useState('RM399');
        const [mediumPrice, setMediumPrice] = useState('RM799');
        const [largePrice, setLargePrice] = useState('RM1,399');
        const navigate = useNavigate();

        const getButtonText = (planName) => {
            if (currentPlan === planName) return 'Cancel Plan';
            const currentIndex = planOrder.indexOf(currentPlan);
            const planIndex = planOrder.indexOf(planName);
            return planIndex > currentIndex ? 'Upgrade Plan' : 'Downgrade Plan';
        };

        const handleUserAddOnChange = (e) => {
            e.preventDefault();
            const value = e.target.value.replace(/\D/g, '');
            setUserAddOn(value);
        }

        const cancelSubscription = async(planName) => {
            const response = await API.cancelSubscription({'token': userToken, 'plan_type': planName});
            console.log(response);
            toast.success('Subscription cancelled successfully');
        }

        return (
            <div>
                    <section className='pricing-section' id='pricing'>
                        <div className='pricing-body-setting'>
                            <div className='pricing-free'>
                                <div className='pricing-content-header'>
                                    <p>Free</p>
                                    <h1><span className='price-setting'>RM 0</span> /month</h1>
                                    <button onClick={() => navigate('/login')}>Sign up now</button>
                                </div>
                                <div className='pricing-content-body'>
                                    <p><IoIosCheckmark className='tick-icon'/>25 customer contacts</p>
                                    <p><IoIosCheckmark className='tick-icon'/>Unlimited user</p>
                                    <p><IoIosCheckmark className='tick-icon'/>1 WhatsApp Business Accounts</p>
                                    <p><IoIosCheckmark className='tick-icon'/>Free onboarding support</p>
                                    <p><IoIosCheckmark className='tick-icon'/>Basic support</p>
                                </div>
                            </div>
                            {/* Small Package */}
                            <div className='pricing-small'>
                                <div className='pricing-content-header'>
                                    <p>Small</p>
                                    <h1><span className='price-setting'> {smallPrice}</span> /month</h1>
                                    <button onClick={() => navigate('/login')}>Sign up now</button>
                                </div>
                                <div className='pricing-content-body'>
                                    <p><IoIosCheckmark className='tick-icon'/>5,000 customer contacts</p>
                                    <p><IoIosCheckmark className='tick-icon'/>Unlimited user</p>
                                    <p><IoIosCheckmark className='tick-icon'/>2 WhatsApp Business Accounts</p>
                                    <p><IoIosCheckmark className='tick-icon'/>Free onboarding support</p>
                                    <p><IoIosCheckmark className='tick-icon'/>Basic support</p>
                                </div>
                            </div>

                            {/* Medium package */}
                            <div className='pricing-medium'>
                                <div className='pricing-content-header'>
                                    <div className='tier'>
                                        <p>Medium</p>
                                        <div className='popular-tag'>
                                            Most Popular
                                        </div>
                                    </div>
                                    <h1><span className='price-setting'>{mediumPrice}</span> /month</h1>
                                    <button onClick={() => navigate('/login')}>Sign up now</button>
                                </div>
                                <div className='pricing-content-body'>
                                    <p><IoIosCheckmark className='tick-icon'/>10,000 customer contacts</p>
                                    <p><IoIosCheckmark className='tick-icon'/>Unlimited user</p>
                                    <p><IoIosCheckmark className='tick-icon'/>2 WhatsApp Business Accounts</p>
                                    <p><IoIosCheckmark className='tick-icon'/>Free onboarding support</p>
                                    <p><IoIosCheckmark className='tick-icon'/>Advanced support</p>
                                </div>
                            </div>

                            {/* Large package */}
                            <div className='pricing-large'>
                                <div className='pricing-content-header'>
                                    <p>Large</p>
                                    <h1><span className='price-setting'>{largePrice}</span> /month</h1>
                                    <button onClick={() => navigate('/login')}>Sign up now</button>
                                </div>
                                <div className='pricing-content-body'>
                                    <p><IoIosCheckmark className='tick-icon'/> 20,000 customer contacts</p>
                                    <p><IoIosCheckmark className='tick-icon'/>Unlimited user</p>
                                    <p><IoIosCheckmark className='tick-icon'/>2 WhatsApp Business Accounts</p>
                                    <p><IoIosCheckmark className='tick-icon'/>Free onboarding support</p>
                                    <p><IoIosCheckmark className='tick-icon'/>Priority support</p>
                                </div>
                            </div>
                        </div>
                    </section>


                {/* Add on section */}
                <section className="add-on-plan-section">
                    <div className='add-on-header'>
                        <h2>Add On Packages</h2>
                    </div>
                    <div className='add-on-div'>
                        <div className='add-on-content'>
                            <div className='add-on-card'>
                                <div className="add-on-detail">
                                <h2>Translation Package</h2>
                                <p><b>RM {translatePrice[currentPlan]}</b> per month</p>
                                </div>
                                <div className="add-on-button">
                                <form action="https://streamhost-django-80bc172b3b26.herokuapp.com/api/payment/checkout/" method="POST" style={{'display': translateFunction ? 'none' : 'block'}}>
                                    <input type="hidden" name="user_id" value={userId} />
                                    <input type="hidden" name="plan" value={translatePlan[currentPlan]} />
                                    <input type="hidden" name="unit" value='1' />
                                    <button>Purchase add-on</button>
                                </form>
                                <button style={{'display': translateFunction ? 'block' : 'none'}} onClick={() => cancelSubscription('Translate')}>Cancel package</button>
                                </div>
                            </div>
                        </div>
                        <div className='add-on-content'>
                            <div className='add-on-card'>
                                <div className="add-on-detail">
                                <h2>ChatGPT Package</h2>
                                <div className='add-on-unit'>
                                    <p><b>RM {gptPrice[currentPlan]}</b> per month</p>
                                </div>
                                </div>
                                <div className="add-on-button">
                                <form action="https://streamhost-django-80bc172b3b26.herokuapp.com/api/payment/checkout/" method="POST" style={{'display': chatGPTFunction ? 'none' : 'block'}}>
                                    <input type="hidden" name="user_id" value={userId} />
                                    <input type="hidden" name="plan" value={gptPlan[currentPlan]} />
                                    <input type="hidden" name="unit" value="1" />
                                    <button>Purchase add-on</button>
                                </form>
                                <button style={{'display': chatGPTFunction ? 'block' : 'none'}} onClick={() => cancelSubscription('ChatGPT')}>Cancel package</button>
                                </div>
                            </div>
                        </div>
                        <div className='add-on-content'>
                            <div className='add-on-card'>
                            <div className="add-on-detail">
                                <h2>User add on</h2>
                                <div className='add-on-unit'>
                                    <p><b>RM 250</b> per user</p>
                                    <input type='number' min='1' value={userAddOn} onChange={handleUserAddOnChange}/>
                                </div>
                                </div>
                                <div className="add-on-button">
                                <form action="https://streamhost-django-80bc172b3b26.herokuapp.com/api/payment/checkout/" method="POST">
                                    <input type="hidden" name="user_id" value={userId} />
                                    <input type="hidden" name="plan" value="user" />
                                    <input type="hidden" name="unit" value={userAddOn} />
                                    <button>Purchase add-on</button>
                                </form>
                                </div>
                            </div>
                        </div>
                        <div className='add-on-content'>
                            <div className='add-on-card'>
                            <div className="add-on-detail">
                                <h2>Account add on</h2>
                                <div className='add-on-unit'>
                                    <p><b>RM 400</b> per account</p>
                                    <input type='number' min='1' value={accountAddOn} onChange={(e) => setAccountAddOn(e.target.value)} />
                                </div>
                                </div>
                                <div className="add-on-button">
                                <form action="https://streamhost-django-80bc172b3b26.herokuapp.com/api/payment/checkout/" method="POST">
                                    <input type="hidden" name="user_id" value={userId} />
                                    <input type="hidden" name="plan" value="account" />
                                    <input type="hidden" name="unit" value={accountAddOn} />
                                    <button>Purchase add-on</button>
                                </form>
                                </div>
                            </div>
                        </div>
                        <div className='add-on-content'>
                            <div className='add-on-card'>
                            <div className="add-on-detail">
                                <h2>Contact add on</h2>
                                <div className='add-on-unit'>
                                    <p><b>RM 0.10</b> per contact</p>
                                    <input type='number' min='1' value={contactAddOn} onChange={(e) => setContactAddOn(e.target.value)} />
                                </div>
                                </div>
                                <div className="add-on-button">
                                <form action="https://streamhost-django-80bc172b3b26.herokuapp.com/api/payment/checkout/" method="POST">
                                    <input type="hidden" name="user_id" value={userId} />
                                    <input type="hidden" name="plan" value="contact" />
                                    <input type="hidden" name="unit" value={contactAddOn} />
                                    <button>Purchase add-on</button>
                                </form>
                                </div>
                            </div>
                        </div>

                    </div>
                </section>
                
            </div>
        );
    };
    // ------------------------------------------------------------------------------------

    // Actual component -----------------------------------------------------------------
    return (
        <React.Fragment>
            <Helmet>
                <title>StreamHost | Plans And Billing </title>
            </Helmet>
            <Sidenav2/>
            <div className = 'sides'><SidenavSettings /></div>
            <div className={`settings-column2 ${(!isMobile || activeView === 'content') ? 'active' : ''}`}>
            <main className='plans-and-billing-main'>
                <div className='plans-and-billing-header'>
                        <div className='settings-header'>
                            {isMobile && (
                                <Link to="/main/settings" className="sett-link">
                                    <FaArrowLeft />
                                </Link>
                                )}
                            <h1>Plans and Billing</h1>
                        </div>
                    <div className='plans-and-billing-nav'>
                        <div className='plans-and-billing-navitem' onClick={() => setActiveTab('plans')}>Plans</div>
                        <div className='plans-and-billing-navitem' onClick={() => setActiveTab('accountData')}>Account Data</div>
                    </div>
                    <hr />
                </div>

                <div className='plans-and-billing-content'>
                    {activeTab === 'plans' ? <Plans /> : <AccountData />}
                </div>
            </main>
            <ToastContainer position='bottom-center' autoClose={5000} hideProgreeBar={false} closeOnClick pauseOnFocusLoss draggable rtl={false} pauseOnHover theme='colored' transition={Bounce} />
        </div>
        </React.Fragment>
    );
}
// ------------------------------------------------------------------------------------


// Password and Security page --------------------------------------------------------
function PasswordAndSecurity() {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [newPassword2, setNewPassword2] = useState('');
    const { userToken } = useContext(UserContext);

    const [activeView, setActiveView] = useState('settings');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 867);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 867);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleBackClick = () => {
        setActiveView('settings');
    };

    const changePassword = () => {
        if (newPassword !== newPassword2) {
            toast.error("New Passwords do not match");
            return;
        } else {
            const data = {
                'token': userToken,
                'old_password': currentPassword,
                'new_password': newPassword
            };
            changePasswordAPI(data);
        }   
    };

    const changePasswordAPI = async(body) => {
        const data = await API.changePassword(body);
        if (data['message'] === 'Password changed successfully') {
            toast.success("Password changed successfully");
        } else {
            toast.error("Old Password is incorrect");
        }
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>StreamHost | Password And Security </title>
            </Helmet>
            <Sidenav2/>
            <div className = 'sides'><SidenavSettings /></div>
            <div className={`settings-column2 ${(!isMobile || activeView === 'content') ? 'active' : ''}`}>
                <main className='password-and-security-main'>
                    <div className='password-and-security-header'>
                        {isMobile && (
                        <Link to="/main/settings" className="sett-link">
                            <FaArrowLeft />
                        </Link>
                        )}
                        <h1>Password and Security</h1>
                    </div>
                    <hr/>
                    <div className='password-and-security-content'>
                        <div className='password-and-security-content-box'>
                            <h2>Change Password</h2>
                            <p>Raw passwords are not stored, so there is no way for us to see this user's password</p>
                            <div className='password-and-security-input'>
                                <label htmlFor='currentPassword'>Current Password</label>
                                <input id='currentPassword' type='password' title='Current Password' placeholder='Current Password' value={currentPassword} onChange={e => setCurrentPassword(e.target.value)} />
                                
                                <label htmlFor='newPassword'>New Password</label>
                                <input id='newPassword' type='password' title='New Password' placeholder='New Password' value={newPassword} onChange={e => setNewPassword(e.target.value)} />
                                
                                <label htmlFor='newPassword2'>Confirm New Password</label>
                                <input id='newPassword2' type='password' title='Confirm New Password' placeholder='Confirm New Password' value={newPassword2} onChange={e => setNewPassword2(e.target.value)} />
                            </div>
                            <button className='password-and-security-button' onClick={changePassword}>Change Password</button>
                        </div>
                    </div>
                </main>
                <ToastContainer position='bottom-center' autoClose={5000} hideProgreeBar={false} closeOnClick pauseOnFocusLoss draggable rtl={false} pauseOnHover theme='colored' transition={Bounce} />
            </div>
        </React.Fragment>
    );
}

function Chatbot() {
    const [chatbots, setChatbots] = useState([]); // Store multiple chatbots
    const [selectedChatbot, setSelectedChatbot] = useState(null); // Store the currently selected chatbot
    const [instructions, setInstructions] = useState('');
    const [assistantName, setAssistantName] = useState('');
    const [originalName, setOriginalName] = useState(''); // Track original name
    const [originalInstructions, setOriginalInstructions] = useState(''); // Track original instructions
    const { profileId } = useContext(UserContext);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 867);
    const [activeView, setActiveView] = useState('settings');
    const [isSubmit, setIsSubmit] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    

    const selectChatbot = (chatbot) => {
        setSelectedChatbot(chatbot);
        setAssistantName(chatbot.chatbot_name || '');
        setInstructions(chatbot.chatbot_role || '');
        setOriginalName(chatbot.chatbot_name || '');
        setOriginalInstructions(chatbot.chatbot_role || '');
    };

    useEffect(() => {
        if (profileId) {
            setIsLoading(true);
            API.getChatbot(profileId)
                .then((response) => {
                    console.log('Chatbots:', response);
                    if (response && Array.isArray(response)) {
                        setChatbots(response);
                        if (response.length > 0) {
                            selectChatbot(response[0]);
                        }
                    } else {
                        setChatbots([]);
                    }
                })
                .catch((error) => {
                    console.error('Error fetching chatbots:', error);
                    setChatbots([]);
                })
                .finally(() => {
                    setIsLoading(false);
                });
        }
    }, [profileId]);

    const fetchChatbots = async(selectedChatbot) => {
        API.getChatbot(profileId)
                .then((response) => {
                    console.log('Chatbots:', response);
                    if (response && Array.isArray(response)) {
                        setChatbots(response); // Set chatbots directly from response
                        const chatbotToSelect = response.find(chatbot => chatbot.id === selectedChatbot.id);
                        if (chatbotToSelect) {
                            console.log('Chatbot to select:', chatbotToSelect);
                            selectChatbot(chatbotToSelect);
                        } 
                    } else {
                        setChatbots([]); // Ensure chatbots is at least an empty array
                    }
                })
                .catch((error) => {
                    console.error('Error fetching chatbots:', error);
                    setChatbots([]); // Handle API errors by setting chatbots to an empty array
                });
    };


    const handleNameChange = (e) => {
        const newName = e.target.value;
        if (newName && newName.toLowerCase() === 'default') {
            return; // Do nothing if the chatbot is named 'default'
        }
        setAssistantName(newName);
    };

    const handleInstructionsChange = (e) => {
        const input = e.target.value;
        setInstructions(input);
    };

    const handleChatbotChange = (e) => {
        const chatbotId = e.target.value;

        if (selectedChatbot && (assistantName !== originalName || instructions !== originalInstructions)) {
            if (!window.confirm("You have unsaved changes. Do you want to discard them?")) {
                // Prevent changing chatbot
                e.preventDefault();
                return;
            }
        }

        if (chatbotId === 'new') {
            // Clear the form fields to add a new chatbot
            setSelectedChatbot(null);
            setAssistantName('');
            setInstructions('');
            setOriginalName('');
            setOriginalInstructions('');
        } else {
            const chatbot = chatbots.find(c => c.id === parseInt(chatbotId));
            if (chatbot) {
                selectChatbot(chatbot);
            }
        }
    };

    const isNameUnique = (name) => {
        return !chatbots.some(chatbot => chatbot.chatbot_name === name && chatbot.id !== selectedChatbot?.id);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        
        if (!isNameUnique(assistantName)) {
            toast.error('Chatbot name must be unique. Please choose another name.');
            return;
        }
    
        if (selectedChatbot) {
            // Update existing chatbot
            API.modifyChatbot(selectedChatbot.id, assistantName, instructions, profileId)
                .then((response) => {
                    toast.success('Assistant settings updated successfully!');
                    fetchChatbots(selectedChatbot);
                
                })
                .catch((error) => {
                    toast.error('Failed to update assistant settings.');
                    console.log(error);
                });
        } else {
            // Create a new chatbot
            API.modifyChatbot(null, assistantName, instructions, profileId)
                .then((response) => {
                    toast.success('New Assistant created successfully!');
                    const updatedChatbots = [...chatbots, response];
                    setChatbots(updatedChatbots);
                    selectChatbot(response); // Set the newly created chatbot as selected
                    setOriginalName(response.chatbot_name);
                    setOriginalInstructions(response.chatbot_role);
                })
                .catch((error) => {
                    toast.error('Failed to create new assistant.');
                    console.log(error);
                });
        }
    
        setIsSubmit(!isSubmit);
    };

    const handleBackClick = () => {
        setActiveView('settings');
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>StreamHost | Chatgpt Page</title>
            </Helmet>
            <Sidenav2/>
            <div className='sides'><SidenavSettings /></div>
            <div className={`settings-column2 ${(!isMobile || activeView === 'content') ? 'active' : ''}`}>
                <div className='settings-account-main'>
                    <div className="settings-header">
                        {isMobile && (
                            <button onClick={handleBackClick} className="sett-link">
                                <FaArrowLeft />
                            </button>
                        )}
                        <h1>Chatbot Assistant</h1>
                    </div>
                    <hr/>
                    {isLoading ? (
                        <div className="loader-container">
                            <div className="loader"></div>
                        </div>
                    ) : (
                    <form onSubmit={handleSubmit} className="chatbot-settings">
                        <h2 className="chatbot-subtitle">1. Settings</h2>
                        <div className="chatbot-form-group">
                            <label htmlFor="chatbotSelect" className="chatbot-label">Select Chatbot:</label>
                            <select
                                id="chatbotSelect"
                                value={selectedChatbot ? selectedChatbot.id : 'new'}
                                onChange={handleChatbotChange}
                                className="chatbot-select"
                            >
                                <option value="new">Add New Chatbot</option>
                                {chatbots && chatbots.length > 0 ? (
                                    chatbots.map(chatbot => (
                                        <option key={chatbot.id} value={chatbot.id}>
                                            {chatbot.chatbot_name || 'Untitled Assistant'}
                                        </option>
                                    ))
                                ) : (
                                    <option>No chatbots available</option>
                                )}
                            </select>
                        </div>
                        <div className="chatbot-form-group">
                            <label htmlFor="assistantName" className="chatbot-label">Assistant name:</label>
                            <input
                                id="assistantName"
                                type="text"
                                value={assistantName || ''} // Ensure assistantName is a string
                                onChange={handleNameChange}
                                placeholder="Untitled Assistant"
                                className="chatbot-input"
                                disabled={assistantName?.toLowerCase() === 'default'} // Only call toLowerCase() if assistantName is defined
                            />
                        </div>
                        <div className="chatbot-form-group">
                            <label className="chatbot-label">AI Model:</label>
                            <select disabled className="chatbot-select">
                                <option>GPT-4o mini</option>
                            </select>
                        </div>
                        <div className="chatbot-form-group">
                            <label htmlFor="instructions" className="chatbot-label">Instructions:</label>
                            <textarea
                                id="instructions"
                                value={instructions}
                                onChange={handleInstructionsChange}
                                placeholder="Enter instructions for the model..."
                                className="chatbot-textarea"
                            />
                        </div>
                        <button type="submit" className="chatbot-button chatbot-button-primary">Done</button>
                    </form>
                    )}
                    <div className="chatbot-model-info">
                        <h2 className="chatbot-subtitle">Model Information</h2>
                        <table className="chatbot-table">
                            <thead>
                                <tr>
                                    <th>AI Models</th>
                                    <th>Credit Usage</th>
                                    <th>Response Quality</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>GPT-3.5 Turbo</td>
                                    <td>1 credit per message</td>
                                    <td>Fast and cost-effective, suitable for simple tasks. May occasionally produce inaccuracies.</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <ToastContainer position='bottom-center' autoClose={5000} hideProgressBar={false} closeOnClick pauseOnFocusLoss draggable rtl={false} pauseOnHover theme='colored' transition={Bounce} />
                </div>
            </div>
        </React.Fragment>
    );
}

function NotificationSettings() {
    const [activeView, setActiveView] = useState('settings');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 867);
    const [accounts, setAccounts] = useState([]);
    const [availableTaskTypes, setAvailableTaskTypes] = useState([]);
    const [userNotAllowedNotifications, setUserNotAllowedNotifications] = useState([]);
    const { userId, userToken, notAllowTaskTypeNotification, profileId, setNotAllowTaskTypeNotification, whatsappNotification, setWhatsappNotification } = useContext(UserContext);

    useEffect(() => {
        // Fetch created user
        const fetchSecondaryProfile = async() => {
            try {
                const response = await API.fetchSecondaryProfile({'user_id': userId, 'token': userToken});
                const formatData = response.map((data) => ({
                    'id': data.id,
                    'username': data.user.username,
                    'email': data.user.email,
                    'building': data.building,
                    'not_allowed_todo_notifications': data.not_allowed_todo_notifications,
                    'allow_whatsapp_notifications': data.allow_whatsapp_notifications
                }));

                setAccounts(formatData);
            } catch(error) {
                console.error(error);
            }
        }

        const fetchTaskTypes = async () => {
            try {
                const types = await API.getTaskTypes({'token': userToken});
                const taskTypes = types.map(type => type.task_type);
                setAvailableTaskTypes(taskTypes);
            } catch (error) {
                console.error('An error occurred while fetching task types:', error);
            }
        };

        

        fetchTaskTypes();
        fetchSecondaryProfile();
    }, [userId, userToken]);

    const handleTaskTypeToggle = (accountId, taskType) => {
        setAccounts(prevAccounts => prevAccounts.map(account => {
            if (account.id === accountId) {
                // Ensure not_allowed_todo_notifications is always an array
                const currentNotAllowed = account.not_allowed_todo_notifications || [];
                let updatedNotAllowed;
                if (currentNotAllowed.includes(taskType)) {
                    // Remove the task type if it's already in the array
                    updatedNotAllowed = currentNotAllowed.filter(type => type !== taskType);
                } else {
                    // Add the task type if it's not in the array
                    updatedNotAllowed = [...currentNotAllowed, taskType];
                }
                API.updateAccountTodoNotifications({"account_id": accountId, "not_allowed_task_types": updatedNotAllowed, "token": userToken});
                
                return {...account, not_allowed_todo_notifications: updatedNotAllowed};
            }
            return account;
        }));
    };

    const handleUserTaskTypeToggle = (taskType) => {
        setNotAllowTaskTypeNotification(prevNotAllowed => {
            let updatedNotAllowed;
            if (prevNotAllowed.includes(taskType)) {
                updatedNotAllowed = prevNotAllowed.filter(type => type !== taskType);
            } else {
                updatedNotAllowed = [...prevNotAllowed, taskType];
            }
            console.log(`Toggled ${taskType} for user. Updated notAllowTaskTypeNotification:`, updatedNotAllowed);
            API.updateAccountTodoNotifications({"account_id": profileId, "not_allowed_task_types": updatedNotAllowed, "token": userToken});
            localStorage.setItem('notAllowTaskTypeNotification', updatedNotAllowed);
            return updatedNotAllowed;
        });
    };

    const handleSecondaryAccountWhatsappToggle = (accountId) => {
        setAccounts(prevAccounts => prevAccounts.map(account => {
            if (account.id === accountId) {
                const updatedAccount = {
                    ...account,
                    allow_whatsapp_notifications: !account.allow_whatsapp_notifications
                };
                API.updateAccountTodoNotifications({
                    account_id: accountId,
                    allow_whatsapp_notifications: updatedAccount.allow_whatsapp_notifications,
                    token: userToken
                });
                return updatedAccount;
            }
            return account;
        }));
    };

    const handleWhatsappToggle = () => {
        setWhatsappNotification(!whatsappNotification);
        console.log("Whatsapp Notification: ", !whatsappNotification);
        API.updateAccountTodoNotifications({"account_id": profileId, "allow_whatsapp_notifications": !whatsappNotification, "token": userToken});
    };

    return (
        <React.Fragment>
            <Helmet>
                <title>StreamHost | Notifications Page</title>
            </Helmet>
            <Sidenav2/>
            <div className='sides'><SidenavSettings /></div>
            <div className={`settings-column2 ${(!isMobile || activeView === 'content') ? 'active' : ''}`}>
                <div className='settings-account-main'>
                    <div className='password-and-security-header'>
                        {isMobile && (
                            <Link to="/main/settings" className="sett-link">
                                <FaArrowLeft />
                            </Link>
                        )}
                        <h1>Notification Settings</h1>
                    </div>

                    <hr/>
                    
                    <div className='notification-settings-content'>
						<CheckNotificationSubscription/>
                    </div>
                    
                    {/* User Account Notification Settings */}
                    <div className='user-notification-panel'>
                        <h2 className='notification-panel-title'>Your Notification Settings</h2>
                        <div className='switch-div'>
                            <label className="switch">
                                <input type="checkbox" checked={whatsappNotification} onChange={() => handleWhatsappToggle() }/>
                                <span className="slider-btn"></span>
                            </label>
                            <p style={{fontSize: '1rem'}}>WhatsApp Notifications</p>
                        </div>
                        <div className='notification-options'>
                            <h4 className='notification-category'>Task Type Notifications:</h4>
                            <div className='settings-task-type-grid'>
                                {availableTaskTypes.map((taskType, index) => (
                                    <div key={index} className='settings-task-type-option'>
                                        <div className='switch-div'>
                                            <label className="switch">
                                                <input 
                                                    type="checkbox" 
                                                    checked={!notAllowTaskTypeNotification.includes(taskType)} 
                                                    onChange={() => handleUserTaskTypeToggle(taskType)}
                                                />
                                                <span className="slider-btn"></span>
                                            </label>
                                            <p className='truncate-text' style={{fontSize: '1rem', maxWidth: '60%'}}>{taskType}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                    <hr className='notification-divider'/>

                    {/* Secondary Accounts Notification Settings */}
                    <div className='secondary-accounts-panel'>
                        <h2 className='notification-panel-title'>Secondary Accounts Notification Settings</h2>
                        {accounts.length === 0 ? (
                            <div className='no-accounts-message'>
                                <PiLinkBreakBold className='no-accounts-icon' />
                                <span>No secondary accounts found</span>
                            </div>
                        ) : (
                            accounts.map((account) => (
                                <div key={account.id} className='account-notification-card'>
                                    <h3 className='account-name'>{account.username}</h3>
                                    <p className='account-detail'>Email: {account.email}</p>
                                    <p className='account-detail'>Building: {account.building}</p>
                                    <div className='account-notification-options'>
                                        <div className='switch-div'>
                                            <label className="switch">
                                                <input type="checkbox" checked={account.allow_whatsapp_notifications} onChange={() => handleSecondaryAccountWhatsappToggle(account.id)}/>
                                                <span className="slider-btn"></span>
                                            </label>
                                            <p className='truncate-text' style={{fontSize: '1rem'}}>WhatsApp Notifications</p>
                                        </div>
                                        <h4 className='notification-category'>Task Type Notifications:</h4>
                                        <div className='settings-task-type-grid'>
                                            {availableTaskTypes.map((taskType, index) => (
                                                <div key={index} className='switch-div settings-task-type-option'>
                                                    <label className="switch">
                                                        <input type="checkbox" checked={!account.not_allowed_todo_notifications.includes(taskType)} onChange={() => handleTaskTypeToggle(account.id, taskType)}/>
                                                        <span className="slider-btn"></span>
                                                    </label>
                                                    <p className='truncate-text' style={{fontSize: '1rem'}}>{taskType}</p>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            ))
                        )}
                    </div>
                    
                    <ToastContainer position='bottom-center' autoClose={5000} hideProgressBar={false} closeOnClick pauseOnFocusLoss draggable rtl={false} pauseOnHover theme='colored' transition={Bounce} />
                </div>
            </div>
        </React.Fragment>
    )
}



// ------------------------------------------------------------------------------------
export {Settings,General ,AccountManagement, PlansAndBilling, PasswordAndSecurity, QuickResponse, WhatsAppTemplate, Chatbot, NotificationSettings};
import React, { useEffect, useState, useContext, useRef} from 'react';
import { UserContext } from "../user-context.js";
import { API } from "../api-service.js";
import './css/ChatRoomList.css';
import UserImage from '../assets/user.png';
import { useWebSocketContext } from '../pages/Websocket.js';
import { toast } from 'react-toastify';

const ContextMenu = ({ x, y, onDelete, onClose }) => {
    const menuRef = useRef(null);
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
          onClose();
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [onClose]);
  
    return (
        <div ref={menuRef} style={{ top: y, left: x }} className="popup-menu">
            <button className="popup-menu-button" onClick={onDelete}>
                Delete Chatroom
            </button>
        </div>
    );
  };

const ChatRoomItem = ({ chatRoom, onChatRoomClick, onDeleteChatRoom }) => {
    const { userToken} = useContext(UserContext);
    // Initialize lastMessage as an object with text and timestamp
    const [lastMessage, setLastMessage] = useState({text: '', timestamp: '', type: ''});
    const [contextMenu, setContextMenu] = useState(null);


    useEffect(() => {
        API.getLastMessage(chatRoom.id, userToken)
            .then(data => {
                // Extract and format the date
                const date = new Date(data.timestamp);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
                const year = date.getFullYear();
                const formattedDate = `${day}/${month}/${year}`;
                // Extract and format the time
                const formattedTime = new Date(data.timestamp).toLocaleString('en-US', {
                    hour: '2-digit', minute: '2-digit'
                });
                // Update state with separate date and time
                setLastMessage({text: data.text, date: formattedDate, time: formattedTime, type: data.media_type, id: data.id});

            })
            .catch(error => {
                console.error('Error:', error);
            });
        
        
    }, [chatRoom, userToken]);

    const handleContextMenu = (event) => {
        event.preventDefault();
        setContextMenu({
            x: event.clientX,
            y: event.clientY,
        });
    };

    const handleDeleteChatRoom = () => {
        onDeleteChatRoom(chatRoom.id, chatRoom.participants);
        setContextMenu(null);
    };

    const getMessageDateDisplay = (messageDate) => {
        const today = new Date();
        const yesterday = new Date(new Date().setDate(today.getDate() - 1));
    
        // Split the date string into day, month, and year
        if (messageDate) {        
            const [day, month, year] = messageDate.split('/');
            // Create a new Date object using the parsed values
            const messageDateTime = new Date(`${year}-${month}-${day}`);
            
        
            // Convert to strings for comparison to ensure timezone is considered
            const todayDay = String(today.getDate()).padStart(2, '0');
            const todayMonth = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const todayYear = today.getFullYear();
            const todayStr =  `${todayDay}/${todayMonth}/${todayYear}`;
    
            // Get yesterday's date
            const yesterdayDay = String(yesterday.getDate()).padStart(2, '0');
            const yesterdayMonth = String(yesterday.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const yesterdayYear = yesterday.getFullYear();
            const yesterdayStr =  `${yesterdayDay}/${yesterdayMonth}/${yesterdayYear}`;
    
            // Extract day, month, and year
            const msgDateTimeDay = String(messageDateTime.getDate()).padStart(2, '0');
            const msgDateTimeMonth = String(messageDateTime.getMonth() + 1).padStart(2, '0'); // Months are zero-based
            const msgDateTimeYear = messageDateTime.getFullYear();
    
            // Format the date as dd/mm/yyyy
            const messageDateStr = `${msgDateTimeDay}/${msgDateTimeMonth}/${msgDateTimeYear}`;
    
            if (messageDateStr === todayStr) {
                // Message was sent today, return nothing
                return 'Today';
            } else if (messageDateStr === yesterdayStr) {
                // Message was sent yesterday
                return 'Yesterday';
            } else if (messageDateStr === 'NaN/NaN/NaN') {
                return ' ';
            } else {
                // Message was sent on another day, return formatted date
                return messageDateStr;
            }
        }
    };
    
    return (
        <div className='chatroom-list' onClick={() => onChatRoomClick(chatRoom.id)} onContextMenu={handleContextMenu}>
            <div className="inbox-item-pic">
                <img className='inbox-item-user-pic' src={UserImage} alt="" />
            </div>
            
            <div className="inbox-item-name">
            {chatRoom.participants.map((participant, index) => (
                <div key={index}>
                    <div className='inbox-item-name-and-date'>
                        <h3>{participant.name}</h3>
                        <p className='inbox-item-last-message-timestamp'>
                            {getMessageDateDisplay(lastMessage.date)}
                        </p>
                    </div>
                    <div className="inbox-item-last-message">
                        <p className='inbox-item-last-message-text'>
                            {lastMessage.type !== 'text' ? lastMessage.type : lastMessage.text}
                        </p>
                        {lastMessage.time !== "Invalid Date" && (
                            <p className='inbox-item-last-message-timestamp'>{lastMessage.time}</p>
                        )}
                    </div>
                </div>
            ))}
            
            </div>
            {contextMenu && (
                <ContextMenu
                    x={contextMenu.x}
                    y={contextMenu.y}
                    onDelete={handleDeleteChatRoom}
                    onClose={() => setContextMenu(null)}
                />
            )}
            
        </div>
    );
};


function ChatRoomList({ onChatRoomClick, onChatRoomDelete, onChatRoomRerender, searchQuery }) { 

    const socketRef = useRef();
    const [chatRooms, setChatRooms] = useState([]);
    const { userToken, wabaId } = useContext(UserContext);
    const { toggleConnection } = useWebSocketContext();
    const [sortedChatRooms, setSortedChatRooms] = useState([]);
    const [newLastMessageForUpdate, setNewLastMessageForUpdate] = useState();
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const filteredChatRooms = chatRooms.filter(chatRoom =>
            chatRoom.participants.some(participant =>
                participant.name.toLowerCase().includes(searchQuery.toLowerCase())
            )
        );

        const sorted = [...filteredChatRooms].sort((a, b) => {
            const dateA = new Date(a.lastMessage.timestamp);
            const dateB = new Date(b.lastMessage.timestamp);
            return dateB - dateA;
        });

        setSortedChatRooms(sorted);
    }, [chatRooms, searchQuery]);

    useEffect(() => {

        
        socketRef.current = new WebSocket(`wss://streamhost-django-80bc172b3b26.herokuapp.com/ws/chatroomlist/`);
        toggleConnection(false);
        socketRef.current.onopen = () => {
            console.log("[chatroomlist] Connection established");
        };

        if (socketRef.current) {
            socketRef.current.onmessage = (event) => {
                const message = JSON.parse(event.data);
                
                if (message.message === "" && message.timestamp === "") {
                    return; // Skip this message
                }
                // if receive wabaid, with chatroom id, update chatrooms
                // if (message.wabaid === wabaId) {
                //     onChatRoomRerender();
                // }

                if (message && message.chatRoomId && message.message) {
                    setChatRooms((currentChatRooms) => 
                        currentChatRooms.map((chatRoom) => 
                            chatRoom.id === message.chatRoomId
                                ? { ...chatRoom, lastMessage: { 
                                    message: message.message, 
                                    timestamp: message.timestamp  // More defensive check
                                } }
                                : chatRoom
                            
                        )
                        
                    );
                    setNewLastMessageForUpdate(message)
                }
            };
        }


        // Cleanup function to close the WebSocket connection
        return () => {
            if (socketRef.current) {
                socketRef.current.close();
                toggleConnection(true);
                console.log("[close] Connection closed");
            }
        };
        
    }, [userToken, toggleConnection]);

    
    useEffect(() => {
        API.getChatRooms(userToken, wabaId).then(async (fetchedChatRooms) => {
            
            // Limit user view on chatroom. Eg: Sales person can only see building 1's messages
            const profileData = await API.fetchProfile(userToken);
            let filteredChatRooms = []
            if (!profileData['related_user']|| profileData['building'] === 'All' || !profileData['building']){
                filteredChatRooms = fetchedChatRooms;
            } else {
                // Implement building limit logic
                filteredChatRooms = fetchedChatRooms.filter(chatRoom => {
                    // Ensure there's at least one participant and the name property exists
                    if (chatRoom.participants.length > 0 && chatRoom.participants[0].name) {
                        return chatRoom.participants[0].name.includes(profileData['building']);
                    }
                    return false;
                });
            }

            const chatRoomsWithProfilesAndLastMessage = await Promise.all(
                filteredChatRooms.map(async (chatRoom) => {
                    const participants = chatRoom.participants;
                    
                    // Fetch the last message for the current chat room
                    let lastMessage = null;
                    try {
                        lastMessage = await API.getLastMessage(chatRoom.id, userToken);
                    } catch (error) {
                        console.error('Error fetching last message for chatRoomId:', chatRoom.id, error);
                    }

                    // Combine chat room data with the last message
                    return { ...chatRoom, participants, lastMessage };
                })
            );

            setChatRooms(chatRoomsWithProfilesAndLastMessage);
            setIsLoading(false);
        }).catch((error) => {
            console.error('Error:', error);
            setIsLoading(false);
        });

        
    }, [userToken, wabaId, newLastMessageForUpdate]);

    if (isLoading) {
        return (
            <div className="loader-container">
                <div className="loader"></div>
            </div>
        );
    }


    const handleDeleteChatRoom = (chatRoomId, chatRoomParticipant) => {
        if (!chatRoomParticipant || chatRoomParticipant.length === 0) {
            console.error('No participant found for this chat room');
            return;
        }
    
        const participantId = chatRoomParticipant[0].id;
        
        API.deleteChatRoom(participantId, userToken)
            .then((result) => {
                setChatRooms(prevChatRooms => prevChatRooms.filter(room => room.id !== chatRoomId));
                console.log(result.message);
                toast.success("Chatroom deleted", {position: 'bottom-center'})
                onChatRoomDelete(chatRoomId);
                
                // Optionally, show a success message to the user
            })
            .catch(error => {
                toast.error("Chatroom deleted", {position: 'bottom-center'})
                console.error('Error deleting chat room:', error.message);
                // Show error message to user
            });

        
    };
    
    

    return (
        <div>
            {sortedChatRooms.map((chatRoom, index) => (
                <ChatRoomItem 
                    key={index} 
                    chatRoom={chatRoom} 
                    onChatRoomClick={onChatRoomClick}
                    onDeleteChatRoom={handleDeleteChatRoom}
                    lastMessage={chatRoom.lastMessage}
                />
            ))}
        </div>
    );
}

export default ChatRoomList;
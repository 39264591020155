import React, { useEffect } from "react";
import { useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import { UserContext } from "../user-context";
import Sidenav2 from "../components/Sidenav";
import { API } from "../api-service";
import Select from 'react-select';
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import HelpButton from '../components/HelpButton.js';
import { Bounce, ToastContainer, toast} from 'react-toastify';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import {Helmet} from 'react-helmet';

import { MdDelete } from "react-icons/md";
import { IoHomeSharp } from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";
import { FaClock } from "react-icons/fa6";
import { FaTrashAlt } from "react-icons/fa";
import { FiPlusCircle } from "react-icons/fi";
import { BsThreeDotsVertical } from "react-icons/bs";

import '../components/css/ContactModal.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-phone-input-2/lib/material.css';
import 'react-phone-input-2/lib/bootstrap.css';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import './css/ChatFlowList.css';


const StyledDateTimePicker = styled(DatePicker)`
        width: 15rem;
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 4px;
        transition: none;

        &:focus {
        margin: 0;
        outline: none; /* Remove default focus outline */
        }
        `;


function ChatFlowList() {

    // Maintain page state
    const { userToken, userId, wabaId, SystemUserToken, waPhoneNumber, waPhoneNumberId, subscriptionPlan } = useContext(UserContext);
    const navigate = useNavigate();
    const [streamFlowData, setStreamFlowData] = useState([]);
    const [massFlowData, setMassFlowData] = useState([]);
    const [activeTab, setActiveTab] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});
    const [selectedCard, setSelectedCard] = useState(null);
    const [clientUserLength, setClientUserLength] = useState(0);
    
    // Select button state
    const [massFlowOption, setMassFlowOption] = useState([])
    const [unitOption, setUnitOption] = useState([]);
    const [contactName, setContactName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [buildings, setBuildings] = useState([]);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    // Edit template
    const [editChatFlowName, setEditChatFlowName] = useState('');
    const [editCheckInTime, setEditCheckInTime] = useState('');
    const [editCheckOutTime, setEditCheckOutTime] = useState('');
    const [checkInDay, setCheckInDay] = useState('Day of');
    const [checkOutDay, setCheckOutDay] = useState('Day of');

    const planContactLimit = {
        'Free': 100,
        'Small': 5000,
        'Medium': 10000,
        'Large': 20000
    }

    // Fetch data from API
    useEffect(() => {
        if(userToken) {
            const fetchBuildingAndUnit = async() => {
                try {
                    const data= await API.getChatFlowList(userToken);
                    // Massflow data
                    const filteredData = data.filter(chatflow => chatflow.type === 'massflow' && chatflow.error_status===true).map(chatflow => ({value: chatflow.id, label: chatflow.chatflow_name}))
                    setStreamFlowData(data.filter(chatflow => chatflow.type === 'streamflow'));
                    setMassFlowData(data.filter(chatflow => chatflow.type === 'massflow'));
                    setMassFlowOption(filteredData);

                    // Unit data
                    let mappedUnit = {};

                    const fetchUnitNodes = async (chatflow) => {
                        try {
                            const response = await API.getNodes({ 'token': userToken, 'id': chatflow.value });
                            const unitNodes = response?.filter(node => node.node_type === 'unitNode');
                            
                            // Combine the text fields into a single array
                            const unitNodeComplete = unitNodes.reduce((acc, node) => {
                                const nodeTextArray = JSON.parse(node.text);
                                return [...acc, ...nodeTextArray];
                            }, []);
                            
                            mappedUnit[chatflow.label] = unitNodeComplete.map(node => ({ value: node, label: node }));
                        } catch (error) {
                            console.error(error);
                            throw error;
                        }
                    };

                    await Promise.all(filteredData.map(chatflow => fetchUnitNodes(chatflow)));
                    setUnitOption(mappedUnit);
                } catch(error) {
                    console.error(error);
                    throw(error);
                }
            }

            const getClientUserList = async() => {
                try {
                    const data = await API.getClientUserList(userToken);
                    setClientUserLength(data.length);
                } catch (error) {
                    console.error(error);
                }
            }
            fetchBuildingAndUnit();
            getClientUserList();
        }

        // Three dots button function
        // Add event listener to document
        const handleDocumentClick = () => {
            setSelectedCard(null);
        };

        document.addEventListener('click', handleDocumentClick);

        // Cleanup function to remove event listener
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    },[]);


    useEffect(() => {
        if (selectedCard !== null) {
            const selectedChatflow = massFlowData[selectedCard];
            setEditChatFlowName(selectedChatflow.chatflow_name);
            setCheckInDay(convertNumberToPhrase(selectedChatflow.checkin_day));
            setCheckOutDay(convertNumberToPhrase(selectedChatflow.checkout_day));
            setEditCheckInTime(selectedChatflow.send_checkin_schedule);
            setEditCheckOutTime(selectedChatflow.send_checkout_schedule);
        }
    }, [selectedCard, massFlowData]);


    // Handle Tab click event
    const tabs = ['MassFlow', 'StreamFlow'];
    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    // Handle delete chatflow button
    const handleThreeDotsClick = (event, index) => {
        event.stopPropagation(); // Prevents the document click event from being triggered
        setSelectedCard(index);
    };
      

    const deleteChatflow = async(event, chatflow_id) => {
        event.stopPropagation(); // Prevents the document click event from being triggered

        // Ask for user confirmation before deleting
        const userConfirmed = window.confirm('Are you sure you want to delete this ChatFlow?');

        if (!userConfirmed) {
            return; // Exit the function if the user cancels the action
        }
        try {
            const response = await API.deleteChatFlow({'token': userToken, 'id': chatflow_id});

            // Remove the deleted chatflow from the state
            if(!activeTab) {
                setMassFlowData(massFlowData.filter(chatflow => chatflow.id !== chatflow_id));
            }else {
                setStreamFlowData(streamFlowData.filter(chatflow => chatflow.id !== chatflow_id));
            }

            toast.success('ChatFlow deleted successfully');
            setSelectedCard(null);
            
        } catch (error) {
            console.error(error);
            throw error;
        }
    };

    const generateOptions = () => {
        const options = [];
        for (let i = 14; i >= 1; i--) {
            options.push(`${i} days before`);
        }
        options.push('Day of');
        for (let i = 1; i <= 14; i++) {
            options.push(`${i} days after`);
        }
        return options;
    };

    // Reformate date function
    const reformatDate = (dateString) => {
        let date = new Date(dateString);
        let day = String(date.getDate()).padStart(2, '0');
        let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
        let year = date.getFullYear();
        let hours = String(date.getHours()).padStart(2, '0');
        let minutes = String(date.getMinutes()).padStart(2, '0');
        let seconds = String(date.getSeconds()).padStart(2, '0');
    
        return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
    }

    // Chatflow button onClick function
    const chatflowButton = (chatflow_id, chatflow_name, chatflow_type) => {
        navigate('/main/streamflow/'+ chatflow_id + '/' + chatflow_name + '/' + chatflow_type);
    };

    // MassFlow send message window
    const openModal = (e, chatflow) => {
        e.stopPropagation();
        if (clientUserLength >= planContactLimit[subscriptionPlan]) {
            toast.error('You have reached the maximum number of contacts for your plan. Please upgrade your plan to send more messages.');
            return;
        }
        setIsModalOpen(true);
        setModalData({
            chatflow_id: chatflow.id,
            chatflow_name: chatflow.chatflow_name,
            chatflow_type: chatflow.type
        });
    };


    // Edit chatflow button -------------------------------------------------------------------
    function convertPhraseToNumber(phrase) {
        if (phrase.includes("Day of")) {
            return 0;
        }
        let sign = 1;
        if (phrase.includes("Before")) {
            sign = -1;
        }
        const numberMatch = phrase.match(/\d+/);
        if (numberMatch) {
            const number = parseInt(numberMatch[0], 10);
            return sign * number;
        }
        throw new Error("Invalid phrase format");
    }

    function convertNumberToPhrase(number) {
        let signPhrase = "";
        let absNumber = Math.abs(number);
    
        if (number > 0) {
            signPhrase = "days after";
        } else if (number < 0) {
            signPhrase = "days before";
        } else {
            return "Day of"; // Handle the case for zero
        }
    
        return `${absNumber} ${signPhrase}`;
    }

    function formatTime(timeString) {
        // Split the time string by colon
        const [hours, minutes] = timeString.split(':');
        // Return the formatted time string
        return `${hours}:${minutes}`;
    }


    const editChatFlow = async(e) => {
        e.stopPropagation();
        try {
            const response = await API.editChatFlow({
                'token': userToken,
                'id': massFlowData[selectedCard].id,
                'chatflow_name': editChatFlowName,
                'checkin_day': convertPhraseToNumber(checkInDay),
                'checkout_day': convertPhraseToNumber(checkOutDay),
                'send_checkin_schedule': editCheckInTime,
                'send_checkout_schedule': editCheckOutTime
            });
            toast.success('ChatFlow updated successfully');
            setSelectedCard(null);
        } catch (error) {
            toast.error('Error updating chatflow');
            console.error(error);
            throw error;
        }
    }
    // -----------------------------------------------------------------------------------------------

    // Create ChatFlow button onClick function
    const createChatFlow = async() => {
        if(userToken) {
            // Get the chatflow data ready
            let chatFlowId = '';
            let chatFlowType = '';
            if (!activeTab) {
                chatFlowId = 'MASSFLOW-'+ Date.now();
                chatFlowType = 'massflow';
            }else {
                chatFlowId = 'STREAMFLOW-'+ Date.now(); 
                chatFlowType = 'streamflow';
            }
            const data = {
                'token': userToken,
                'chatflow_id': chatFlowId,
                'chatflow_type': chatFlowType,
                'chatflow_name': 'New ChatFlow',
            }

            // Create chatflow in database
            try {
                const response = await API.addChatFlow(data);
                if(response) {
                    navigate('/main/streamflow/'+response['id']+ '/' + response['chatflow_name'] + '/' + response['type']);
                }
            } catch (error) {
                console.error(error);
                throw(error);
            }            
        }
    }
    // -----------------------------------------------------------------------------------------------

    // Contact modal state handling -------------------------------------------------------------------
    const deleteBuilding = (index, id) => {
        setBuildings(prevBuildings => 
            prevBuildings.map(building => {
                console.log(building.id, id);
                return building.id === id ? { ...building, visible: false } : building;
            })
        );
        setTimeout(() => {
            setBuildings(prevBuildings => prevBuildings.filter(building => building.id !== id));
        }, 300); // This should match your transition duration
    }

    const handleAddBuilding = () => {
        setBuildings(prevBuildings => [...prevBuildings, { id: Date.now(), visible: false}]);
        setTimeout(() => {
            setBuildings(prevBuildings => 
                prevBuildings.map(building => 
                    building.visible ? building : { ...building, visible: true, sendCheckIn: false, sendCheckOut: false }
                )
            );
        }, 1);
    }

    const handlePhoneChange = (e) => {
        let input = e.target.value;
        if (input.length === 1 && input !== '+') {
            input = '+' + input;
        }
        setPhoneNumber(input);

        try {
            if (input) {
                const parsedNumber = parsePhoneNumber(input);
                if (isValidPhoneNumber(input)) {
                    setPhoneNumber(parsedNumber.formatInternational());
                    setPhoneError('');
                } else {
                    setPhoneError('Invalid phone number');
                }
            } else {
                setPhoneError('');
            }
        } catch (error) {
            setPhoneError('Invalid phone number');
        }
    };

    const handleTemplateChange = (index, selectedOption) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                template: selectedOption
            };
            return updatedBuildings;
        });
    };

    const handleUnitChange = (index, selectedOption) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                unit: selectedOption.label
            };
            return updatedBuildings;
        });
    };

    const handleCheckInDateChange = (index, date) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                checkIn: date
            };
            return updatedBuildings;
        });
    };

    const handleCheckOutDateChange = (index, date) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                checkOut: date
            };
            return updatedBuildings;
        });
    };

    const handleSendCheckIn = (index, selectedOption) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                sendCheckIn: selectedOption.target.checked
            };
            return updatedBuildings;
        });
    }

    const handleSendCheckOut = (index, selectedOption) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                sendCheckOut: selectedOption.target.checked
            };
            return updatedBuildings;
        });
    }

    // button function
    const addContactButton = () => {

        // 1. Validation part
        if (!contactName || !phoneNumber) {
            toast.error('Please fill in all the fields');
            return;
        }
        if (phoneError) {
            toast.error('Invalid phone number');
            return;
        }
        if (buildings.length === 0) {
            toast.error('Please add at least one building');
            return;
        }

        // Prepare data to send to API while validating form
        let checkin = [];
        let checkout = [];
        let unit = [];
        let templateLabel = [];
        let templateValue = [];
        let checkInBoolean = [];
        let checkOutBoolean = [];

        buildings.map(building => {
            if (!building.template || !building.unit || !building.checkIn || !building.checkOut) {
                    toast.error('Please fill in all the fields');
                    return;
                }

            // Check date validation
            const checkIn = new Date(building.checkIn);
            const checkOut = new Date(building.checkOut);
            if (checkIn >= checkOut) {
                toast.error('Check-out date must be after check-in date');
                return;
            }

            // If data no problem, append to list for API data preparation
            checkin.push(new Date(building.checkIn));
            checkout.push(new Date(building.checkOut));
            unit.push(building.unit);
            templateLabel.push(building.template.label);
            templateValue.push(building.template.value);
            checkInBoolean.push(building.sendCheckIn);
            checkOutBoolean.push(building.sendCheckOut);
        });


        // 2. Add contact
        const addContact = async() => {
            try {
                if (!isButtonDisabled) {
                    setIsButtonDisabled(true)
                    // Initialize the formatted contact name
                    let formattedContactName = "";
                    // Iterate over the arrays and format the contact name
                    for (let i = 0; i < templateLabel.length; i++) {
                        if (i > 0) {
                            formattedContactName += ", ";
                        }
                         // Reformat date and add user into contact
                        let checkInDate = checkin[i];
                        let checkOutDate = checkout[i];
                        let checkInDay = checkInDate.getDate().toString().padStart(2, '0');
                        const checkInMonth = (checkInDate.getMonth() + 1).toString().padStart(2, '0'); 
                        const formattedCheckInDate = `${checkInDay}/${checkInMonth}`;
                        let checkOutDay = checkOutDate.getDate().toString().padStart(2, '0');
                        const checkOutMonth = (checkOutDate.getMonth() + 1).toString().padStart(2, '0'); 
                        const formattedCheckOutDate = `${checkOutDay}/${checkOutMonth}`;

                        formattedContactName += `${templateLabel[i]} ${unit[i]} ${formattedCheckInDate}-${formattedCheckOutDate}`;
                    }

                    // Append the contact name
                    formattedContactName += ` ${contactName}`;
                    try {
                        // Assuming API.addNewContacts is your method to add a new contact
                        const response = await API.addNewContacts({
                            'token': userToken,
                            'phone': phoneNumber.replace(/\s+/g, '').replace('+', ''),
                            'waPhoneNumberId': waPhoneNumberId,
                            'username': formattedContactName,
                            'userId': userId,
                            'SystemUserToken': SystemUserToken,
                            'checkin_datetime': checkin, 
                            'checkout_datetime': checkout, 
                            'checkin_list': checkInBoolean,
                            'checkout_list': checkOutBoolean,
                            'unit_no': unit,
                            'chatflow_id': templateValue,
                            'wabaId': wabaId
                        });
                        setIsButtonDisabled(false);
                        if (response === 'User Successfully Added' || response === 'Contact added') {
                            let checkInSent = false;
                            let checkOutSent = false;

                            // Send message immediately if slide button is checked
                            // 1. Check in list
                            checkInBoolean.map(async(checkIn, index) => {
                                if (checkIn) {
                                    checkInSent = true;
                                    // Send checkin massflow message;
                                    const response = await API.massFlowAlgorithm({
                                        'token': userToken,
                                        'chatflow_id': templateValue[index],
                                        'unit': unit[index],
                                        'waba_id': wabaId,
                                        'phone_number_id': waPhoneNumberId,
                                        'to_phone_number': phoneNumber.replace(/\s+/g, '').replace('+', ''),
                                        'type': 'checkInNode'
                                    })
                                }
                            })

                            // 2. Check out list
                            checkOutBoolean.map(async(checkOut, index) => {
                                if (checkOut) {
                                    checkOutSent = true;
                                    // Send checkout massflow message
                                    const response = await API.massFlowAlgorithm({
                                        'token': userToken,
                                        'chatflow_id': templateValue[index],
                                        'unit': unit[index],
                                        'waba_id': wabaId,
                                        'phone_number_id': waPhoneNumberId,
                                        'to_phone_number': phoneNumber.replace(/\s+/g, '').replace('+', ''),
                                        'from_phone_number': waPhoneNumber,
                                        'type': 'checkOutNode'
                                    })
                                }
                            })

                            toast.success('Contact added successfully');
                            if (checkInSent) {
                                toast.success('Check-in message sent successfully');
                            }
                            if (checkOutSent) {
                                toast.success('Check-out message sent successfully');
                            }
                            setIsButtonDisabled(false);

                        } else {
                            toast.error(response);
                            setIsButtonDisabled(false);
                        }
                    } catch (error) {
                        toast.error(error);
                        setIsButtonDisabled(false);
                    }
                }
            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        addContact();
    }
    // -----------------------------------------------------------------------------------------------

    return(
        <React.Fragment>
            <Helmet>
                <title>StreamHost | Chatflow Page</title>
            </Helmet>
            <Sidenav2/>
            <main className='chatflowlist-main'>
                <nav className='chatflowlist-tab'>
                    {tabs.map((tab, index) => (
                        <button key={index} className={`chatflowlist-tab-button ${index === activeTab ? 'chatflowlist-active' : ''}`} onClick={() => handleTabClick(index)}><b>{tab}</b></button>
                    ))}
                </nav>

                {/* MASSFLOW Section ------------------------------------------------------------- */}
                <div className='chatflowlist-contentbox'>
                    <div className={`chatflowlist-content ${!activeTab ? 'chatflowlist-active' : ''}`}>
                        {massFlowData.map((chatflow, index) => (
                            <div key={index} className={`chatflowlist-item ${(selectedCard===index) ? 'edit-active' : ''}`}  onClick={() => chatflowButton(chatflow.id, chatflow.chatflow_name, chatflow.type)}>
                                <div className='chatflowlist-item-header' style={{backgroundImage: `url(${chatflow.chatflow_image})`, 'display': selectedCard === index ? 'none' : 'block'}}></div>
                                <div className='chatflowlist-item-body'>
                                    <div className='chatflowcard-title' style={{'display': selectedCard === index ? 'none' : 'flex'}}>
                                        <h1>{chatflow.chatflow_name}</h1><BsThreeDotsVertical size={'1.25rem'} onClick={(e) => handleThreeDotsClick(e, index)} />
                                    </div>
                                    <div className={`chatflowlist-item-status ${chatflow.error_status ? 'item-green': 'item-red'}`} style={{'display': selectedCard === index ? 'none' : 'block'}}>
                                        {chatflow.error_status ? 'Available' : 'Error'}
                                    </div>

                                    <div className={`chatflow-edit ${selectedCard === index ? 'edit-active' : ''}`}>
                                        <h3>Edit template</h3>
                                        <div className="input-container">
                                            <input id='chatflow-name' type='text' value={editChatFlowName} placeholder='Enter chatflow name' onChange={(e) => setEditChatFlowName(e.target.value)} onClick={(e)=> e.stopPropagation()}/>
                                            <label htmlFor='chatflow-name'>Edit chatflow name:</label>
                                        </div>

                                        {/* Check-in schedule */}
                                        <div className='edit-schedule'>
                                            <div className="input-container">
                                                <select id='checkin-day' value={checkInDay} onChange={(e) => setCheckInDay(e.target.value)} onClick={(e) => e.stopPropagation()}>
                                                    {generateOptions().map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label htmlFor='checkin-day'>Check-in send day:</label>
                                            </div>
                                            <div className="input-container">
                                                <input id='edit-checkin-time' type='time' value={editCheckInTime} onChange={(e) => setEditCheckInTime(e.target.value)} onClick={(e)=> e.stopPropagation()}/>
                                                <label htmlFor='edit-checkin-time'>Check-in send time:</label>
                                            </div>
                                        </div>
                                        
                                        {/* Check-out schedule */}
                                        <div className='edit-schedule'>
                                            <div className="input-container">
                                                <select id='checkin-day' value={checkOutDay} onChange={(e) => setCheckOutDay(e.target.value)} onClick={(e) => e.stopPropagation()}>
                                                    {generateOptions().map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </select>
                                                <label htmlFor='checkin-day'>Check-out send day:</label>
                                            </div>
                                            <div className="input-container">
                                                <input id='edit-checkin-time' type='time' value={editCheckOutTime} onChange={(e) => setEditCheckOutTime(e.target.value)} onClick={(e)=> e.stopPropagation()}/>
                                                <label htmlFor='edit-checkin-time'>Check-out send time:</label>
                                            </div>
                                        </div>


                                        <div className='chatflow-btn-div'>
                                            <button className='chatflow-delete-btn' id={index} onClick={(e) => deleteChatflow(e, chatflow.id)}><FaTrashAlt style={{fontSize:'1rem'}}/></button>
                                            <button onClick={(e) => editChatFlow(e)}>Done</button>
                                        </div>
                                    </div>
                                    <p style={{'display': selectedCard === index ? 'none' : 'block'}}><FaClock /> Send {convertNumberToPhrase(chatflow.checkin_day)} check-in at {formatTime(chatflow.send_checkin_schedule)}</p>
                                    <p style={{'display': selectedCard === index ? 'none' : 'block', 'marginTop': '0.75rem'}}><FaClock /> Send {convertNumberToPhrase(chatflow.checkout_day)} check-out at {formatTime(chatflow.send_checkout_schedule)}</p>
                                    <button onClick={(e) => openModal(e, chatflow)} disabled={chatflow.error_status === false} style={{ opacity: chatflow.error_status === false ? 0.5 : 1, cursor: chatflow.error_status === false ? 'not-allowed' : 'pointer', display: selectedCard === index ? 'none' : 'block', }}>Send Message</button>
                                </div>
                            </div>
                        ))}

                        <div className='chatflowlist-addmore' onClick={createChatFlow}>
                            <FiPlusCircle size='5rem'/>
                            <h1>Create New</h1>
                        </div>
                    </div>


                    {/* STREAMFLOW Section ---------------------------------------------------- */}
                    <div className={`chatflowlist-content ${activeTab ? 'chatflowlist-active' : ''}`}>
                        {streamFlowData.map((chatflow, index) => (
                            <div key={index} className='chatflowlist-item' onClick={() => chatflowButton(chatflow.id, chatflow.chatflow_name, chatflow.type)}>
                                <div className='chatflowlist-item-header' style={{backgroundImage: `url(${chatflow.chatflow_image})`}}></div>
                                <div className='chatflowlist-item-body'>
                                    <div className='chatflowcard-title'>
                                        <h1>{chatflow.chatflow_name}</h1><BsThreeDotsVertical size={'1.25rem'} onClick={(e) => handleThreeDotsClick(e, index)} style={{'display' : selectedCard === index ? 'none' : 'block'}}/>
                                        {selectedCard === index && (
                                            <button className={'chatflow-delete-btn'} id={index} onClick={(e) => deleteChatflow(e, chatflow.id)}><FaTrashAlt /></button>
                                        )}
                                    </div>
                                    <div className={`chatflowlist-item-status ${(chatflow.error_status && chatflow.publish_state) ? 'item-green' : 'item-red'}`}>
                                        {!chatflow.error_status ? 'Error' : (chatflow.publish_state ? 'Running': 'Stopped')}
                                    </div>
                                    <p>Created On:  {reformatDate(chatflow.create_date)}</p>
                                    <p>Last Updated: {reformatDate(chatflow.last_update_date)}</p>
                                </div>
                            </div>
                        ))}

                        <div className='chatflowlist-addmore' onClick={createChatFlow}>
                            <FiPlusCircle size='5rem'/>
                            <h1>Create New</h1>
                        </div>
                    </div>
                </div>
                <ToastContainer position='bottom-center' autoClose={5000} hideProgreeBar={false} closeOnClick pauseOnFocusLoss draggable rtl={false} pauseOnHover theme='colored' transition={Bounce} /> 
            </main>

            {/* Contact section */}
            {isModalOpen && (
                <main className={`test-modal-main ${isModalOpen ? 'open' : ''}`}>
                    <div className='modal-container'>
                        <div className='modal'>
                            <div className='test-modal-header'>
                                <IoIosArrowBack style={{'fontSize': '1.5rem'}} className='test-modal-icon' onClick={()=>setIsModalOpen(false)}/>
                                <h2>Create new contact</h2>
                            </div>
                            <div className='test-modal-contact'>
                                <h3>Contact details</h3>
                                <div class="input-container">
                                    <input type="text" id="myInput" value={contactName} placeholder="Enter contact name" onChange={(e) => setContactName(e.target.value)}/>
                                    <label for="myInput">Enter contact name</label>
                                </div>
                                <div class="input-container">
                                    <input type="tel" id="phoneInput" placeholder="Enter phone number" value={phoneNumber} onChange={handlePhoneChange}/>
                                    <label htmlFor="phoneInput">Enter phone number</label>
                                    {phoneError && <div className="error-message">{phoneError}</div>}
                                </div>
        
                                {buildings?.map((building, index) => (
                                    <div key={index}className={`building-div ${building.visible ? 'visible' : ''}`}>
                                        <div className='building-header'>
                                            <h3>{buildings[index]['template']?.label ? buildings[index]['template']['label'] : `Building ${index + 1}`} {buildings[index]['unit'] ? buildings[index]['unit'] : ''}</h3>
                                            <MdDelete className='delete-icon' onClick={() => deleteBuilding(index, building.id)} style={{ fontSize: '1.5rem' }} />
                                        </div>
                                        <div className='building-info'>
                                            <Select className='modal-select' placeholder={'Select building'} options={massFlowOption} value={buildings[index]['template']?.value ? {'label': buildings[index]['template']?.label, 'value': buildings[index]['template']?.value} : null} onChange={(selectedOption) => handleTemplateChange(index, selectedOption)} />
                                            <Select className='modal-select' placeholder={'Select unit'} options={unitOption[buildings[index]['template']?.label]} value={buildings[index]['unit'] ? {'label': buildings[index]['unit'], 'value': buildings[index]['unit']} : null} onChange={(selectedOption) => handleUnitChange(index, selectedOption)} />
                                            <div className='test-modal-date'>
                                                <div>
                                                    <label htmlFor='checkin'>Check In</label>
                                                    <StyledDateTimePicker id='checkin' selected={buildings[index]['checkIn']} onChange={(date) => handleCheckInDateChange(index, date)} dateFormat="MMMM d, yyyy" placeholderText="Check in" />
                                                    <div className='switch-div'>
                                                        <label class="switch">
                                                            <input type="checkbox" id="toggleButton" value={buildings[index]['sendCheckIn']} onChange={(selectedOption) => handleSendCheckIn(index, selectedOption)}/>
                                                            <span class="slider-btn"></span>
                                                        </label>
                                                        <p>Send check-in details now</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor='checkout'>Check Out</label>
                                                    <StyledDateTimePicker id='checkout' selected={buildings[index]['checkOut']} onChange={(date) => handleCheckOutDateChange(index, date)} dateFormat="MMMM d, yyyy" placeholderText="Check out" />
                                                    <div className='switch-div'>
                                                        <label class="switch">
                                                            <input type="checkbox" id="toggleButton" value={buildings[index]['sendCheckOut']} onChange={(selectedOption) => handleSendCheckOut(index, selectedOption)}/>
                                                            <span class="slider-btn"></span>
                                                        </label>
                                                        <p>Send check-out details now</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
        
                                <div className={'test-modal-add'} onClick={handleAddBuilding}>
                                    <IoHomeSharp style={{fontSize:'1.5rem'}}/>
                                    <p>Add building</p>
                                </div>
        
                                <div className='test-modal-add-btn'>
                                    <button className='test-modal-btn' onClick={addContactButton} disabled={isButtonDisabled}>{isButtonDisabled}Add contact</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            )}
        <HelpButton />
        </React.Fragment>
    )
}

export default ChatFlowList;
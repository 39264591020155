import { useEffect, useState } from 'react';
import { RiMenu3Fill } from "react-icons/ri";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { IoIosClose } from "react-icons/io";
import { RiWhatsappFill } from "react-icons/ri";
import { RiInstagramFill } from "react-icons/ri";
import { FaFacebook } from "react-icons/fa";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { FaRegCopyright } from "react-icons/fa6";

import './css/Header.css';
import './css/Footer.css';

function MainHeader() {

    const [isClicked, setIsClicked] = useState(false);
    const params = useLocation();
    const navigate = useNavigate();

    const menuOnClick = () => {
        setIsClicked(!isClicked);
    }

    const scrollToSection = (id) => {
        if (params.pathname != '/') {
            navigate('/');
        }
        const section = document.getElementById(id);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <header className='header-main'>
            <a href="#"><img src={require("../assets/header_title.png")} style={{ height: '3.25rem', marginTop: '1rem' }} /></a>

            <div className="main">
                <div className={`navbar-main ${isClicked ? 'open' : ''}`} >
                    <ul className="normal-container">
                        <li><a onClick={() => scrollToSection('product')}><p>Product</p></a></li>
                        <li><a onClick={() => scrollToSection('bento-box')}><p>Features</p></a></li>
                        <li><a onClick={() => scrollToSection('pricing')}><p>Pricing</p></a></li>
                    </ul>
                    <ul className={'button-container'}>
                        <li><Link to='/login'>Sign-in</Link></li>
                        <li id='contact-sales-btn'><Link to='/contact_sales'>Contact sales</Link></li>
                    </ul>
                </div>
                <div id="menu-icon" onClick={() => menuOnClick()}>{isClicked ?  <IoIosClose /> :<RiMenu3Fill /> }</div>
            </div>
        </header>
    )
}


function MainFooter() {
    const params = useLocation();
    const navigate = useNavigate();

    const scrollToSection = (id) => {
        if (params.pathname != '/') {
            navigate('/');
        }
        const section = document.getElementById(id);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <footer>
            <div className='footer-header'>
                <div className='gradient-box'>
                    <img src={require('../assets/transparent_logo.png')}/>
                </div>
            </div>
            <p>Your trusted WhatsApp chatbot provider</p>
            <nav>
                <Link to='/contact_sales'>Contact us</Link>
                <Link onClick={() => scrollToSection('bento-box')}>Features</Link>
                <Link onClick={() => scrollToSection('pricing')}>Pricing</Link>
                <Link to='/privacypolicy'>Terms of Service</Link>
                <Link to='/privacypolicy'>Privacy Policy</Link>
            </nav>

            <nav className='social-media-div'>
                <a href="https://api.whatsapp.com/send?phone=601126365609&text=Hi%20I%20would%20like%20to%20understand%20more%20on%20what%20StreamHost%20provides" target="_blank" rel="noopener noreferrer"><RiWhatsappFill /></a>
                <a href="https://www.facebook.com/profile.php?id=61560445150135" target="_blank" rel="noopener noreferrer"><FaFacebook /></a>
                <a href='https://www.instagram.com/streamhost_/' target="_blank" rel="noopener noreferrer"><RiInstagramFill /></a>
                <a href="mailto:info.streamhost@gmail.com"><MdOutlineAlternateEmail /></a>
            </nav>

            <p className='copyright'><FaRegCopyright /> 2024 StreamHost PLT. (LLP0038088-LGN). All rights reserved</p>
        </footer>
    )
}

export { MainHeader, MainFooter }

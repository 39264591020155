import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import { Bounce, ToastContainer, toast } from 'react-toastify';
import { UserContext } from "../user-context";
import { FaArrowLeft } from "react-icons/fa";
import Sidenav from "../components/Sidenav";
import { AgGridReact } from 'ag-grid-react';
import {Helmet} from 'react-helmet';
import Select from 'react-select';
import { API } from "../api-service";
import HelpButton from '../components/HelpButton.js';
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { MdDelete } from "react-icons/md";
import { IoHomeSharp } from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";
import DatePicker from 'react-datepicker';
import styled from 'styled-components';

import '../components/css/ContactModal.css';
import 'react-phone-input-2/lib/material.css';
import 'react-phone-input-2/lib/bootstrap.css';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import './css/Contacts.css';

import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { ColumnsToolPanelModule } from "@ag-grid-enterprise/column-tool-panel";
import { FiltersToolPanelModule } from "@ag-grid-enterprise/filter-tool-panel";
import { MenuModule } from "@ag-grid-enterprise/menu";
import { SetFilterModule } from "@ag-grid-enterprise/set-filter";


ModuleRegistry.registerModules([
    ClientSideRowModelModule,
    ColumnsToolPanelModule,
    FiltersToolPanelModule,
    MenuModule,
    SetFilterModule,
]);

const StyledDateTimePicker = styled(DatePicker)`
        width: 100%;
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 4px;
        transition: none;

        &:focus {
        margin: 0;
        outline: none; /* Remove default focus outline */
        }
        `;



function Contacts() {

    // Maintain state
    const { userToken, userId, wabaId, waPhoneNumber, SystemUserToken, subscriptionPlan, waPhoneNumberId } = useContext(UserContext);
    const [massFlowData, setMassFlowData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [massFlowOption, setMassFlowOption] = useState([]);
    const [unitOption, setUnitOption] = useState([]);
    const [contactName, setContactName] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    // Edit modal state
    // Components for edit contact
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editBuildings, setEditBuildings] = useState([]);
    const [checkEditBuildings, setCheckEditBuildings] = useState([]);
    const [editClientUserId, setEditClientUserId] = useState('');
    const [editContactName, setEditContactName] = useState('');
    const gridRef = useRef(null);
    
    // Select button state
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [buildings, setBuildings] = useState([]);


    const closeModal = () => {
        setIsModalOpen(false);
    };

    const planContactLimit = {
        'Free': 100,
        'Small': 5000,
        'Medium': 10000,
        'Large': 20000
    };

    // Delete contact button
    const deleteContactBtn = params => {
        const onClick = async () => {
            try {
                await API.removeChatRoom({ 'token': userToken, 'id': params.data.id });
                await API.deleteClientUser({ 'token': userToken, 'id': params.data.id });

                setRowData(currentRowData => {
                    // Correctly return the filtered array to update the state
                    return currentRowData.filter(object => object.id !== params.data.id);
                });
            } catch (error) {
                console.error(error);
            }
        };
        return <button className='contactDeleteBtn' onClick={onClick}>Delete</button>;
    };

    const editContactBtn = params => {
        const onClick = async() => {
            console.log(params);
            // Regex function
            const getUserName = (input) => {
                // Regular expression to match the last number in the string
                const regex = /.*\d+/;
                const match = input?.match(regex);
                
                if (match) {
                    // Get the index of the last number
                    const lastNumberIndex = match[0].length;
                    // Return the substring after the last number
                    return input.substring(lastNumberIndex).trim();
                }
                
                // Return the original string if no number is found
                return input;
            }

            setIsEditModalOpen(true);
            setEditClientUserId(params.data.id);
            setEditContactName(getUserName(params.data.name));

            const chatflow_label_list = params.data.building;
            const chatflow_value_list = params.data.chatflow_id;
            const checkin_list = params.data.checkin_datetime;
            const checkout_list = params.data.checkout_datetime;
            const sendCheckIn_list =  Array.isArray(params.data.checkin_list) ? params.data.checkin_list : JSON.parse(params.data.checkin_list?.replace(/'/g, '"'));
            const sendCheckOut_list = Array.isArray(params.data.checkout_list) ? params.data.checkout_list : JSON.parse(params.data.checkout_list?.replace(/'/g, '"'));
            const unit_list = JSON.parse(params.data.unit_no?.replace(/'/g, '"'));
            let building_list = [];
            for (let i = 0; i < chatflow_label_list.length; i++) {
                const checkin_date = checkin_list[i];
                const checkout_date = checkout_list[i];
                const unit = unit_list[i];
                const sendCheckIn = sendCheckIn_list[i];
                const sendCheckOut = sendCheckOut_list[i];
                building_list.push({
                    'id': Date.now() + i,
                    'visible': true,
                    'checkIn': new Date(checkin_date),
                    'checkOut': new Date(checkout_date),
                    'unit': unit,
                    'checkin_status': params.data.checkin_status,
                    'checkout_status': params.data.checkout_status,
                    'sendCheckIn': sendCheckIn,
                    'sendCheckOut': sendCheckOut,
                    'sendCheckOutAgain': false,
                    'sendCheckInAgain': false,
                    'template': {'label': chatflow_label_list[i], 'value': chatflow_value_list[i] }
                });
            }
            setCheckEditBuildings(building_list);
            setEditBuildings(building_list);
        }
        return <button className='contactEditBtn' onClick={ onClick }>Edit</button>;
    }

    // Set table definition
    const [rowData, setRowData] = useState([]);
    const [columnDef, setColumnDef] = useState([
        { field: 'id', resizable: false, hide: true, flex: 1 },
        { field: 'chatflow_id', resizable: false, hide: true, flex: 1 },
        { field: 'name', headerName: 'Name', resizable: true, flex: 1, filter: "agSetColumnFilter", menuTabs: ['filterMenuTab', 'generalMenuTab', 'columnsMenuTab'],   },
        { field: 'building', headerName: 'Building', resizable: true, flex: 1, filter: "agSetColumnFilter", menuTabs: ['filterMenuTab', 'generalMenuTab'] },
        { field: 'unit_no', headerName: 'Unit', resizable: true, flex: 1 },
        { field: 'phone_number', headerName: 'Phone Number', resizable: true, flex: 1 },
        { field: 'checkin_datetime', headerName: 'Check In', resizable: true, flex: 1 },
        { field: 'checkout_datetime', headerName: 'Check Out', resizable: true, flex: 1 },
        { field: 'checkin_status', headerName: 'Check In Status', resizable: false, hide: true, flex: 1 },
        { field: 'checkout_status', headerName: 'Check Out Status', resizable: false, hide: true, flex: 1 }, 
        { field: 'checkin_list', headerName: 'Check In List', resizable: false, hide: true, flex: 1 }, 
        { field: 'checkout_list', headerName: 'Check Out List', resizable: false, hide: true, flex: 1 },
        { field: 'edit_btn', headerName: 'Edit', cellRenderer: editContactBtn, resizable: false, flex: 1},
        { field: 'delete_btn', headerName: 'Delete', cellRenderer: deleteContactBtn, resizable: false, flex: 0.6 },
    ].map(col => ({
        ...col,
        wrapText: true,
    })));
    

    const [gridOptions, setGridOptions] = useState({
        animateRows: true,
        onCellClicked: (params) => {
            if (params.colDef.field) {
                const cell = params.event.target.closest('.ag-cell');
                if (cell) {
                    if (params.node.data.isExpanded) {
                        // Collapse the row back to the original height
                        params.node.setRowHeight(45);
                        params.node.data.isExpanded = false;
                        cell.classList.remove('expanded-cell');  // Remove expanded class
                    } else {
                        // Expand the row to fit the content
                        const cellHeight = cell.scrollHeight;
                        params.node.setRowHeight(cellHeight);
                        params.node.data.cellHeight = cellHeight;
                        params.node.data.isExpanded = true;
                        cell.classList.add('expanded-cell');  // Add expanded class
                    }

                    // Trigger a height recalculation
                    params.api.onRowHeightChanged();
                }
            }
        },
        getRowHeight: (params) => {
            return params.node.data.isExpanded ? params.node.data.cellHeight : 45;
        },
    });

    const onRowClicked = useCallback((params) => {
        params.node.setExpanded(!params.node.expanded);
        params.api.resetRowHeights();
    }, []);

    // Handle quick filter
    const onFilterTextBoxChanged = useCallback(() => {
        gridRef.current.api.setQuickFilter(
            document.getElementById("filter-text-box").value
        );
    }, []);

    // Get ClientUser and MassFlow data when page loads
    useEffect(() => {
        const fetchData = async () => {
            try {

                const data = await API.getClientUserList(userToken);
                const formattedData = await Promise.all(data?.map(async (contact) => {
                    let chatflowIds = [];
                    let checkinDates = [];
                    let checkoutDates = [];
                    let checkin_list = [];
                    let checkout_list = [];

                    try {
                        chatflowIds = JSON.parse(contact.chatflow_id || '[]');
                    } catch (error) {
                        console.error('Error parsing chatflow_id:', error);
                    }
                    try {
                        checkinDates = JSON.parse(contact.checkin_datetime.replace(/'/g, '"') || '[]');
                    } catch (error) {
                        console.error('Error parsing checkin_datetime:', error);
                    }
                    try {
                        checkoutDates = JSON.parse(contact.checkout_datetime.replace(/'/g, '"') || '[]');
                    } catch (error) {
                        console.error('Error parsing checkout_datetime:', error);
                    }
                    try {
                        checkin_list = JSON.parse(contact.checkin_list.replace(/'/g, '"') || '[]');
                        console.log(checkin_list);
                    } catch (error) {
                        console.error('Error parsing checkin_list', error);
                    }
                    try {
                        checkout_list = JSON.parse(contact.checkout_list.replace(/'/g, '"') || '[]');
                    } catch (error) {
                        console.error('Error parsing checkout_list', error);
                    }

                    const buildingArray = await Promise.all(
                        chatflowIds.map(async (chatflow_id) => {
                            const chatflow = await API.getChatFlow({ 'token': userToken, 'id': chatflow_id });
                            return chatflow.chatflow_name;
                        })
                    );
        
                    const formattedCheckIn = checkinDates.map(dateString => {
                        const date = new Date(dateString);
                        const year = date.getFullYear();
                        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
                        const day = date.getDate().toString().padStart(2, '0');
                        return `${year}-${month}-${day}`;
                    });
        
                    const formattedCheckOut = checkoutDates.map(dateString => {
                        const date = new Date(dateString);
                        const year = date.getFullYear();
                        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
                        const day = date.getDate().toString().padStart(2, '0');
                        return `${year}-${month}-${day}`;
                    });
                    return {
                        id: contact.id,
                        building: buildingArray,
                        name: contact.name,
                        phone_number: contact.phone_number,
                        unit_no: contact.unit_no,
                        checkin_status: contact.checkin_status,
                        checkout_status: contact.checkout_status,
                        checkin_list: checkin_list,
                        checkout_list: checkout_list,
                        checkin_datetime: formattedCheckIn,
                        checkout_datetime: formattedCheckOut,
                        chatflow_id: JSON.parse(contact.chatflow_id),
                    };
                }));
                setRowData(formattedData);
            } catch (error) {
                console.error(error);
            }
        };

        const fetchBuildingAndUnit = async() => {
            try {
                const data= await API.getChatFlowList(userToken);
                // Massflow data
                let filteredData = []
                try {
                    filteredData = data?.filter(chatflow => chatflow.type === 'massflow' && chatflow.error_status===true).map(chatflow => ({value: chatflow.id, label: chatflow.chatflow_name}))
                    setMassFlowData(data?.filter(chatflow => chatflow.type === 'massflow'));
                    setMassFlowOption(filteredData);
                } catch(error) {
                    console.error(error);
                }

                // Unit data
                let mappedUnit = {};

                const fetchUnitNodes = async (chatflow) => {
                    try {
                        const response = await API.getNodes({ 'token': userToken, 'id': chatflow.value });
                        const unitNodes = response?.filter(node => node.node_type === 'unitNode');
                        
                        // Combine the text fields into a single array
                        const unitNodeComplete = unitNodes.reduce((acc, node) => {
                            const nodeTextArray = JSON.parse(node.text);
                            return [...acc, ...nodeTextArray];
                        }, []);
                        
                        mappedUnit[chatflow.label] = unitNodeComplete.map(node => ({ value: node, label: node }));
                    } catch (error) {
                        console.error(error);
                        throw error;
                    }
                };

                await Promise.all(filteredData.map(chatflow => fetchUnitNodes(chatflow)));
                setUnitOption(mappedUnit);
            } catch(error) {
                console.error(error);
                throw(error);
            }
        }

        fetchBuildingAndUnit();
        fetchData();
    }, [userToken, subscriptionPlan]);



    // Add contacts window
    const openModal = (e) => {
        e.stopPropagation();
        if (rowData.length >= planContactLimit[subscriptionPlan]) {
            toast.error('You have reached the maximum number of contacts for your subscription plan');
            return;
        }
        setIsModalOpen(true);
    };

    
    // Contact modal state handling -------------------------------------------------------------------
    const deleteBuilding = (index, id) => {
        setBuildings(prevBuildings => 
            prevBuildings.map(building => {
                console.log(building.id, id);
                return building.id === id ? { ...building, visible: false } : building;
            })
        );
        setTimeout(() => {
            setBuildings(prevBuildings => prevBuildings.filter(building => building.id !== id));
        }, 300); // This should match your transition duration
    }

    const handleAddBuilding = () => {
        setBuildings(prevBuildings => [...prevBuildings, { id: Date.now(), visible: false}]);
        setTimeout(() => {
            setBuildings(prevBuildings => 
                prevBuildings.map(building => 
                    building.visible ? building : { ...building, visible: true, sendCheckIn: false, sendCheckOut: false }
                )
            );
        }, 1);
    }

    const handlePhoneChange = (e) => {
        let input = e.target.value;
        if (input.length === 1 && input !== '+') {
            input = '+' + input;
        }
        setPhoneNumber(input);

        try {
            if (input) {
                const parsedNumber = parsePhoneNumber(input);
                if (isValidPhoneNumber(input)) {
                    setPhoneNumber(parsedNumber.formatInternational());
                    setPhoneError('');
                } else {
                    setPhoneError('Invalid phone number');
                }
            } else {
                setPhoneError('');
            }
        } catch (error) {
            setPhoneError('Invalid phone number');
        }
    };

    const handleTemplateChange = (index, selectedOption) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                template: selectedOption
            };
            return updatedBuildings;
        });
    };

    const handleUnitChange = (index, selectedOption) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                unit: selectedOption.label
            };
            return updatedBuildings;
        });
    };

    const handleCheckInDateChange = (index, date) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                checkIn: date
            };
            return updatedBuildings;
        });
    };

    const handleCheckOutDateChange = (index, date) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                checkOut: date
            };
            return updatedBuildings;
        });
    };

    const handleSendCheckIn = (index, selectedOption) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                sendCheckIn: selectedOption.target.checked
            };
            return updatedBuildings;
        });
    }

    const handleSendCheckOut = (index, selectedOption) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                sendCheckOut: selectedOption.target.checked
            };
            return updatedBuildings;
        });
    }

    // button function
    const addContactButton = () => {
        console.log(waPhoneNumberId);
        console.log(waPhoneNumber);
        // 1. Validation part
        if (!contactName || !phoneNumber) {
            toast.error('Please fill in all the fields');
            return;
        }
        if (phoneError) {
            toast.error('Invalid phone number');
            return;
        }
        if (buildings.length === 0) {
            toast.error('Please add at least one building');
            return;
        }

        // Prepare data to send to API while validating form
        let checkin = [];
        let checkout = [];
        let unit = [];
        let templateLabel = [];
        let templateValue = [];
        let checkInBoolean = [];
        let checkOutBoolean = [];
        buildings.map(building => {
            if (!building.template || !building.unit || !building.checkIn || !building.checkOut) {
                    toast.error('Please fill in all the fields');
                    return;
                }

            // Check date validation
            const checkIn = new Date(building.checkIn);
            const checkOut = new Date(building.checkOut);
            if (checkIn >= checkOut) {
                toast.error('Check-out date must be after check-in date');
                return;
            }

            // If data no problem, append to list for API data preparation
            checkin.push(new Date(building.checkIn));
            checkout.push(new Date(building.checkOut));
            unit.push(building.unit);
            templateLabel.push(building.template.label);
            templateValue.push(building.template.value);
            checkInBoolean.push(building.sendCheckIn);
            checkOutBoolean.push(building.sendCheckOut);

        });


        // 2. Add contact
        const addContact = async() => {
            try {
                if (!isButtonDisabled) {
                    setIsButtonDisabled(true)
                    // Initialize the formatted contact name
                    let formattedContactName = "";
                    // Iterate over the arrays and format the contact name
                    for (let i = 0; i < templateLabel.length; i++) {
                        if (i > 0) {
                            formattedContactName += ", ";
                        }
                         // Reformat date and add user into contact
                        let checkInDate = checkin[i];
                        let checkOutDate = checkout[i];
                        let checkInDay = checkInDate.getDate().toString().padStart(2, '0');
                        const checkInMonth = (checkInDate.getMonth() + 1).toString().padStart(2, '0'); 
                        const formattedCheckInDate = `${checkInDay}/${checkInMonth}`;
                        let checkOutDay = checkOutDate.getDate().toString().padStart(2, '0');
                        const checkOutMonth = (checkOutDate.getMonth() + 1).toString().padStart(2, '0'); 
                        const formattedCheckOutDate = `${checkOutDay}/${checkOutMonth}`;

                        formattedContactName += `${templateLabel[i]} ${unit[i]} ${formattedCheckInDate}-${formattedCheckOutDate}`;
                    }

                    // Append the contact name
                    formattedContactName += ` ${contactName}`;
                    try {
                        const response = await API.addNewContacts({
                            'token': userToken,
                            'phone': phoneNumber.replace(/\s+/g, '').replace('+', ''),
                            'waPhoneNumberId': waPhoneNumberId,
                            'username': formattedContactName,
                            'userId': userId,
                            'SystemUserToken': SystemUserToken,
                            'checkin_datetime': checkin, 
                            'checkout_datetime': checkout, 
                            'checkin_list': checkInBoolean,
                            'checkout_list': checkOutBoolean,
                            'unit_no': unit,
                            'chatflow_id': templateValue,
                            'wabaId': wabaId
                        });
                        if (response === 'User Successfully Added' || response === 'Contact added') {
                            let checkInSent = false;
                            let checkOutSent = false;

                            // Send message immediately if slide button is checked
                            // 1. Check in list
                            checkInBoolean.map(async(checkIn, index) => {
                                if (checkIn) {
                                    checkInSent = true;
                                    // Send checkin massflow message;
                                    const response = await API.massFlowAlgorithm({
                                        'token': userToken,
                                        'chatflow_id': templateValue[index],
                                        'unit': unit[index],
                                        'waba_id': wabaId,
                                        'phone_number_id': waPhoneNumberId,
                                        'to_phone_number': phoneNumber.replace(/\s+/g, '').replace('+', ''),
                                        'type': 'checkInNode'
                                    })
                                }
                            })

                            // 2. Check out list
                            checkOutBoolean.map(async(checkOut, index) => {
                                if (checkOut) {
                                    checkOutSent = true;
                                    // Send checkout massflow message
                                    const response = await API.massFlowAlgorithm({
                                        'token': userToken,
                                        'chatflow_id': templateValue[index],
                                        'unit': unit[index],
                                        'waba_id': wabaId,
                                        'phone_number_id': waPhoneNumberId,
                                        'to_phone_number': phoneNumber.replace(/\s+/g, '').replace('+', ''),
                                        'from_phone_number': waPhoneNumber,
                                        'type': 'checkOutNode'
                                    })
                                }
                            })

                            toast.success('Contact added successfully');
                            if (checkInSent) {
                                toast.success('Check-in message sent successfully');
                            }
                            if (checkOutSent) {
                                toast.success('Check-out message sent successfully');
                            }
                            setIsButtonDisabled(false);

                        } else {
                            toast.error(response);
                            setIsButtonDisabled(false);
                        }
                    } catch (error) {
                        toast.error(error);
                        setIsButtonDisabled(false);
                    }
                }
            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        addContact();
    }
    // -----------------------------------------------------------------------------------------------

    // Function for edit contact state handling ---------------------------------
    const deleteEditBuilding = (index, id) => {
        setEditBuildings(prevBuildings => 
            prevBuildings.map(building => {
                return building.id === id + index ? { ...building, visible: false } : building;
            })
        );
        setTimeout(() => {
            setEditBuildings(prevBuildings => prevBuildings.filter(building => building.id !== id));
        }, 300); // This should match your transition duration
    }

    const handleEditAddBuilding = () => {
        setEditBuildings(prevBuildings => [...prevBuildings, { id: Date.now(), visible: false}]);
        setTimeout(() => {
            setEditBuildings(prevBuildings => 
                prevBuildings.map(building => 
                    building.visible ? building : { ...building, visible: true, sendCheckOutAgain: false, sendCheckInAgain: false,}
                )
            );
        }, 1);
    }

    const handleEditTemplateChange = (index, selectedOption) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                template: selectedOption
            };
            return updatedBuildings;
        });
    };

    const handleEditUnitChange = (index, selectedOption) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                unit: selectedOption.label
            };
            return updatedBuildings;
        });
    };

    const handleEditCheckInDateChange = (index, date) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                checkIn: date
            };
            return updatedBuildings;
        });
    };

    const handleEditCheckOutDateChange = (index, date) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                checkOut: date
            };
            return updatedBuildings;
        });
    };

    const handleEditSendCheckInAgain = (index, selectedOption) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                sendCheckInAgain: selectedOption.target.checked
            };
            return updatedBuildings;
        });
    }

    const handleEditSendCheckOutAgain = (index, selectedOption) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                sendCheckOutAgain: selectedOption.target.checked
            };
            return updatedBuildings;
        });
    }

    const editContact = async() => {
        if (editBuildings.length === 0) {
            toast.error('Please add at least one building');
            return;
        }

        // Prepare data to send to API while validating form
        let status = true;
        let checkin = [];
        let checkout = [];
        let unit = [];
        let templateLabel = [];
        let templateValue = [];
        let checkInBoolean = [];
        let checkOutBoolean = [];
        let checkInStatus = null;
        let checkOutStatus = null;
        editBuildings.map((building, index) => {
            if (!building.template || !building.unit || !building.checkIn || !building.checkOut) {
                    toast.error('Please fill in all the fields');
                    return;
                }

            // Check date validation
            const checkIn = new Date(building.checkIn);
            const checkOut = new Date(building.checkOut);
            if (checkIn >= checkOut) {
                toast.error('Check-out date must be after check-in date');
                status = false;
            }

            // Check if user wants to resend checkin or checkout again
            if (building.sendCheckInAgain) {
                checkInStatus = false;
                checkInBoolean.push('False');
            } else {
                checkInStatus = checkEditBuildings['checkin_status'] || false;
                checkInBoolean.push(building.sendCheckIn || 'False');
            }

            if (building.sendCheckOutAgain) {
                checkOutStatus = false;
                checkOutBoolean.push('False');
            } else {
                checkOutStatus = checkEditBuildings['checkout_status'] || false;
                checkOutBoolean.push(building.sendCheckOut || 'False');
            }

            // If data no problem, append to list for API data preparation
            checkin.push(checkIn);
            checkout.push(checkOut);
            unit.push(building.unit);
            templateLabel.push(building.template.label);
            templateValue.push(building.template.value);
            
        });

        // If anything wrong with data, stop the edit procedure
        if (!status) {
            return;
        }

        // Set contact Name
        // Initialize the formatted contact name
        let formattedContactName = "";
        // Iterate over the arrays and format the contact name
        for (let i = 0; i < templateLabel.length; i++) {
            if (i > 0) {
                formattedContactName += ", ";
            }
             // Reformat date and add user into contact
            let checkInDate = checkin[i];
            let checkOutDate = checkout[i];
            let checkInDay = checkInDate.getDate().toString().padStart(2, '0');
            const checkInMonth = (checkInDate.getMonth() + 1).toString().padStart(2, '0'); 
            const formattedCheckInDate = `${checkInDay}/${checkInMonth}`;
            let checkOutDay = checkOutDate.getDate().toString().padStart(2, '0');
            const checkOutMonth = (checkOutDate.getMonth() + 1).toString().padStart(2, '0'); 
            const formattedCheckOutDate = `${checkOutDay}/${checkOutMonth}`;

            formattedContactName += `${templateLabel[i]} ${unit[i]} ${formattedCheckInDate}-${formattedCheckOutDate}`;
        }

        // Append the contact name
        formattedContactName += ` ${editContactName}`;


        try {
            const response = await API.editClientUser({
                'token': userToken,
                'id': editClientUserId,
                'name': formattedContactName,
                'unit_no': unit,
                'checkin_datetime': checkin,
                'checkout_datetime': checkout,
                'checkin_status': checkInStatus,
                'checkout_status': checkOutStatus,
                'checkin_list': checkInBoolean,
                'checkout_list': checkOutBoolean,
                'chatflow_id': templateValue,
            });

            // Update row data with the new data
            let chatflowIds = [];
            let checkinDates = [];
            let checkoutDates = [];
            try {
                chatflowIds = JSON.parse(response.chatflow_id || '[]');
            } catch (error) {
                console.error('Error parsing chatflow_id:', error);
            }
            try {
                checkinDates = JSON.parse(response.checkin_datetime.replace(/'/g, '"') || '[]');
            } catch (error) {
                console.error('Error parsing checkin_datetime:', error);
            }
            try {
                checkoutDates = JSON.parse(response.checkout_datetime.replace(/'/g, '"') || '[]');
            } catch (error) {
                console.error('Error parsing checkout_datetime:', error);
            }

            const buildingArray = await Promise.all(
                chatflowIds.map(async (chatflow_id) => {
                    const chatflow = await API.getChatFlow({ 'token': userToken, 'id': chatflow_id });
                    return chatflow.chatflow_name;
                })
            );

            const formattedCheckIn = checkinDates.map(dateString => {
                const date = new Date(dateString);
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
                const day = date.getDate().toString().padStart(2, '0');
                return `${year}-${month}-${day}`;
            });

            const formattedCheckOut = checkoutDates.map(dateString => {
                const date = new Date(dateString);
                const year = date.getFullYear();
                const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-based
                const day = date.getDate().toString().padStart(2, '0');
                return `${year}-${month}-${day}`;
            });

            const data = {
                id: response.id,
                building: buildingArray,
                name: response.name,
                phone_number: response.phone_number,
                unit_no: response.unit_no,
                checkin_datetime: formattedCheckIn,
                checkout_datetime: formattedCheckOut,
                checkin_list: response.checkin_list,
                checkout_list: response.checkout_list,
                checkin_status: response.checkin_status,
                checkout_status: response.checkout_status,
                chatflow_id: JSON.parse(response.chatflow_id),
            };
            setRowData(currentRowData => {
                return currentRowData.map(object => 
                    object.id === response.id ? { ...object, ...data } : object
                );
            });
            setIsEditModalOpen(false);
            toast.success('Contact updated successfuly');
            closeModal();
        } catch (error) {
            console.error(error);
            toast.error('Something wrong happened')
        }
    }
    // --------------------------------------------------------------------------


    // Sliding button logic -----------------------------------------------------
    // --------------------------------------------------------------------------

    return (
        <React.Fragment>
            <Helmet>
                <title>StreamHost | Contacts Page</title>
            </Helmet>
            <Sidenav />
            <div className='contacts-container'>
                <div className='contacts-header'>
                    <h1>Contacts</h1>
                </div>

                <div className="contacts ag-theme-quartz">
                    <button className='create-contact-btn' onClick={openModal}>Create New Contact</button>
                    <input type="text" id="filter-text-box" className="quick-filter-input" placeholder="Quick filter..." onInput={onFilterTextBoxChanged}/>
                    <AgGridReact ref={gridRef} rowData={rowData} columnDefs={columnDef} gridOptions={gridOptions} onRowClicked={onRowClicked}/>
                </div>
                <ToastContainer style={{zIndex: '100000001'}}position='bottom-center' autoClose={5000} hideProgreeBar={false} closeOnClick pauseOnFocusLoss draggable rtl={false} pauseOnHover theme='colored' transition={Bounce} />
            </div>
            {isModalOpen && (
                <main className={`test-modal-main ${isModalOpen ? 'open' : ''}`}>
                    <div className='modal-container'>
                        <div className='modal'>
                            <div className='test-modal-header'>
                                <IoIosArrowBack style={{'fontSize': '1.5rem'}} className='test-modal-icon' onClick={()=>setIsModalOpen(false)}/>
                                <h2>Create new contact</h2>
                            </div>
                            <div className='test-modal-contact'>
                                <h3>Contact details</h3>
                                <div className="input-container">
                                    <input type="text" id="myInput" value={contactName} placeholder="Enter contact name" onChange={(e) => setContactName(e.target.value)}/>
                                    <label htmlFor="myInput">Enter contact name</label>
                                </div>
                                <div className="input-container">
                                    <input type="tel" id="phoneInput" placeholder="Enter phone number" value={phoneNumber} onChange={handlePhoneChange}/>
                                    <label htmlFor="phoneInput">Enter phone number</label>
                                    {phoneError && <div className="error-message">{phoneError}</div>}
                                </div>
        
                                {buildings?.map((building, index) => (
                                    <div key={index}className={`building-div ${building.visible ? 'visible' : ''}`}>
                                        <div className='building-header'>
                                            <h3>{buildings[index]['template']?.label ? buildings[index]['template']['label'] : `Building ${index + 1}`} {buildings[index]['unit'] ? buildings[index]['unit'] : ''}</h3>
                                            <MdDelete className='delete-icon' onClick={() => deleteBuilding(index, building.id)} style={{ fontSize: '1.5rem' }} />
                                        </div>
                                        <div className='building-info'>
                                            <Select className='modal-select' placeholder={'Select building'} options={massFlowOption} value={buildings[index]['template']?.value ? {'label': buildings[index]['template']?.label, 'value': buildings[index]['template']?.value} : null} onChange={(selectedOption) => handleTemplateChange(index, selectedOption)} />
                                            <Select className='modal-select' placeholder={'Select unit'} options={unitOption[buildings[index]['template']?.label]} value={buildings[index]['unit'] ? {'label': buildings[index]['unit'], 'value': buildings[index]['unit']} : null} onChange={(selectedOption) => handleUnitChange(index, selectedOption)} />
                                            <div className='test-modal-date'>
                                                <div>
                                                    <label htmlFor='checkin'>Check In</label>
                                                    <StyledDateTimePicker id='checkin' selected={buildings[index]['checkIn']} onChange={(date) => handleCheckInDateChange(index, date)} dateFormat="MMMM d, yyyy" placeholderText="Check in" />
                                                    <div className='switch-div'>
                                                        <label className="switch">
                                                            <input type="checkbox" id="toggleButton" value={buildings[index]['sendCheckIn']} onChange={(selectedOption) => handleSendCheckIn(index, selectedOption)}/>
                                                            <span className="slider-btn"></span>
                                                        </label>
                                                        <p>Send check-in details now</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor='checkout'>Check Out</label>
                                                    <StyledDateTimePicker id='checkout' selected={buildings[index]['checkOut']} onChange={(date) => handleCheckOutDateChange(index, date)} dateFormat="MMMM d, yyyy" placeholderText="Check out" />
                                                    <div className='switch-div'>
                                                        <label className="switch">
                                                            <input type="checkbox" id="toggleButton" value={buildings[index]['sendCheckOut']} onChange={(selectedOption) => handleSendCheckOut(index, selectedOption)}/>
                                                            <span className="slider-btn"></span>
                                                        </label>
                                                        <p>Send check-out details now</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        
                                    </div>
                                ))}
        
                                <div className={'test-modal-add'} onClick={handleAddBuilding}>
                                    <IoHomeSharp style={{fontSize:'1.5rem'}}/>
                                    <p>Add building</p>
                                </div>
        
                                <div className='test-modal-add-btn'>
                                    <button className='test-modal-btn' onClick={addContactButton} disabled={isButtonDisabled}>{isButtonDisabled}Add contact</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            )}

            {isEditModalOpen && (
                <main className={`test-modal-main ${isModalOpen ? 'open' : ''}`}>
                    <div className='modal-container'>
                        <div className='modal'>
                            <div className='test-modal-header'>
                                <IoIosArrowBack style={{'fontSize': '1.5rem'}} className='test-modal-icon' onClick={()=>setIsEditModalOpen(false)}/>
                                <h2>Edit contact</h2>
                            </div>
                            <div className='test-modal-contact'>
                                <h3>Contact details</h3>
                                <div class="input-container">
                                    <input type="text" id="myInput" value={editContactName} placeholder="Enter contact name" onChange={(e) => setEditContactName(e.target.value)}/>
                                    <label htmlFor="myInput">Enter contact name</label>
                                </div>
                                {editBuildings?.map((building, index) => (
                                    <div key={index}className={`building-div ${building.visible ? 'visible' : ''}`}>
                                        <div className='building-header'>
                                            <h3>{editBuildings[index]['template']?.label ? editBuildings[index]['template']['label'] : `Building ${index + 1}`} {editBuildings[index]['unit'] ? editBuildings[index]['unit'] : ''}</h3>
                                            <MdDelete className='delete-icon' onClick={() => deleteEditBuilding(index, building.id)} style={{ fontSize: '1.5rem' }} />
                                        </div>
                                        <div className='building-info'>
                                            <Select className='modal-select' placeholder={'Select building'} options={massFlowOption} value={editBuildings[index]['template']?.value ? {'label': editBuildings[index]['template']?.label, 'value': editBuildings[index]['template']?.value} : null} onChange={(selectedOption) => handleEditTemplateChange(index, selectedOption)} />
                                            <Select className='modal-select' placeholder={'Select unit'} options={unitOption[editBuildings[index]['template']?.label]} value={editBuildings[index]['unit'] ? {'label': editBuildings[index]['unit'], 'value': editBuildings[index]['unit']} : null} onChange={(selectedOption) => handleEditUnitChange(index, selectedOption)} />
                                            <div className='test-modal-date'>
                                                <div>
                                                    <label htmlFor='checkin'>Check In</label>
                                                    <StyledDateTimePicker id='checkin' selected={editBuildings[index]['checkIn']} onChange={(date) => handleEditCheckInDateChange(index, date)} dateFormat="MMMM d, yyyy" placeholderText="Check in" />
                                                    <div className='switch-div'>
                                                        <label class="switch">
                                                            <input type="checkbox" id="toggleButton" value={editBuildings[index]['sendCheckInAgain']} onChange={(selectedOption) => handleEditSendCheckInAgain(index, selectedOption)}/>
                                                            <span class="slider-btn"></span>
                                                        </label>
                                                        <p>Send check-in details again?</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor='checkout'>Check Out</label>
                                                    <StyledDateTimePicker id='checkout' selected={editBuildings[index]['checkOut']} onChange={(date) => handleEditCheckOutDateChange(index, date)} dateFormat="MMMM d, yyyy" placeholderText="Check out" />
                                                    <div className='switch-div'>
                                                        <label class="switch">
                                                            <input type="checkbox" id="toggleButton" value={editBuildings[index]['sendCheckOutAgain']} onChange={(selectedOption) => handleEditSendCheckOutAgain(index, selectedOption)}/>
                                                            <span class="slider-btn"></span>
                                                        </label>
                                                        <p>Send check-out details again?</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
        
                                <div className={'test-modal-add'} onClick={handleEditAddBuilding}>
                                    <IoHomeSharp style={{fontSize:'1.5rem'}}/>
                                    <p>Add building</p>
                                </div>
        
                                <div className='test-modal-add-btn'>
                                    <button className='test-modal-btn' onClick={editContact} disabled={isButtonDisabled}>{isButtonDisabled}Confirm edit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            )}
            <HelpButton />
        </React.Fragment>
    )
}

export default Contacts;



import React, { useState, useEffect, useContext, useRef } from "react";
import {Helmet} from 'react-helmet';
import { useWebSocketContext } from './Websocket.js';
import { UserContext } from "../user-context.js";
import Sidenav2 from "../components/Sidenav.js";
import { API } from "../api-service.js";
import { useParams } from 'react-router-dom';
import './css/Inbox.css';
import Select from 'react-select';
import ChatArea from '../components/ChatArea';
import ChatRoomList from '../components/ChatRoomList';
import { FaImage, FaFile, FaFileWord, FaFilePdf, FaFileExcel, FaFilePowerpoint } from 'react-icons/fa';
import { IoMdPersonAdd, IoIosArrowBack, IoIosChatboxes } from "react-icons/io";
import { IoVideocam, IoDocuments, IoHomeSharp } from "react-icons/io5";
import { BsTranslate, BsThreeDotsVertical } from "react-icons/bs";
import { PiLinkBreakBold } from "react-icons/pi";
import { FaPlus, FaRobot } from "react-icons/fa6";
import { LuListTodo } from "react-icons/lu";
import sendSymbolImage from '../assets/send-symbol.png';
import { Bounce, ToastContainer, toast} from 'react-toastify';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import styled from 'styled-components';
import { parsePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { MdDelete } from "react-icons/md";
import MobileImageSelector from '../components/MobileImageSelector';

import 'react-phone-input-2/lib/material.css';
import 'react-phone-input-2/lib/bootstrap.css';
import EXIF from 'exif-js';
import { TemplateNode } from '../components/CustomNodes';
import { FaMicrophone, FaStop } from 'react-icons/fa';
import { FFmpeg } from '@ffmpeg/ffmpeg';
import { fetchFile } from '@ffmpeg/util';

const StyledDateTimePicker = styled(DatePicker)`
        width: 100%;
        padding: 10px;
        font-size: 16px;
        border: 1px solid #ccc;
        border-radius: 4px;
        transition: none;

        &:focus {
        margin: 0;
        outline: none; /* Remove default focus outline */
        }
        `;

function Inbox() {

    // User information
    const SystemUserToken = "EAANbHtHZC5BQBOZCHluZAO43Kru1a9sEgxZCxEy0iuLi7ZBMoG2Fl8j8xdXjsHFR1Rwx9CeHdjd4qwEEeroywc6TZA51UkQmcDjL91kIfTLHaeZACq77PiTKUbiL2FcaZATKnf0zYww3BmZAOEQgaNBj1aZAvT8VyPXYgNLQK8DtfbnxDLZBFHtMHYhLj9FZBlSx86WhtbZAZBznbLrzi3";
    const { userToken, profileId, setProfileId, userId, setUserId, setContextUserName, setContextFirstName, setContextLastName, userType, setUserType, 
            setCountry, setTimeZone, imageUrl, setImageUrl, setWabaId, wabaId, waPhoneNumber, setWaPhoneNumber, setWabaAccessToken, waPhoneNumberId, setWaPhoneNumberId, setContextAbout, subscriptionPlan, setSubscriptionPlan, 
            translateFunction, setTranslateFunction, chatGPTFunction, setChatGPTFunction, setNotAllowTaskTypeNotification, setWhatsappNotification } = useContext(UserContext);

    const { updateActivePhoneNumber, toggleConnection } = useWebSocketContext();
    const [messageInput, setMessageInput] = React.useState('');
    const { phone_number } = useParams();
    const [users, setUsers] = useState([]);
    const [activeChatRoomDetails, setActiveChatRoomDetails] = useState(null);
    const [currentParticipantName, setCurrentParticipantName] = useState([]);
    const [currentParticipantPhoneNumber, setCurrentParticipantPhoneNumber] = useState();
    const [clientUserLength, setClientUserLength] = useState(0);
    const [activePhoneId, setActivePhoneId] = useState(localStorage.getItem('waba_id') || null);
    const planContactLimit = {
        'Free': 100,
        'Small': 5000,
        'Medium': 10000,
        'Large': 20000
    }
    
    // Socket connection
    const socketRef = useRef(null);

    // Option menu component states
    const [showDropdown, setShowDropdown] = useState(false);
    const dropdownRef = useRef(null); 
    const [showTranslateDropdown, setShowTranslateDropdown] = useState(false);

    // Command component states
    const commandRef = useRef(null);
    const [command, setCommand] = useState([]);
    const [commandWindow, setCommandWindow] = useState(false);
    const [filterCommand, setFilterCommand] = useState([]);

    //File component states
    const [selectedFile, setSelectedFile] = useState(null);
    const [previewUrl, setPreviewUrl] = useState('');
    const fileInputRef = useRef(null);

    // ToDoList component states
    const [todolistModal, setTodolistModal] = useState(false);
    const [todolistPhone, setTodolistPhone] = useState('');
    const [todolistOption, setTodolistOption] = useState([]);
    const [selectedTag, setSelectedTag] = useState('');
    const [todolistDescription, setTodolistDescription] = useState('');

    // Whatsapp template component
    const [whatsappTemplateModal, setWhatsappTemplateModal] = useState(false)
    const [isTemplateButtonDisabled, setIsTemplateButtonDisabled] = useState(false);

    // Text area component states
    const [textAreaHeight, setTextAreaHeight] = useState('auto');
    const textAreaRef = useRef(null);
    const [MessageToChatArea, setMessageToChatArea] = useState('');
    const [ImageToChatArea, setImageToChatArea] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 867);
    const [activeView, setActiveView] = useState('inbox');

    // Loading component states for add contact
    const [massFlowOption, setMassFlowOption] = useState([])
    const [massFlowData, setMassFlowData] = useState([]);
    const [unitOption, setUnitOption] = useState([]);
    const [contactName, setContactName] = useState('');
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [buildings, setBuildings] = useState([]);

    // Components for edit contact
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editBuildings, setEditBuildings] = useState([]);
    const [editClientUserId, setEditClientUserId] = useState('');
    const [editContactName, setEditContactName] = useState('');
    const [editSelectedTemplate, setEditSelectedTemplate] = useState({});

    const [isChatbotActive, setIsChatbotActive] = useState(false);
    const [isActive, setIsActive] = useState(false);

    const [NotificationSubscription, setNotificationSubscription] = useState(false);
    const [isTextAreaDisabled, setIsTextAreaDisabled] = useState(false); 

    // WhatsApp Template States
    const [templates, setTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [parameters, setParameters] = useState({ header: [], body: [], button: [] }); // Ensuring it's an object with default empty arrays
    const [inputValues, setInputValues] = useState({ header: {}, body: {}, button: {} });
    const [whatsappImageUrl, setWhatsappImageUrl] = useState(null);

    // Add these state variables inside the Inbox component
    const [isRecording, setIsRecording] = useState(false);
    const [audioBlob, setAudioBlob] = useState(null);
    const mediaRecorderRef = useRef(null);
    const [audioPreviewUrl, setAudioPreviewUrl] = useState(null);
    const [AudioToChatArea, setAudioToChatArea] = useState(null);
    const audioChunksRef = useRef([]);
    const ffmpeg = new FFmpeg();
    const deviceId = localStorage.getItem('deviceId');

    const [isSendingFile, setIsSendingFile] = useState(false);
    const [audioContext, setAudioContext] = useState(null);
    //Testing new
    const processImage = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const img = new Image();
                img.onload = () => {
                    EXIF.getData(img, function() {
                        const orientation = EXIF.getTag(this, "Orientation");
                        const canvas = document.createElement('canvas');
                        const ctx = canvas.getContext('2d');
                        let width = img.width;
                        let height = img.height;
    
                        // Set proper canvas dimensions before transform & export
                        if (orientation > 4 && orientation < 9) {
                            canvas.width = height;
                            canvas.height = width;
                        } else {
                            canvas.width = width;
                            canvas.height = height;
                        }
    
                        // Transform context before drawing image
                        switch (orientation) {
                            case 2: ctx.transform(-1, 0, 0, 1, width, 0); break;
                            case 3: ctx.transform(-1, 0, 0, -1, width, height); break;
                            case 4: ctx.transform(1, 0, 0, -1, 0, height); break;
                            case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
                            case 6: ctx.transform(0, 1, -1, 0, height, 0); break;
                            case 7: ctx.transform(0, -1, -1, 0, height, width); break;
                            case 8: ctx.transform(0, -1, 1, 0, 0, width); break;
                            default: break;
                        }
    
                        ctx.drawImage(img, 0, 0);
                        canvas.toBlob((blob) => {
                            resolve(new File([blob], file.name, { type: 'image/jpeg' }));
                        }, 'image/jpeg', 0.9);
                    });
                };
                img.onerror = reject;
                img.src = event.target.result;
            };
            reader.onerror = reject;
            reader.readAsDataURL(file);
        });
    };

    useEffect(() => {
        const checkMobile = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        checkMobile();
        window.addEventListener('resize', checkMobile);

        return () => window.removeEventListener('resize', checkMobile);
    }, []);

    useEffect(() => {
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        setAudioContext(new AudioContext());
    
        return () => {
            if (audioContext) {
                audioContext.close();
            }
        };
    }, []);

    // UseEffect to fetch WhatsApp templates and their parameters
    useEffect(() => {

        //set active phone number id
        const setActivePhone = async (waba_id, waPhoneNumberId, waPhoneNumber)  => {
            setWabaId(waba_id);
            localStorage.setItem('waba_id', waba_id);
            setWaPhoneNumberId(waPhoneNumberId);
            localStorage.setItem('waPhoneNumberId', waPhoneNumberId);
            setWaPhoneNumber(waPhoneNumber);
            localStorage.setItem('waPhoneNumber', waPhoneNumber);
            updateActivePhoneNumber(waba_id);
        };

        // Fetch phone numbers
        const fetchWABADetails = async () => {
            const wabaId = await API.getWabaId(userToken);
            if (wabaId && wabaId.length > 0) {
                setWabaAccessToken(wabaId.map((item) => item.access_token));
                setWaPhoneNumberId(wabaId.map((item) => item.phone_number_id));
                localStorage.setItem('wabaAccessToken', wabaId.map((item) => item.access_token));
                localStorage.setItem('waPhoneNumberId', wabaId.map((item) => item.phone_number_id));

                // Set default active phone number
                const defaultWaba = wabaId[0];
                setActivePhone(defaultWaba.waba_id, defaultWaba.phone_number_id, defaultWaba.display_phone_number)
            }
        };

        const fetchSecondaryWABADetails = async (profileId) => {
            const wabaId = await API.getSecondaryWabaId({'profile_id': profileId, 'token': userToken});
            if (wabaId && wabaId.length > 0) {
                setWabaAccessToken(wabaId.map((item) => item.access_token));
                setWaPhoneNumberId(wabaId.map((item) => item.phone_number_id));
                localStorage.setItem('wabaAccessToken', wabaId.map((item) => item.access_token));
                localStorage.setItem('waPhoneNumberId', wabaId.map((item) => item.phone_number_id));
                localStorage.setItem('relatedUserProfileId', wabaId[0].profile);

                // Set default active phone number
                if (!activePhoneId) {
					const defaultWaba = wabaId[0];
					setActivePhone(defaultWaba.waba_id, defaultWaba.phone_number_id, defaultWaba.display_phone_number)
				}
            }
        }

        const fetchTaskTypes = async () => {
            try {
                const taskTypes = await API.getTaskTypes({'token': userToken});
            } catch (error) {
                console.error('An error occurred while fetching task types:', error);
            }
        }

        // after userToken is set, fetch user profile
        const fetchProfile = async () => {
            try {
                // Fetch user profile
                
                if (userToken) {
                    const data = await API.fetchProfile(userToken);
                    setProfileId(data['id']);
                    setUserId(data['user']['id']);
                    setContextUserName(data['user']['username']);
                    setContextFirstName(data['user']['first_name']);
                    setContextLastName(data['user']['last_name']);
                    setImageUrl(data['image']);
                    setNotAllowTaskTypeNotification(data['not_allowed_todo_notifications']);
                    setWhatsappNotification(data['allow_whatsapp_notifications']);
                    let condUserType = '';
                    if (data['related_user'] === 'main' || data['related_user'] === null) {
                        condUserType = 'main';
                        setUserType('main');
                        fetchWABADetails();
                    } else {
                        condUserType = 'secondary';
                        setUserType('secondary');
                        fetchSecondaryWABADetails(data['id']);
                    }
                    fetchTaskTypes();
                    // Save user profile data in local storage
                    localStorage.setItem('profileId', data['id']);
                    localStorage.setItem('userId', data['user']['id']);
                    localStorage.setItem('userName', data['user']['username']);
                    localStorage.setItem('firstName', data['user']['first_name']);
                    localStorage.setItem('lastName', data['user']['last_name']);
                    localStorage.setItem('userType', condUserType);
                    localStorage.setItem('imageUrl', data['image']);
                    localStorage.setItem('notAllowTaskTypeNotification', data['not_allowed_todo_notifications']);
                    localStorage.setItem('whatsappNotification', data['allow_whatsapp_notifications']);
                } else {
                    console.error('User token not found');
                }
            } catch (error) {
                console.error('An error occurred while loading user profile:', error);
            }
        };

        const fetchSubscription = async() => {
            try {
                if (userToken) {
                    let data = await API.getSubscription({'token': userToken})
                    data = data.sort((a, b) => a.id - b.id);

                    console.log(data);

                    // Iterate through the sorted data to check for additional plans
                    data.forEach((subscription, index) => {
                        if (subscription['status'] === 'True') {
                            const planType = subscription['plan_type']['plan_type'];
                            if (planType === 'Translate') {
                                setTranslateFunction(true);
                                localStorage.setItem('translateFunction', true);
                            } else if (planType === 'ChatGPT') {
                                setChatGPTFunction(true);
                                localStorage.setItem('chatGPTFunction', true);
                            } else {
                                setSubscriptionPlan(planType);
                                localStorage.setItem('subscriptionPlan', planType);
                            }
                        }
                    });
                }
            } catch (error) {
                console.error(error);
            }
        }
        
        
        fetchProfile();
        setWabaId(localStorage.getItem('waba_id'));
        setWaPhoneNumber(localStorage.getItem('waPhoneNumber'));
        updateActivePhoneNumber(wabaId);
        fetchSubscription();
        
    }, [userToken]);

    useEffect(() => {
        // UseEffect to fetch WhatsApp templates and their parameters
        const getWhatsAppTemplate = async () => {
            try {
                const response = await fetch('https://graph.facebook.com/v20.0/' + wabaId + '/message_templates?fields=name&status=APPROVED', {
                    method: 'GET',
                    headers: {
                        'Authorization': 'Bearer ' + SystemUserToken,
                    }
                });
                const resp = await response.json();
                const templateList = resp.data?.map((template) => ({
                    'value': template.name,
                    'label': template.name,
                }));
                setTemplates(templateList);
            } catch (error) {
                console.error('Error fetching templates:', error);
            }
        };

        // Fetch tasks types
        const getTaskTypes = async () => {
            if (userToken) {
                try {
                    const taskTypeData = await API.getTaskTypes({'token': userToken});
                    if (taskTypeData.length === 0) {
                        return;
                    }
                    // Reformat data
                    let option = []
                    taskTypeData.map(type => {
                        option.push(type.task_type)
                    });

                    setTodolistOption(option);
                } catch (error) {
                    console.error('Error:', error);
                    throw error;
                }
            }
        }


        getTaskTypes();
        getWhatsAppTemplate();
    }, [wabaId, SystemUserToken]);


    const handleMobileFileSelect = (file, type) => {
        handleFileChange({ target: { files: [file] } });
        setShowDropdown(false);
    };

    
    // Fetch parameters including image for the selected template
    const fetchTemplateParameters = async (templateName) => {
        try {
            const response = await fetch(`https://graph.facebook.com/v20.0/${wabaId}/message_templates?name=${templateName}`, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + SystemUserToken,
                },
            });
            const resp = await response.json();
            const components = resp.data[0]?.components || [];

            // Split components into header, body, and button types
            const parameterList = {
                header: components.filter(comp => comp.type === "HEADER").map(component => {
                    if (component.format === 'IMAGE') {
                        setWhatsappImageUrl(component.example?.header_handle[0] || null); // Store the image URL if present
                        return []; // No text parameters for image header
                    }
                    return component.text?.match(/{{(.*?)}}/g)?.map(param => ({
                        param: param.replace(/[{}]/g, ''),
                        text: component.text,
                    })) || [];
                }).flat(),
                body: components.filter(comp => comp.type === "BODY").map(component =>
                    component.text.match(/{{(.*?)}}/g)?.map(param => ({
                        param: param.replace(/[{}]/g, ''),
                        text: component.text,
                    })) || []
                ).flat(),
                button: components.filter(comp => comp.type === "BUTTON").map(component =>
                    component.parameters?.map(param => ({
                        param: param.type, // Could be 'payload' for quick reply buttons
                        text: param.payload, // or it could be button payload text
                    })) || []
                ).flat(),
            };

            setParameters(parameterList);
        } catch (error) {
            console.error('Error fetching template parameters:', error);
        }
    }; 

    const downloadAudio = (blob, filename) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename || 'audio.mp3';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    };

    const convertToAAC = async (audioBlob) => {
        if (!ffmpeg.loaded) {
            await ffmpeg.load();
        }
    
        await ffmpeg.writeFile('audio.webm', await fetchFile(audioBlob));
    
        // Convert WebM to AAC
        await ffmpeg.exec(['-i', 'audio.webm', '-c:a', 'aac', '-b:a', '64k', 'output.aac']);
    
        const data = await ffmpeg.readFile('output.aac');
        return new Blob([data.buffer], { type: 'audio/aac' });
    };

    const convertToMobileCompatibleMP3 = async (audioBlob) => {
        if (!ffmpeg.loaded) {
            await ffmpeg.load();
        }
    
        await ffmpeg.writeFile('audio.webm', await fetchFile(audioBlob));
    
        // Convert WebM to a mobile-compatible MP3
        await ffmpeg.exec([
            '-i', 'audio.webm',
            '-c:a', 'libmp3lame',
            '-b:a', '64k',
            '-ar', '44100',
            '-ac', '1',
            '-f', 'mp3',
            'output.mp3'
        ]);
    
        const data = await ffmpeg.readFile('output.mp3');
        const mp3Blob = new Blob([data.buffer], { type: 'audio/mpeg' });
        
    
        return mp3Blob;
    };

    const startRecording = async () => {
        try {
            console.log('Attempting to access microphone...');
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            console.log('Microphone access granted');
    
            // Try different MIME types in order of preference
            const mimeTypes = [
                'audio/webm',
                'audio/webm;codecs=opus',
                'audio/ogg;codecs=opus',
                'audio/mp4',
                'audio/mpeg',
            ];
    
            let selectedMimeType = null;
            for (const mimeType of mimeTypes) {
                if (MediaRecorder.isTypeSupported(mimeType)) {
                    selectedMimeType = mimeType;
                    break;
                }
            }
    
            if (!selectedMimeType) {
                throw new Error('No supported MIME type found for this browser');
            }
    
            console.log(`Using MIME type: ${selectedMimeType}`);
            
            const mediaRecorder = new MediaRecorder(stream, { mimeType: selectedMimeType });
            console.log('MediaRecorder created');
            
            mediaRecorder.ondataavailable = (event) => {
                if (event.data.size > 0) {
                    audioChunksRef.current.push(event.data);
                    console.log(`Audio chunk received, size: ${event.data.size} bytes`);
                }
            };
    
            mediaRecorder.onstop = () => {
                console.log('Recording stopped');
                const audioBlob = new Blob(audioChunksRef.current, { type: selectedMimeType });
                console.log(`Audio Blob created, size: ${audioBlob.size} bytes, type: ${audioBlob.type}`);
                setAudioBlob(audioBlob);
                setAudioPreviewUrl(URL.createObjectURL(audioBlob));
                audioChunksRef.current = [];
            };
    
            mediaRecorder.start();
            console.log('Recording started');
            setIsRecording(true);
            mediaRecorderRef.current = mediaRecorder;
        } catch (error) {
            console.error('Error accessing microphone:', error);
            toast.error('Unable to access microphone: ' + error.message);
        }
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current && isRecording) {
            mediaRecorderRef.current.stop();
            setIsRecording(false);
            
            mediaRecorderRef.current.onstop = async () => {
                console.log('Recording stopped');
                const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/webm' });
                console.log(`Audio Blob created, size: ${audioBlob.size} bytes`);
                
                // Create a preview URL for the audio
                const audioUrl = URL.createObjectURL(audioBlob);
                setAudioPreviewUrl(audioUrl);
                setAudioBlob(audioBlob);
                
                audioChunksRef.current = [];
            };
        }
    };

    const uploadAudioToWhatsApp = async (audioBlob) => {
        console.log('Starting upload to WhatsApp...');
        console.log('Audio Blob size:', audioBlob.size, 'bytes');
        console.log('Audio Blob type:', audioBlob.type);

        // Convert WebM to mobile-compatible AAC
        const aacBlob = await convertToAAC(audioBlob);
        console.log('Converted to AAC, new size:', aacBlob.size, 'bytes');

        const formData = new FormData();
        formData.append('file', aacBlob, 'audio.aac');
        formData.append('type', 'audio/aac');
        formData.append('messaging_product', 'whatsapp');

        console.log('FormData created with file and type');
    
        try {
            console.log('Sending request to WhatsApp API...');
            const response = await fetch(`https://graph.facebook.com/v19.0/${waPhoneNumberId}/media`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${SystemUserToken}`
                },
                body: formData
            });
            
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            
            const data = await response.json();
            console.log('Response data:', data);
            console.log('Media ID:', data.id);
    
            // Send the audio message using the media ID
            const sendResult = await API.sendAudioToWhatsapp(
                data.id, 
                waPhoneNumberId, 
                SystemUserToken, 
                currentParticipantPhoneNumber, 
                wabaId
            );
            console.log('Audio message sent:', sendResult);
        } catch (error) {
            console.error('Error uploading or sending audio:', error);
            toast.error('Failed to upload or send audio');
        }
    };


    
    const sendVoiceMessage = async () => {
        if (!audioBlob) return;
        // upload audio to whatsapp and get media id
        uploadAudioToWhatsApp(audioBlob);

        // Create a blob URL for the audio
        const audioUrl = URL.createObjectURL(audioBlob);
            
        // Send the audio to ChatArea
        setAudioToChatArea({
            url: audioUrl,
            senderId: wabaId,
            timestamp: new Date().toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }),
        });

        // Convert WebM to mobile-compatible MP3
        const mp3Blob = await convertToMobileCompatibleMP3(audioBlob);
        console.log('Converted to MP3, new size:', mp3Blob.size, 'bytes');

        const formData = new FormData();
        formData.append('file', mp3Blob, 'audio.mp3');
        formData.append('content_type', 'audio/mpeg');
        formData.append('messaging_product', 'whatsapp');

        formData.append('activePhoneNumber', wabaId);

        try {
            const response = await fetch(`https://streamhost-django-80bc172b3b26.herokuapp.com/api/chatroom/${activeChatRoomDetails}/messages/`, {
                method: 'POST',
                headers: {
                    'Authorization': 'Token ' + userToken
                },
                body: formData
            });

            if (response.ok) {
                const result = await response.json();
                console.log('Voice message sent successfully:', result);
                setAudioBlob(null);
                setAudioPreviewUrl(null);
            } else {
                console.error('Failed to send voice message:', response.statusText);
                toast.error('Failed to send voice message');
            }
        } catch (error) {
            console.error('Error sending voice message:', error);
            toast.error('Error sending voice message');
        }
    };


    
    // Handle template change
    const handleTemplateChange2 = (evt) => {
        setSelectedTemplate(evt.target.value);
        setInputValues({ header: {}, body: {}, button: {} });
        setWhatsappImageUrl(null);  // Reset image URL in case the new template doesn't have an image
        fetchTemplateParameters(evt.target.value); // Fetch parameters when template is selected
    };

    // Handle parameter input change
    const handleInputChange2 = (index, param, value, paramType) => {
        setInputValues(prevValues => ({
            ...prevValues,
            [paramType]: {
                ...prevValues[paramType],  // Spread the existing values for the paramType (header/body/button)
                [param]: value  // Update the specific param with the new value
            }
        }));
    };

    // Function to send WhatsApp message including image if present
    const sendWhatsAppTemplate = async () => {
        if (!selectedTemplate) {
            toast.error('Please select a template.');
            return;
        }
        setIsTemplateButtonDisabled(true);
        const headerParams = whatsappImageUrl ? [] : Object.values(inputValues.header || {});
        const bodyParams = Object.values(inputValues.body || {});
        const buttonParams = Object.values(inputValues.button || {});
    
        const templateMessage = {
            wabaId: waPhoneNumberId,
            SystemUserToken: SystemUserToken,
            toWaNumber: currentParticipantPhoneNumber,
            templateName: selectedTemplate,
            headerParams: headerParams,
            bodyParams: bodyParams,
            buttonParams: buttonParams,
            imageUrl: whatsappImageUrl || null
        };
    
        try {
            const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/send-whatsapp-template/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(templateMessage),
            });
    
            const data = await response.json();
            if (response.ok) {
                toast.success('Message sent successfully!');
                setIsTemplateButtonDisabled(false);
            } else {
                toast.error(`Failed to send message: ${data.error}`);
                setIsTemplateButtonDisabled(false);
            }
    
        } catch (error) {
            console.error('Error sending WhatsApp template message:', error);
            toast.error('Failed to send message');
            setIsTemplateButtonDisabled(false);
        }
    };
    
    


    useEffect(() => {

        const fetchLastMessage = async () => { // {{ edit_1 }}
            try {
                const lastMessage = await API.getLastMessage(activeChatRoomDetails, userToken);
                if (lastMessage.sender.waba_id === wabaId){
                    const lastMessageTime = new Date(lastMessage.timestamp); // {{ edit_3 }}
                    const currentTime = new Date();
                    const timeDifference = currentTime - lastMessageTime; // Difference in milliseconds
                    setIsTextAreaDisabled(timeDifference > 24 * 60 * 60 * 1000);  
                }
                
                
            } catch (error) {
                console.error('Error fetching last message for chatRoomId:', activeChatRoomDetails, error);
                setIsTextAreaDisabled(false); 
            }
        }; // {{ edit_2 }}

        fetchLastMessage();

    
    }, [activeChatRoomDetails]);

    

  
    // UseEffect hooks -----------------------------------------------------------------------------------------
    useEffect(() => {
        // set push notification to false
        

        if(userToken) {
            // Get data for contact edit and add contact
            const fetchBuildingAndUnit = async() => {
                try {
                    const data= await API.getChatFlowList(userToken);
                    // Massflow data
                    const filteredData = data.filter(chatflow => chatflow.type === 'massflow' && chatflow.error_status===true).map(chatflow => ({value: chatflow.id, label: chatflow.chatflow_name}))
                    setMassFlowData(data.filter(chatflow => chatflow.type === 'massflow'));
                    setMassFlowOption(filteredData);

                    // Unit data
                    let mappedUnit = {};

                    const fetchUnitNodes = async (chatflow) => {
                        try {
                            const response = await API.getNodes({ 'token': userToken, 'id': chatflow.value });
                            const unitNodes = response?.filter(node => node.node_type === 'unitNode');
                            
                            // Combine the text fields into a single array
                            const unitNodeComplete = unitNodes.reduce((acc, node) => {
                                const nodeTextArray = JSON.parse(node.text);
                                return [...acc, ...nodeTextArray];
                            }, []);
                            
                            mappedUnit[chatflow.label] = unitNodeComplete.map(node => ({ value: node, label: node }));
                        } catch (error) {
                            console.error(error);
                            throw error;
                        }
                    };

                    await Promise.all(filteredData.map(chatflow => fetchUnitNodes(chatflow)));
                    setUnitOption(mappedUnit);
                } catch(error) {
                    console.error(error);
                    throw(error);
                }
            }

            // Get contact length for limit purpose
            const getContactLength = async() => {
                try {
                    const data = await API.getClientUserList(userToken);
                    setClientUserLength(data.length);
                } catch (error) {
                    console.error(error);
                }
            }


            // Get chatroom ids
            if (phone_number) {
                // TODO: Change this to async function
                API.getChatRooms(userToken, wabaId).then(data => {
                    let found = false;
                    data.forEach(chatRoom => {
                        if (chatRoom.participants[0].phone_number === phone_number) {
                            setActiveChatRoomDetails(chatRoom.id);
                            found = true;
                            if (isMobile) {
                                setActiveView('chat');
                            }
                        }
                    });
                    if (!found) {
                        toast.error('No chat room found with the provided phone number.');
                    }
                });
            }

            fetchBuildingAndUnit();
            getContactLength();
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 867);
        };
    
        
    }, []);
  
    // This useEffect hook is use to handle dropdown menu, when user click outside the dropdown, the dropdown will be hidden
    useEffect(() => {
        function handleClickOutside(event) {
            // If the click is outside the dropdown, hide it
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
                setShowTranslateDropdown(false);
            }

            if (commandRef.current && !commandRef.current.contains(event.target)) {
                setCommandWindow(false);
            }
        }
    
        // Add the event listener
        document.addEventListener('mousedown', handleClickOutside);
    
        // Cleanup function to remove the event listener
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    useEffect(() => {
        if(editSelectedTemplate.value) {
            const fetchUnitNodes = async() => {
                try {
                    const response = await API.getNodes({'token': userToken, 'id': editSelectedTemplate.value});
                    const unitNodes = response.filter(node => node.node_type === 'unitNode');
                    // Create a Set to track unique labels
                    const uniqueLabels = new Set();
                    const uniqueUnitNodes = unitNodes.filter(node => {
                        const isUnique = !uniqueLabels.has(node.text);
                        uniqueLabels.add(node.text);
                        return isUnique;
                    });
                    setUnitOption(uniqueUnitNodes.map(node => ({value: node.id, label: node.text})));
                } catch (error) {
                    console.error(error);
                    throw error;
                }
            }
            fetchUnitNodes();
        }
    }, [editSelectedTemplate, setEditSelectedTemplate]);


    // this useEffect is use to set the wabaid and phone number when userToken changes
    useEffect(() => {
        setWabaId(localStorage.getItem('waba_id'));
		setWaPhoneNumberId(localStorage.getItem('waPhoneNumberId'));
        // Inbox page will have error when refresh cuz usertoken not properly
        API.getUsers(userToken)
            .then(fetchedUsers => {
                setUsers(fetchedUsers);
            })
            .catch(error => {
                console.error('Error:', error);
            });
        

        const fetchCommands = async () => {
            try {
                const commands = await API.fetchCommand({'token': userToken});
                setCommand(commands);
            } catch (error) {
                console.error('Error fetching commands:', error);
            }
        }
        fetchCommands();

    }, [userToken]);


    // this use effect is use to set the current participant name and phone number when active chat room details changes
    useEffect(() => {
        if (activeChatRoomDetails) {        
            API.getChatRoomMessages(activeChatRoomDetails, userToken)
                .then(details => {
                setCurrentParticipantName(details.participant_name);
                setCurrentParticipantPhoneNumber(details.participant_phone_numbers); 
                setIsChatbotActive(details.chatbot_state);
            })
            .catch(error => {
                console.error('Error:', error);
            });
        }
        setImageToChatArea('');
    }, [activeChatRoomDetails]);  

    // useEffect whenever current participant changes
    useEffect(() => {

        // Regex function
        const getUserName = input => {
            // Regular expression to match the last number in the string
            const regex = /.*\d+/;
            const match = input?.match(regex);
            
            if (match) {
                // Get the index of the last number
                const lastNumberIndex = match[0].length;
                // Return the substring after the last number
                return input.substring(lastNumberIndex).trim();
            }
            
            // Return the original string if no number is found
            return input;
        }

        // Get ClientUser
        const setEditData = async (phone_number) => {
            try {
                // Prepare the data for edit
                const data = await API.getClientUser({'phone_number': phone_number, 'token': userToken});
                setEditClientUserId(data.id);
                setEditContactName(getUserName(data.name));
                const chatflow_list = JSON.parse(data.chatflow_id.replace(/'/g, '"'));
                const checkin_list = JSON.parse(data.checkin_datetime.replace(/'/g, '"'));
                const checkout_list = JSON.parse(data.checkout_datetime.replace(/'/g, '"'));
                const unit_list = JSON.parse(data.unit_no.replace(/'/g, '"'));
                let building_list = [];
                for (let i = 0; i < chatflow_list.length; i++) {
                    const checkin_date = checkin_list[i];
                    const checkout_date = checkout_list[i];
                    const unit = unit_list[i];
                    const chatflow = await API.getChatFlow({'token': userToken, 'id': chatflow_list[i]});
                    building_list.push({
                        'id': Date.now(),
                        'visible': true,
                        'checkIn': new Date(checkin_date),
                        'checkOut': new Date(checkout_date),
                        'unit': unit,
                        'template': {'label': chatflow.chatflow_name, 'value': chatflow.id},
                    });
                }
                setEditBuildings(building_list);
            } catch (error) {
                console.error(error);
            }
        }
        if (currentParticipantPhoneNumber) {
            setEditData(currentParticipantPhoneNumber);
        }
    }, [currentParticipantPhoneNumber]);
    // ----------------------------------------------------------------------------------------------------------------
  

    // Button Functions ----------------------------------------------------------------------------------------------
    const handleChatRoomClick = (chatRoomId) => {
        logChatRoomId(chatRoomId);
        if (isMobile) {
            setActiveView('chat');
        }
    };
  
    const handleBackClick = () => {
        setActiveView('inbox');
        setActiveChatRoomDetails(null);
    };

    
    const translateText = async (text, targetLang) => {
        const apiKey = 'AIzaSyAP854a-9kEJG5PvxKd2N7czNWXNH6y76o'; // Replace with your API key
        const detectUrl = `https://translation.googleapis.com/language/translate/v2/detect?key=${apiKey}`;
        const translateUrl = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`;
        
        try {
            // Detect the language
            const detectResponse = await axios.post(detectUrl, { q: text }, {
                headers: { 'Content-Type': 'application/json' }
            });
            
            const detectedLanguage = detectResponse.data.data.detections[0][0].language;
            
            // Translate the text
            const translateResponse = await axios.post(translateUrl, {
                q: text,
                target: targetLang,
                source: detectedLanguage
            }, {
                headers: { 'Content-Type': 'application/json' }
            });
    
            return translateResponse.data.data.translations[0].translatedText;
        } catch (error) {
            console.error('Error translating text:', error);
            toast.error('Translation error');
            return text;
        }
    };
    // ----------------------------------------------------------------------------------------------------------------


    //Function to handle file change, file button
    const handleFileChange = async (event) => {
        setShowDropdown(false);
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            
            // Create a preview URL for the file
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                setPreviewUrl(e.target.result);
            };
            fileReader.readAsDataURL(file);
        }
    };

    //Funciton to handle send file
    const handleSendFile = async () => {
        if (selectedFile && !isSendingFile) {

            setIsSendingFile(true);

            const formData = new FormData();
            formData.append('image', selectedFile);
            formData.append('activePhoneNumber', wabaId);
            const baseUrl = "https://streamhost-media.s3.ap-southeast-2.amazonaws.com/chat_images/";
            setImageToChatArea(baseUrl + selectedFile.name.replace(/\s+/g, '_'));
            // setSelectedFile(null);
            // setPreviewUrl('');
            try { 
                const response = await fetch('https://streamhost-django-80bc172b3b26.herokuapp.com/api/chatroom/' + activeChatRoomDetails + '/messages/', {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Token ' + userToken  
                    },
                    body: formData
                });

                if (response.ok) {
                    const result = await response.json();
                    console.log('File uploaded successfully:', result);
                    
                    // Clear the preview after sending
                } else {
                    console.error('Failed to upload file:', response.statusText);
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            }
        }
    };

    
    //Function to set the active room id
    const logChatRoomId = (chatRoomId) => {
        setActiveChatRoomDetails(chatRoomId);
    };

    //Function to handle the input change for the chat-box
    const handleInputChange = (event) => {
        // Add command function here
        const text = event.target.value;
        if (text.startsWith('/')) {

            if (text === '/') {
                setFilterCommand(command);
            } else {
                setFilterCommand(command.filter(command => command.command.startsWith(text.slice(1))));
            }
            setCommandWindow(true);
        } else {
            setCommandWindow(false);
        }
        setMessageInput(event.target.value);
    };

   

    //declare new pop up window function for add contact
    const [isModalOpen, setIsModalOpen] = useState(false);

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleOpenAdd = () => {
        if (clientUserLength >= planContactLimit[subscriptionPlan]){
            toast.error('You have reached the maximum number of contacts for your subscription plan');
            return;
        }
        setIsModalOpen(true);
    };
    
    //Function to show the menu for file section
    const handleButtonClick = (e) => {
        setIsActive(!isActive);
        e.preventDefault(); // Prevent form submission
        setShowDropdown(!showDropdown); // Trigger the hidden file input when the button is clicked
    };


    //Function to show the menu for file section
    const handleSelection = (type) => {
        if (!isMobile) {
            // Your existing logic for desktop
            let acceptType = '';
            switch (type) {
                case 'image':
                    acceptType = 'image/*';
                    break;
                case 'video':
                    acceptType = 'video/*';
                    break;
                case 'document':
                    acceptType = '.pdf,.doc,.docx,.xls,.xlsx';
                    break;
                default:
                    acceptType = '';
            }
            fileInputRef.current.accept = acceptType;
            fileInputRef.current.click();
        }
        // For mobile, we'll let the MobileImageSelector handle the file selection
    };

    //Function to send message to whatsapp and save the message
    const sendMessageToWhatsappAndSave = async (message, phoneNumberId, SystemUserToken, currentParticipantPhoneNumber, wabaId, activeChatRoomDetails, userToken) => {
        try {
            await API.sendMessageToWhatsapp(message, phoneNumberId, SystemUserToken, currentParticipantPhoneNumber, wabaId);
            await API.saveChatRoomMessages(message, activeChatRoomDetails, userToken, wabaId );
            setMessageInput('');
        } catch (error) {
            console.error("Error sending message or saving chat room messages:", error);
        }
    }

    //This function triggers when user deletes a chatroom
    function handleChatRoomDelete() {
        setActiveChatRoomDetails(null); 
    }

    // Rerender function
    const [forceUpdate, setForceUpdate] = useState(0);

    const triggerReRender = () => {
        setForceUpdate(prev => prev + 1); // Incrementing the counter to change state
    };

    //preview file icon
    const getFileIcon = (fileType) => {
        switch (fileType) {
            case 'application/pdf':
                return <FaFilePdf />;
            case 'application/msword':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return <FaFileWord />;
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
                return <FaFileExcel />;
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
                return <FaFilePowerpoint />;
            default:
                return <FaFile />;
        }
    };

    // Function for edit contact
    const handleInboxSetting = () => {
        setIsEditModalOpen(true);
    }

    //Function to handle translate click
    const handleTranslateClick = async (lang) => {
        if (messageInput) {
            const translated = await translateText(messageInput, lang);
            setMessageInput(translated);
        }
    
        setShowTranslateDropdown(false);
    };

    //Function to handle scrolling
    const handleInput = (e) => {
        let target = e.target;
        target.style.height = 'auto';
        target.style.height = (target.scrollHeight) + 'px';
    };

    // Add todolist from plus icon
    const openTodolist = () => {
        setSelectedTag(todolistOption[0]);
        setTodolistModal(true);
    };
    const handleCloseToDoList = () => {
        setTodolistModal(false);
    };

    const openWhatsappTemplate = () => {
        setWhatsappTemplateModal(true);
    };
    const handleCloseWhatsappTemplate = () => {
        setWhatsappTemplateModal(false);
    };
    
    const addTodolist = async() => {
        if (todolistDescription === '' || selectedTag === ''){
            toast.error('Please fill in all the fields');
            return;
        }
        if (phoneError) {
            toast.error('Invalid phone number');
            return;
        }

        let title='';
        if (todolistPhone) {
            title = 'Phone: ' + todolistPhone.replace(/^\+/, '').replace(/\s+/g, '') + '\n\n' + 'Task: ' + todolistDescription;
        } else {
            title = 'Task: ' + todolistDescription;
        }

        try {
            const response = await API.addTask({
                'token': userToken,
                'task_id': 'TASK-' + Date.now(),
                'task_type': selectedTag,
                'task_title': title,
            });
            toast.success('Task added successfully');
        } catch (error) {
            toast.error('Error adding task:', error);
        }
    }

    const handlePhoneChange2 = (e) => {
        let input = e.target.value;
        if (input.length === 1 && input !== '+') {
            input = '+' + input;
        }
        setTodolistPhone(input);

        try {
            if (input) {
                const parsedNumber = parsePhoneNumber(input);
                if (isValidPhoneNumber(input)) {
                    setTodolistPhone(parsedNumber.formatInternational());
                    setPhoneError('');
                } else {
                    setPhoneError('Invalid phone number');
                }
            } else {
                setPhoneError('');
            }
        } catch (error) {
            setPhoneError('Invalid phone number');
        }
    };

    //Function to handle chatbot
    // State for toggle
    // Toggle handler
    const handleToggleChatbot = () => {
        setIsChatbotActive(!isChatbotActive);
        API.toggleChatbot(activeChatRoomDetails)
            .then((response) => {
                console.log(response);
            }).catch((error) => {
                toast.error('Error toggling chatbot:');
                console.error(error);
            });
    };


    // Function for add contact state handling ---------------------------------  
    const deleteBuilding = (index, id) => {
        console.log(buildings);
        setBuildings(prevBuildings => 
            prevBuildings.map(building => {
                console.log(building.id, id);
                return building.id === id ? { ...building, visible: false } : building;
            })
        );
        setTimeout(() => {
            setBuildings(prevBuildings => prevBuildings.filter(building => building.id !== id));
        }, 300); // This should match your transition duration
    }

    const handleAddBuilding = () => {
        setBuildings(prevBuildings => [...prevBuildings, { id: Date.now(), visible: false}]);
        setTimeout(() => {
            setBuildings(prevBuildings => 
                prevBuildings.map(building => 
                    building.visible ? building : { ...building, visible: true, sendCheckIn: false, sendCheckOut: false }
                )
            );
        }, 1);
    }

    const handlePhoneChange = (e) => {
        let input = e.target.value;
        if (input.length === 1 && input !== '+') {
            input = '+' + input;
        }
        setPhoneNumber(input);

        try {
            if (input) {
                const parsedNumber = parsePhoneNumber(input);
                if (isValidPhoneNumber(input)) {
                    setPhoneNumber(parsedNumber.formatInternational());
                    setPhoneError('');
                } else {
                    setPhoneError('Invalid phone number');
                }
            } else {
                setPhoneError('');
            }
        } catch (error) {
            setPhoneError('Invalid phone number');
        }
    };

    const handleTemplateChange = (index, selectedOption) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                template: selectedOption
            };
            return updatedBuildings;
        });
    };

    const handleUnitChange = (index, selectedOption) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                unit: selectedOption.label
            };
            return updatedBuildings;
        });
    };

    const handleCheckInDateChange = (index, date) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                checkIn: date
            };
            return updatedBuildings;
        });
    };

    const handleCheckOutDateChange = (index, date) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                checkOut: date
            };
            return updatedBuildings;
        });
    };

    const handleSendCheckIn = (index, selectedOption) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                sendCheckIn: selectedOption.target.checked
            };
            return updatedBuildings;
        });
    }

    const handleSendCheckOut = (index, selectedOption) => {
        setBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                sendCheckOut: selectedOption.target.checked
            };
            return updatedBuildings;
        });
    }

    // button function
    const addContactButton = () => {

        // 1. Validation part
        if (!contactName || !phoneNumber) {
            toast.error('Please fill in all the fields');
            return;
        }
        if (phoneError) {
            toast.error('Invalid phone number');
            return;
        }
        if (buildings.length === 0) {
            toast.error('Please add at least one building');
            return;
        }

        // Prepare data to send to API while validating form
        let checkin = [];
        let checkout = [];
        let unit = [];
        let templateLabel = [];
        let templateValue = [];
        let checkInBoolean = [];
        let checkOutBoolean = [];
        buildings.map(building => {
            if (!building.template || !building.unit || !building.checkIn || !building.checkOut) {
                    toast.error('Please fill in all the fields');
                    return;
                }

            // Check date validation
            const checkIn = new Date(building.checkIn);
            const checkOut = new Date(building.checkOut);
            if (checkIn >= checkOut) {
                toast.error('Check-out date must be after check-in date');
                return;
            }

            // If data no problem, append to list for API data preparation
            checkin.push(new Date(building.checkIn));
            checkout.push(new Date(building.checkOut));
            unit.push(building.unit);
            templateLabel.push(building.template.label);
            templateValue.push(building.template.value);
            checkInBoolean.push(building.sendCheckIn);
            checkOutBoolean.push(building.sendCheckOut);
        });


        // 2. Add contact
        const addContact = async() => {
            try {
                if (!isButtonDisabled) {
                    setIsButtonDisabled(true)
                    // Initialize the formatted contact name
                    let formattedContactName = "";
                    // Iterate over the arrays and format the contact name
                    for (let i = 0; i < templateLabel.length; i++) {
                        if (i > 0) {
                            formattedContactName += ", ";
                        }
                         // Reformat date and add user into contact
                        let checkInDate = checkin[i];
                        let checkOutDate = checkout[i];
                        let checkInDay = checkInDate.getDate().toString().padStart(2, '0');
                        const checkInMonth = (checkInDate.getMonth() + 1).toString().padStart(2, '0'); 
                        const formattedCheckInDate = `${checkInDay}/${checkInMonth}`;
                        let checkOutDay = checkOutDate.getDate().toString().padStart(2, '0');
                        const checkOutMonth = (checkOutDate.getMonth() + 1).toString().padStart(2, '0'); 
                        const formattedCheckOutDate = `${checkOutDay}/${checkOutMonth}`;

                        formattedContactName += `${templateLabel[i]} ${unit[i]} ${formattedCheckInDate}-${formattedCheckOutDate}`;
                    }

                    // Append the contact name
                    formattedContactName += ` ${contactName}`;
                    try {
                        // Assuming API.addNewContacts is your method to add a new contact
                        const response = await API.addNewContacts({
                            'token': userToken,
                            'phone': phoneNumber.replace(/\s+/g, '').replace('+', ''),
                            'waPhoneNumberId': waPhoneNumberId,
                            'username': formattedContactName,
                            'userId': userId,
                            'SystemUserToken': SystemUserToken,
                            'checkin_datetime': checkin, 
                            'checkout_datetime': checkout, 
                            'checkin_list': checkInBoolean,
                            'checkout_list': checkOutBoolean,
                            'unit_no': unit,
                            'chatflow_id': templateValue,
                            'wabaId': wabaId
                        });
                        setIsButtonDisabled(false);
                        if (response === 'User Successfully Added' || response === 'Contact added') {
                            closeModal();
                            let checkInSent = false;
                            let checkOutSent = false;

                            // Send message immediately if slide button is checked
                            // 1. Check in list
                            checkInBoolean.map(async(checkIn, index) => {
                                if (checkIn) {
                                    checkInSent = true;
                                    // Send checkin massflow message;
                                    const response = await API.massFlowAlgorithm({
                                        'token': userToken,
                                        'chatflow_id': templateValue[index],
                                        'unit': unit[index],
                                        'waba_id': wabaId,
                                        'phone_number_id': waPhoneNumberId,
                                        'to_phone_number': phoneNumber.replace(/\s+/g, '').replace('+', ''),
                                        'type': 'checkInNode'
                                    })
                                }
                            })

                            // 2. Check out list
                            checkOutBoolean.map(async(checkOut, index) => {
                                if (checkOut) {
                                    checkOutSent = true;
                                    // Send checkout massflow message
                                    const response = await API.massFlowAlgorithm({
                                        'token': userToken,
                                        'chatflow_id': templateValue[index],
                                        'unit': unit[index],
                                        'waba_id': wabaId,
                                        'phone_number_id': waPhoneNumberId,
                                        'to_phone_number': phoneNumber.replace(/\s+/g, '').replace('+', ''),
                                        'from_phone_number': waPhoneNumber,
                                        'type': 'checkOutNode'
                                    })
                                }
                            })

                            toast.success('Contact added successfully');
                            
                            if (checkInSent) {
                                toast.success('Check-in message sent successfully');
                            }
                            if (checkOutSent) {
                                toast.success('Check-out message sent successfully');
                            }
                            setIsButtonDisabled(false);
                            window.location.reload();
                        } else {
                            toast.error(response);
                            setIsButtonDisabled(false);
                        }
                    } catch (error) {
                        toast.error(error);
                        setIsButtonDisabled(false);
                    }
                }
            } catch (error) {
                console.error(error);
                throw error;
            }
        }

        addContact();
    }
    // --------------------------------------------------------------------------


    // Function for edit contact state handling ---------------------------------
    const deleteEditBuilding = (index, id) => {
        setEditBuildings(prevBuildings => 
            prevBuildings.map(building => {
                console.log(building.id, id);
                return building.id === id ? { ...building, visible: false } : building;
            })
        );
        setTimeout(() => {
            setEditBuildings(prevBuildings => prevBuildings.filter(building => building.id !== id));
        }, 300); // This should match your transition duration
    }

    const handleEditAddBuilding = () => {
        setEditBuildings(prevBuildings => [...prevBuildings, { id: Date.now(), visible: false}]);
        setTimeout(() => {
            setEditBuildings(prevBuildings => 
                prevBuildings.map(building => 
                    building.visible ? building : { ...building, visible: true }
                )
            );
        }, 1);
    }

    const handleEditTemplateChange = (index, selectedOption) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                template: selectedOption
            };
            return updatedBuildings;
        });
    };

    const handleEditUnitChange = (index, selectedOption) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                unit: selectedOption.label
            };
            return updatedBuildings;
        });
    };

    const handleEditCheckInDateChange = (index, date) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                checkIn: date
            };
            return updatedBuildings;
        });
    };

    const handleEditCheckOutDateChange = (index, date) => {
        setEditBuildings(prevBuildings => {
            const updatedBuildings = [...prevBuildings];
            updatedBuildings[index] = {
                ...updatedBuildings[index],
                checkOut: date
            };
            return updatedBuildings;
        });
    };

    const editContact = async() => {
        if (editBuildings.length === 0) {
            toast.error('Please add at least one building');
            return;
        }



        // Prepare data to send to API while validating form
        let checkin = [];
        let checkout = [];
        let unit = [];
        let templateLabel = [];
        let templateValue = [];
        let booleanList = [];
        editBuildings.map(building => {
            if (!building.template || !building.unit || !building.checkIn || !building.checkOut) {
                    toast.error('Please fill in all the fields');
                    return;
                }

            // Check date validation
            const checkIn = new Date(building.checkIn);
            const checkOut = new Date(building.checkOut);
            if (checkIn >= checkOut) {
                toast.error('Check-out date must be after check-in date');
                return;
            }

            // If data no problem, append to list for API data preparation
            checkin.push(checkIn);
            checkout.push(checkOut);
            unit.push(building.unit);
            templateLabel.push(building.template.label);
            templateValue.push(building.template.value);
            booleanList.push('False');
        });

        // Set contact Name
        // Initialize the formatted contact name
        let formattedContactName = "";
        // Iterate over the arrays and format the contact name
        for (let i = 0; i < templateLabel.length; i++) {
            if (i > 0) {
                formattedContactName += ", ";
            }
             // Reformat date and add user into contact
            let checkInDate = checkin[i];
            let checkOutDate = checkout[i];
            let checkInDay = checkInDate.getDate().toString().padStart(2, '0');
            const checkInMonth = (checkInDate.getMonth() + 1).toString().padStart(2, '0'); 
            const formattedCheckInDate = `${checkInDay}/${checkInMonth}`;
            let checkOutDay = checkOutDate.getDate().toString().padStart(2, '0');
            const checkOutMonth = (checkOutDate.getMonth() + 1).toString().padStart(2, '0'); 
            const formattedCheckOutDate = `${checkOutDay}/${checkOutMonth}`;

            formattedContactName += `${templateLabel[i]} ${unit[i]} ${formattedCheckInDate}-${formattedCheckOutDate}`;
        }

        // Append the contact name
        formattedContactName += ` ${editContactName}`;

        // Call API to edit contact
        try {
            const response = await API.editClientUser({
                'token': userToken,
                'id': editClientUserId,
                'name': formattedContactName,
                'unit_no': unit,
                'checkin_datetime': checkin,
                'checkout_datetime': checkout,
                'chatflow_id': templateValue,
            });
            setCurrentParticipantName(formattedContactName);
            setIsEditModalOpen(false);
            toast.success('Contact updated successfuly');
        } catch (error) {
            console.error(error);
            toast.error('Something wrong happened')
        }
    }
    // --------------------------------------------------------------------------

    // Search function------------------------------------------------------------
    const [searchQuery, setSearchQuery] = useState('');

    const handleSearchInputChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const placeholderText = isTextAreaDisabled 
        ? "More than 24 hours have passed since the recipient last replied to you." 
        : "Type a message...";

    return (
        <React.Fragment>

            <Helmet>
                <title>StreamHost | Inbox Page</title>
            </Helmet>

            {!isMobile || activeView === 'inbox' ? <Sidenav2 /> : null}
            <ToastContainer style={{zIndex: '100000001'}} position='bottom-center' autoClose={5000} hideProgreeBar={false} closeOnClick pauseOnFocusLoss draggable rtl={false} pauseOnHover theme='colored' transition={Bounce} /> 
            <main className="inbox-main">
                {wabaId && (
                    <div class="inbox-main-div" >
                        <div className={`inbox-column1 ${(!isMobile || activeView === 'inbox') ? 'active' : ''}`}>
                            <div class="inbox-header">
                                <h2>Inbox</h2>
                            </div>
                            <div class="search-bar">
                                <input type="text" placeholder="Search" value={searchQuery} onChange={handleSearchInputChange}/>
                                <IoMdPersonAdd onClick={handleOpenAdd} className='inbox-add-contact' style={{fontSize: '1.7rem'}}/>
                            </div>
                            <div className="inbox-list">
                                <ChatRoomList onChatRoomClick={handleChatRoomClick} onChatRoomDelete={handleChatRoomDelete} onChatRoomRerender={triggerReRender} searchQuery={searchQuery}  />
                            </div>
                        </div>
                    
                        <div className={`inbox-column2 ${(!isMobile || activeView === 'chat') ? 'active' : ''}`}>
                            {activeChatRoomDetails && (
                                <><div className="inbox-header2">
                                    {isMobile && (
                                        <button className="back-button" onClick={handleBackClick}> <IoIosArrowBack /> </button>
                                    )}
                                    <div className='main-inbox-header'>
                                            <h2>{currentParticipantName}</h2>
                                            <div className="inbox-settings-chatbot-and-threedots-btn">
                                                <FaRobot
                                                    className="inbox-settings-chatbot-btn"
                                                    style={{ fontSize: '1.25rem', color: isChatbotActive ? 'green' : 'black', display: chatGPTFunction ? 'block' : 'none' }}
                                                    onClick={handleToggleChatbot} />
                                                <BsThreeDotsVertical className='inbox-settings-btn' style={{ fontSize: '1.25rem' }} onClick={handleInboxSetting} />
                                            </div>

                                        </div>
                                    </div>
                                    <div className={`chatbot-active-bar ${!isChatbotActive ? 'chatbot-is-active' : ''}`}>
                                        <span className="icon-wrapper" role="img" aria-label="robot">
                                        <FaRobot />
                                        </span>
                                        <span className="status-text">ChatBot is active</span>
                                    </div>
                                </>
                            )}
                            <div class="chat-area">
                                {activeChatRoomDetails && (
                                    <ChatArea 
                                        chatRoomId={activeChatRoomDetails} 
                                        sendMessage={MessageToChatArea} 
                                        sendImage={ImageToChatArea} 
                                        AudioToChatArea={AudioToChatArea} 
                                        socketRef={socketRef} 
                                        isSendingFile={isSendingFile} 
                                        setIsSendingFile={setIsSendingFile} 
                                        setSelectedFile={setSelectedFile} 
                                        setPreviewUrl={setPreviewUrl}
                                    />
                                )}
                            </div> 
                            {activeChatRoomDetails && (
                                <div class="chat-box">
                                    <div className="attachment-button-container">
                                        <div className="div-chat-box-send-icon-symbol-button" >
                                            <button className={`chat-box-send-icon-symbol-button ${isActive ? 'active' : ''}`}  onClick={handleButtonClick} type="button">
                                                <FaPlus className='chat-icon'/>
                                            </button>
                                        </div>
                                        <div className="div-chat-box-send-icon-symbol-button" >
                                            <button className='chat-box-send-icon-symbol-button' onClick={() => setShowTranslateDropdown(!showTranslateDropdown)} type="button" style={{'display': translateFunction ? 'block' : 'none'}}>
                                                <BsTranslate className='chat-icon'/>
                                            </button>
                                        </div>
                                        <div className="voice-recording-controls">
                                            {!isRecording ? (
                                                <button onClick={startRecording} className="voice-record-btn">
                                                    <FaMicrophone style={{color: 'black'}}/>
                                                </button>
                                            ) : (
                                                <button onClick={stopRecording} className="voice-stop-btn">
                                                    <FaStop />
                                                </button>
                                            )}
                                            {audioPreviewUrl && (
                                                <div className="audio-preview">
                                                    <audio src={audioPreviewUrl} controls />
                                                    <button onClick={sendVoiceMessage} className="voice-send-btn">
                                                        Send to WhatsApp
                                                    </button>
                                                    <button onClick={() => {
                                                        setAudioBlob(null);
                                                        setAudioPreviewUrl(null);
                                                    }} className="voice-discard-btn">
                                                        Discard
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                        {showTranslateDropdown && (
                                                <div className={`dropdown-translate-menu-chat-box ${showTranslateDropdown ? 'show' : ''}`} ref={dropdownRef}>
                                                <ul>
                                                <li onClick={() => handleTranslateClick('zh')}>Chinese</li>
                                                <li onClick={() => handleTranslateClick('ko')}>Korean</li>
                                                <li onClick={() => handleTranslateClick('ja')}>Japanese</li>
                                                <li onClick={() => handleTranslateClick('ms')}>Malay</li>
                                                <li onClick={() => handleTranslateClick('id')}>Indonesian</li>
                                                </ul>
                                            </div>
                                        )}
                                        {showDropdown && (
                                            <div className={`dropdown-menu-chat-box ${showDropdown ? 'show' : ''}`} ref={dropdownRef}>
                                                <ul>
                                                    {isMobile ? (
                                                        <MobileImageSelector onFileSelect={handleMobileFileSelect} />
                                                    ) : (
                                                        <>
                                                            <li onClick={() => handleSelection('image')}><FaImage /> Images</li>
                                                            <li onClick={() => handleSelection('video')}><IoVideocam /> Video</li>
                                                            <li onClick={() => handleSelection('document')}><IoDocuments /> Documents</li>
                                                        </>
                                                    )}
                                                    <li onClick={() => openTodolist()}><LuListTodo />To Do List</li>
                                                    <li onClick={() => openWhatsappTemplate()}><IoIosChatboxes />Templates</li>
                                                </ul>
                                            </div>
                                        )}
                                          
                                    </div>
                                    <input
                                        type="file"
                                        style={{ display: 'none' }} // Hide the file input
                                        ref={fileInputRef}
                                        onChange={(event) => handleFileChange(event, activeChatRoomDetails, userToken, wabaId)}
                                    />
                                    {previewUrl && (
                                        <div className="media-preview">
                                            {selectedFile.type.startsWith('image/') ? (
                                                <img src={previewUrl} alt="Preview" />
                                            ) : selectedFile.type.startsWith('video/') ? (
                                                <video src={previewUrl} controls />
                                            ) : (
                                                <div>
                                                    {getFileIcon(selectedFile.type)}
                                                    <span>{selectedFile.name}</span>
                                                    <span>({(selectedFile.size / 1024).toFixed(2)} KB)</span>
                                                </div>
                                            )}
                                            <div className="send-media-container">

                                                <button onClick={() => {setSelectedFile(null); setPreviewUrl(''); setIsSendingFile(false);}}>Cancel</button>
                                                <button onClick={handleSendFile} disabled={isSendingFile}>
                                                    {isSendingFile ? 'Sending...' : 'Send File'}
                                                </button>
                                                
                                            </div>
                                        </div>
                                    )}
                                    <form onSubmit={(event) => {
                                        event.preventDefault();
                                        const textToSend = messageInput;
                                        if (textToSend.trim() === '') {
                                            toast.error('Message input cannot be empty.');
                                            return;
                                        }

                                        sendMessageToWhatsappAndSave(textToSend, waPhoneNumberId, SystemUserToken, currentParticipantPhoneNumber, wabaId, activeChatRoomDetails, userToken)
                                            .catch((error) => {
                                                console.error("Failed to send message:", error);
                                            });
                                        
                                        setMessageToChatArea(textToSend);
                                        setMessageInput('');
                                        if (textAreaRef.current) {
                                            textAreaRef.current.style.height = 'initial'; // Or any other default height, e.g., '50px'
                                        }
                                    }}>
                                        
                                        {commandWindow && (
                                            <div className="command-menu" ref={commandRef}>
                                                <ul>
                                                    {filterCommand.map((command, index) => (
                                                        <li key={index} className='command-item' onClick={() => setMessageInput(command.text_response)}>{command.command}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                        )}
                                        <textarea ref={textAreaRef} type="text" placeholder={placeholderText} value={messageInput} onChange={handleInputChange} style={{ display: audioPreviewUrl ? 'none' : 'block' ,height: textAreaHeight, maxHeight: '200px', fontSize: '1rem', cursor: isTextAreaDisabled ? 'not-allowed' : 'text' }} className='chat-box-form-input-box' inputMode="text" onInput={handleInput} disabled={isTextAreaDisabled}
                                            onKeyDown={async(event) => {
                                                // If user press enter
                                                if (event.key === 'Enter' && !event.shiftKey) {
                                                    event.preventDefault();
                                                    
                                                    // Command handle
                                                    let messageText = '';
                                                    if (messageInput.startsWith('/')) {
                                                        const commandText = messageInput.slice(1);
                                                        const commandFound = command.find(c => c.command === commandText);
                                                        if (commandFound) {
                                                            messageText = commandFound.text_response;
                                                            setMessageInput(messageText);
                                                            // Send file if file_response exist
                                                            if (commandFound.file_response.split('/').pop()!=='null'){
                                                                const response = await fetch(commandFound.file_response);
                                                                const blob = await response.blob();
                                                                const file = new File([blob], commandFound.file_response.split('/').pop(), {type: blob.type});
                                                                handleFileChange({target: {files: [file]}}, activeChatRoomDetails, userToken, wabaId);
                                                            }

                                                            return;
                                                        } else {
                                                            toast.error('Command not found')
                                                            return;
                                                        }
                                                    } else {
                                                        event.target.form.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
                                                    }
                                                }
                                        }}/>
                                        <button className='chat-box-send-icon-symbol-button' type="submit">
                                            <img className='chat-box-send-icon-symbol' src={sendSymbolImage} alt="Send" style={{display: audioPreviewUrl ? 'none' : 'block' }} />
                                        </button>
                                    </form>
                                </div>
                            )}
                        </div>
                        
                    </div>
                )}
                {!wabaId && 
                    <div className='inbox-no-wabaid link-no-account'>
                        <h2><span><PiLinkBreakBold /> No whatsapp activated yet</span></h2>
                    </div>
                }
            </main>

            {/* ToDoList Modal Section */}
            {todolistModal && (
                <div className="template-modal">
                    <div className="template-modal-container">
                        <div className="todolist-modal-content">
                            <div className="modal-header">
                                <IoIosArrowBack className='modal-backbtn' onClick={handleCloseToDoList} size={'1.5rem'}/>
                                <h2>Add To Do List</h2>
                            </div>

                            <div className="input-container">
                                <select id='todolist-tag' value={selectedTag} onChange={(e) => setSelectedTag(e.target.value)} onClick={(e) => e.stopPropagation()}>
                                    {todolistOption.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                                <label htmlFor='todolist-tag'>To-do list tag:</label>
                            </div>
                            
                            <div className="input-container">
                                <input type="tel" id="phoneInput" placeholder="Enter phone number" value={todolistPhone} onChange={(e) => handlePhoneChange2(e)}/>
                                <label htmlFor="phoneInput">Phone number (optional)</label>
                                {phoneError && <div className="error-message">{phoneError}</div>}
                            </div>

                            <div className="input-container">
                                <textarea type='text' id='task-title' placeholder='Task title' value={todolistDescription} onChange={(evt) => setTodolistDescription(evt.target.value)}/>
                                <label htmlFor='task-title'>To-do list description</label>
                            </div>
                            
                            <button onClick={addTodolist}>Add To Do List</button>
                        </div>
                    </div>
                </div>
            )}


            {/* WhatsApp Template Modal Section */}
            {whatsappTemplateModal && (
                <div className="template-modal" style={{zIndex: '100000001'}}>
                    <div className="template-modal-container">
                        <div className="template-modal-content">
                            <div className="template-modal-header">
                                <IoIosArrowBack style={{'fontSize': '1.5rem'}} className='test-modal-icon' onClick={handleCloseWhatsappTemplate}/>
                                <h3>Choose WhatsApp Template</h3>
                            </div>

                            {/* Template Dropdown */}
                            <div className="template-selection">
                                <div className="input-container">
                                    <select id='todolist-tag' value={selectedTemplate} onChange={(e) => { handleTemplateChange2(e)}} onClick={(e) => e.stopPropagation()}>
                                        {templates.map((option) => (
                                            <option key={option} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                    <label htmlFor='todolist-tag'>Template options:</label>
                                </div>
                            </div>

                            {/* Parameter Inputs */}
                            {parameters.header.length > 0 && (
                                <div className="template-parameters">
                                    <h3>Modify Header Parameters</h3>
                                    {parameters.header.map((param, index) => (
                                        <div class="input-container">
                                            <input type="text" id={"myInput" + index} placeholder="Enter contact name" value={inputValues.header?.[param.param] || ''}  onChange={(e) => handleInputChange2(index, param.param, e.target.value, 'header')}/>
                                            <label for={"myInput" + index}>{param.param}</label>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {parameters.body.length > 0 && (
                                <div className="template-parameters">
                                    <h3>Modify Body Parameters</h3>
                                    {parameters.body.map((param, index) => (
                                        <div class="input-container">
                                            <input type="text" id={"myInput" + index} placeholder="Enter contact name" value={inputValues.body?.[param.param] || ''}  onChange={(e) => handleInputChange2(index, param.param, e.target.value, 'body')}/>
                                            <label for={"myInput" + index}>{param.param}</label>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {/* Send Message Button */}
                            <button onClick={sendWhatsAppTemplate} disabled={isTemplateButtonDisabled}>Send Message</button>
                        </div>
                    </div>
                </div>
            )}




            {/*  Add contact section */}
            {isModalOpen && (
                <main className={`test-modal-main ${isModalOpen ? 'open' : ''}`}>
                    <div className='modal-container'>
                        <div className='modal'>
                            <div className='test-modal-header'>
                                <IoIosArrowBack style={{'fontSize': '1.5rem'}} className='test-modal-icon' onClick={()=>setIsModalOpen(false)}/>
                                <h2>Create new contact</h2>
                            </div>
                            <div className='test-modal-contact'>
                                <h3>Contact details</h3>
                                <div class="input-container">
                                    <input type="text" id="myInput" value={contactName} placeholder="Enter contact name" onChange={(e) => setContactName(e.target.value)}/>
                                    <label for="myInput">Enter contact name</label>
                                </div>
                                <div class="input-container">
                                    <input type="tel" id="phoneInput" placeholder="Enter phone number" value={phoneNumber} onChange={handlePhoneChange}/>
                                    <label htmlFor="phoneInput">Enter phone number</label>
                                    {phoneError && <div className="error-message">{phoneError}</div>}
                                </div>
        
                                {buildings?.map((building, index) => (
                                    <div key={index}className={`building-div ${building.visible ? 'visible' : ''}`}>
                                        <div className='building-header'>
                                            <h3>{buildings[index]['template']?.label ? buildings[index]['template']['label'] : `Building ${index + 1}`} {buildings[index]['unit'] ? buildings[index]['unit'] : ''}</h3>
                                            <MdDelete className='delete-icon' onClick={() => deleteBuilding(index, building.id)} style={{ fontSize: '1.5rem' }} />
                                        </div>
                                        <div className='building-info'>
                                            <Select className='modal-select' placeholder={'Select building'} options={massFlowOption} value={buildings[index]['template']?.value ? {'label': buildings[index]['template']?.label, 'value': buildings[index]['template']?.value} : null} onChange={(selectedOption) => handleTemplateChange(index, selectedOption)} />
                                            <Select className='modal-select' placeholder={'Select unit'} options={unitOption[buildings[index]['template']?.label]} value={buildings[index]['unit'] ? {'label': buildings[index]['unit'], 'value': buildings[index]['unit']} : null} onChange={(selectedOption) => handleUnitChange(index, selectedOption)} />
                                            <div className='test-modal-date'>
                                                <div>
                                                    <label htmlFor='checkin'>Check In</label>
                                                    <StyledDateTimePicker id='checkin' selected={buildings[index]['checkIn']} onChange={(date) => handleCheckInDateChange(index, date)} dateFormat="MMMM d, yyyy" placeholderText="Check in" />
                                                    <div className='switch-div'>
                                                        <label class="switch">
                                                            <input type="checkbox" id="toggleButton" value={buildings[index]['sendCheckIn']} onChange={(selectedOption) => handleSendCheckIn(index, selectedOption)}/>
                                                            <span class="slider-btn"></span>
                                                        </label>
                                                        <p>Send check-in details now</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <label htmlFor='checkout'>Check Out</label>
                                                    <StyledDateTimePicker id='checkout' selected={buildings[index]['checkOut']} onChange={(date) => handleCheckOutDateChange(index, date)} dateFormat="MMMM d, yyyy" placeholderText="Check out" />
                                                    <div className='switch-div'>
                                                        <label class="switch">
                                                            <input type="checkbox" id="toggleButton" value={buildings[index]['sendCheckOut']} onChange={(selectedOption) => handleSendCheckOut(index, selectedOption)}/>
                                                            <span class="slider-btn"></span>
                                                        </label>
                                                        <p>Send check-out details now</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
        
                                <div className={'test-modal-add'} onClick={handleAddBuilding}>
                                    <IoHomeSharp style={{fontSize:'1.5rem'}}/>
                                    <p>Add building</p>
                                </div>
        
                                <div className='test-modal-add-btn'>
                                    <button className='test-modal-btn' onClick={addContactButton} disabled={isButtonDisabled}>Add contact</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            )}

            {/* Edit contact section */}
            {isEditModalOpen && (
                <main className={`test-modal-main ${isModalOpen ? 'open' : ''}`}>
                    <div className='modal-container'>
                        <div className='modal'>
                            <div className='test-modal-header'>
                                <IoIosArrowBack style={{'fontSize': '1.5rem'}} className='test-modal-icon' onClick={()=>setIsEditModalOpen(false)}/>
                                <h2>Edit contact</h2>
                            </div>
                            <div className='test-modal-contact'>
                                <h3>Contact details</h3>
                                <div class="input-container">
                                    <input type="text" id="myInput" value={editContactName} placeholder="Enter contact name" onChange={(e) => setEditContactName(e.target.value)}/>
                                    <label htmlFor="myInput">Enter contact name</label>
                                </div>
                                {editBuildings?.map((building, index) => (
                                    <div key={index}className={`building-div ${building.visible ? 'visible' : ''}`}>
                                        <div className='building-header'>
                                            <h3>{editBuildings[index]['template']?.label ? editBuildings[index]['template']['label'] : `Building ${index + 1}`} {editBuildings[index]['unit'] ? editBuildings[index]['unit'] : ''}</h3>
                                            <MdDelete className='delete-icon' onClick={() => deleteEditBuilding(index, building.id)} style={{ fontSize: '1.5rem' }} />
                                        </div>
                                        <div className='building-info'>
                                            <Select className='modal-select' placeholder={'Select building'} options={massFlowOption} value={editBuildings[index]['template']?.value ? {'label': editBuildings[index]['template']?.label, 'value': editBuildings[index]['template']?.value} : null} onChange={(selectedOption) => handleEditTemplateChange(index, selectedOption)} />
                                            <Select className='modal-select' placeholder={'Select unit'} options={unitOption[editBuildings[index]['template']?.label]} value={editBuildings[index]['unit'] ? {'label': editBuildings[index]['unit'], 'value': editBuildings[index]['unit']} : null} onChange={(selectedOption) => handleEditUnitChange(index, selectedOption)} />
                                            <div className='test-modal-date'>
                                                <div>
                                                    <label htmlFor='checkin'>Check In</label>
                                                    <StyledDateTimePicker id='checkin' selected={editBuildings[index]['checkIn']} onChange={(date) => handleEditCheckInDateChange(index, date)} dateFormat="MMMM d, yyyy" placeholderText="Check in" />
                                                </div>
                                                <div>
                                                    <label htmlFor='checkout'>Check Out</label>
                                                    <StyledDateTimePicker id='checkout' selected={editBuildings[index]['checkOut']} onChange={(date) => handleEditCheckOutDateChange(index, date)} dateFormat="MMMM d, yyyy" placeholderText="Check out" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
        
                                <div className={'test-modal-add'} onClick={handleEditAddBuilding}>
                                    <IoHomeSharp style={{fontSize:'1.5rem'}}/>
                                    <p>Add building</p>
                                </div>
        
                                <div className='test-modal-add-btn'>
                                    <button className='test-modal-btn' onClick={editContact} disabled={isButtonDisabled}>{isButtonDisabled}Confirm edit</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </main>
            )}
        </React.Fragment>
    );
}

export default Inbox;


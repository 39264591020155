import React, { useState, useContext, useEffect } from 'react';
import { API } from '../../api-service';
import { UserContext } from '../../user-context';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet'; 
import { HiOutlineEyeOff } from "react-icons/hi";
import { HiOutlineEye } from "react-icons/hi";
import { Bounce, ToastContainer, toast} from 'react-toastify';
import { Link } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import '../css/Login.css';
import introVideo from '../../assets/output.mp4';

function Auth() {

    // Manage username password state
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [confirmPassword, setConfirmPassword] = useState("");
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [isLoginActive, setIsLoginActive] = useState(true);
    const [showVideo, setShowVideo] = useState(false); //

    const navigate = useNavigate();

    // Context Loading
    const { setUserToken } = useContext(UserContext); 

    useEffect(() => {
        if (localStorage.getItem('userToken')) {
            setUserToken(localStorage.getItem('userToken'));
            navigate('/main/inbox');
        }
        
    }, []);

    useEffect(() => {
        console.log(showVideo);
        console.log('videoisrunning');
        
    }, [showVideo,setShowVideo]);

    // handle button onChange
    const loginClicked = (e) => {
        if (!username || !password) {
            toast.error('Please fill in all the field');
            return
        }
        e.preventDefault();
        fetchToken();
    }

    const fetchToken = async() => {
        try {
            const response = await API.fetchToken({username, password}); // Passes in username, and password as body in HTTP request
            const resp = Object.keys(response)[0];
            if(resp === 'token') {
                const data = await API.fetchProfile(response['token']);
                if (data.active_sessions_count < 10) {
                    setUserToken(response['token']);
                    localStorage.setItem('userToken', response['token']);
                    setShowVideo(true);
                } else {
                    toast.error("You have reached the maximum number of active sessions. Please log out from another device to continue.");
                }

                // For user session error catching
            } else if(response['error'] === 'Session limit exceeded') {
                toast.error("You have reached the maximum number of active sessions. Please log out from another device to continue.");

            }else {
                toast.error("Wrong username or password! Please try again.");
            }
        } catch (error) {
            console.log(error);
            toast.error("Unexpected error occurred! Please try again later.");
        }
    }


    // Handle password visibility
    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    // Register / Login
    const toggleAuthMode = () => {
        setUsername('');
        setPassword('');
        setConfirmPassword('');
        setIsLoginActive(!isLoginActive);
    };

    const registerUser = async(event) => {
        event.preventDefault();

        if (!username || !password || !confirmPassword || !isChecked) {
            toast.error('Please fill up all the fields');
            return;
        }

        if (!validateEmail(email)) {
            toast.error('Please enter a valid email address');
            return;
        }
        
        if (!validatePassword(password)) {
            toast.error('Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character');
            return;
        }

        if (!validateString(username)) {
            toast.error('Username must contain only letters, numbers, and @/./+/-/_ characters.');
            return;
        }

        if (password !== confirmPassword) {
            toast.error("Passwords do not match");
            return;
        }

        const emailResponse = await API.checkUserExist({'email': email});
        if (emailResponse['exists'] === true) {
            toast.error('A user with that email already exists.');
            return;
        }
        
        const response = await sendRegisterEmail(email, username, password);
        if (response['username'] === 'A user with that username already exists.' ) {
            toast.error('A user with that username already exists.');
            return;
        } else if (response['password'] === "The password is too similar to the username."){
            toast.error('The password is too similar to the username.');
            return;
        }
        // Creates a user profile based on the newly created user account
        const userId = response['id'];
        const createProfile = await API.addProfile({
                                    'user_id': userId, 
                                    'first_name': '', 
                                    'last_name': '', 
                                    'company': '', 
                                    'phone_number': '', 
                                    'country': '', 
                                    'company_website': '', 
                                    'company_industry': ''
                                });
        toast.success('Registration email has been sent. Please check your email to verify your account.');
    };

    // Send email API obtained from api-service.js
    const sendRegisterEmail = async(inputEmail, inputUserName, inputPassword) => {
        const response = await API.sendRegisterEmail({'email': inputEmail, 'username': inputUserName, 'password': inputPassword, 're_password': inputPassword});
        return response;
    }

    // Function to validate password
    function validatePassword(password) {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/;
        return regex.test(password);
    }

    function validateEmail(email) {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }

    function validateString(input) {
        const regex = /^[a-zA-Z\d@.+\-_]+$/;
        return regex.test(input);
    }

    const handleVideoEnd = () => {
        navigate('/main/inbox');
    };

    return (
        <div className={`login-section ${isLoginActive ? 'login-active' : 'register-active'}`}>

            <Helmet>
                <title>StreamHost | {isLoginActive ? 'Login' : 'Register'}</title>
            </Helmet>
            
            {/* Login Section */}
            {showVideo ? (
                <div className="fullscreen-video">
                    <video id="intro-video" src={introVideo} autoPlay muted onEnded={handleVideoEnd} onError={(e) => console.error("Video error:", e)} className="video-element" />
                </div>
            ) : (
                <>
            <div className='login-col1'>
                <div className='header-login'>
                    <Link to='/'><img src={require("../../assets/transparent_logo.png")} alt="logo" className='logo'/></Link>
                </div>

                <div className='login-content'> 
                    <h1>Welcome back !</h1>
                    <p>Enter to provide high quality service to your customers</p>

                    <form className='loginInputForm' onSubmit={loginClicked}>

                        <div class="input-container">
                            <input type="text" id="loginUsername" value={username} onChange={evt=> setUsername(evt.target.value)} placeholder="Enter username" required/>
                            <label for="loginUsername">Enter your username</label>
                        </div>
                        <div className='login-password'>
                            <div class="input-container">
                                <input type={showPassword ? "text" : "password"}  id="password" value={password} onChange={evt=> setPassword(evt.target.value)} placeholder="Enter password" required/>
                                <label for="password">Enter password</label>
                            </div>
                            <span onClick={togglePasswordVisibility} className="password-toggle-icon">
                                {showPassword ? <HiOutlineEye /> : <HiOutlineEyeOff />}
                            </span>
                        </div>
                        <button className="login-button" onClick={loginClicked} type='submit'>Log in</button>
                    </form>

                    <div className='divider'>
                        <p className='login-or'>Or, Login with</p> 
                    </div>

                    <div className='google-log-in'>
                        <span className="google-login-wrapper">
                            {/* Use JWT.io to decode credential */}
                            <GoogleLogin 
                                onSuccess={(credentialResponse) => {
                                    const loginData = jwtDecode(credentialResponse?.credential);
                                    const filteredData = {'email': loginData['email'], 
                                                            'name': loginData['username'], 
                                                            'family_name': loginData['family_name'], 
                                                            'given_name': loginData['given_name'],
                                                            'picture': loginData['picture']};
                                    console.log(filteredData);
                                    API.googleRegisterUser(filteredData)
                                        .then(resp => {
                                            console.log(resp);
                                            setUserToken(resp['token']);
                                            localStorage.setItem('userToken', resp['token']);
                                            setShowVideo(true);
                                        })
                                        .catch(error => {
                                            console.error(error);
                                            toast.error('Something wrong happened !')
                                            return;
                                        });                                    
                                }}
                                onError={()=> {
                                    console.log('Login Failed');
                                    alert('Login Failed')
                                }}
                                width={'100%'}
                            />
                        </span>
                    </div>
                    <div className="remember-forgot">
                        <a onClick={toggleAuthMode}>Don't have an account?</a>
                        <Link to="/forgot-password">Forgot Password</Link>
                    </div>
                </div>
            </div>
            

            <div className='login-col2'>
                <ToastContainer position='bottom-center' autoClose={5000} hideProgreeBar={false} closeOnClick pauseOnFocusLoss draggable rtl={false} pauseOnHover theme='colored' transition={Bounce} />
            </div>


            {/* Register page */}
            <section className='register-section'>
                <div className='header-login'>
                    <Link to='/'><img src={require("../../assets/transparent_logo.png")} alt="logo" className='logo'/></Link>
                </div>

                <div className='login-content'> 
                    <h1>Sign Up</h1>
                    <p>Streamline your short rental workflow with StreamHost</p>

                    <form className='loginInputForm' onSubmit={loginClicked}>

                        <div class="input-container">
                            <input type="text" id="loginUsername" value={username} onChange={evt=> setUsername(evt.target.value)} placeholder="Enter username" required/>
                            <label for="loginUsername">Enter your username</label>
                        </div>
                        <div class="input-container">
                            <input type="text" id="loginUsername" value={email} onChange={evt=> setEmail(evt.target.value)} placeholder="Enter username" required/>
                            <label for="loginUsername">Enter your email</label>
                        </div>
                        <div className='login-password'>
                            <div class="input-container">
                                <input type={showPassword ? "text" : "password"}  id="password" value={password} onChange={evt=> setPassword(evt.target.value)} placeholder="Enter password" required/>
                                <label for="password">Enter password</label>
                            </div>
                            <span onClick={togglePasswordVisibility} className="password-toggle-icon">
                                {showPassword ? <HiOutlineEye /> : <HiOutlineEyeOff />}
                            </span>
                        </div>
                        <div className='login-password'>
                            <div class="input-container">
                                <input type={showConfirmPassword ? "text" : "password"}  id="password" value={confirmPassword} onChange={evt=> setConfirmPassword(evt.target.value)} placeholder="Confirm password" required/>
                                <label for="password">Confirm password</label>
                            </div>
                            <span onClick={toggleConfirmPasswordVisibility} className="password-toggle-icon">
                                {showConfirmPassword ? <HiOutlineEye /> : <HiOutlineEyeOff />}
                            </span>
                        </div>
                        <div className="tnc_box">
                            <input type="checkbox" className="myCheckbox" name="myCheckbox" onChange={(e) => setIsChecked(e.target.checked)}required />
                            <label htmlFor="myCheckbox"> I agree to StreamHost's <Link to='/privacypolicy'> <b>Terms & Privacy Policy</b> </Link> </label>
                        </div>

                        <button className="login-button" onClick={registerUser} type="submit">Sign up</button>
                    </form>

                    <div className="remember-forgot">
                        <a onClick={toggleAuthMode}>Already have an account?</a>
                    </div>
                </div>
            </section>
            </>
            )}
        </div>
    )
}

export {Auth};
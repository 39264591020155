import { MainHeader, MainFooter } from "../components/Layout";
import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { IoIosSend } from "react-icons/io";
import { IoIosCheckmark } from "react-icons/io";
import { FaArrowRight } from "react-icons/fa6";
import './css/Index.css';
import { Helmet } from 'react-helmet';

const Planet = ({ size, color, orbit, speed, cursorPosition }) => {
    const [angle, setAngle] = useState(Math.random() * 2 * Math.PI);
    
    useEffect(() => {
        const animatePlanet = () => {
        setAngle((prevAngle) => (prevAngle + speed) % (2 * Math.PI));
        };
    
        const intervalId = setInterval(animatePlanet, 16);
        return () => clearInterval(intervalId);
    }, [speed]);
    
    const planetStyle = {
        zIndex: -10001,
        position: 'absolute',
        width: `${size}px`,
        height: `${size}px`,
        borderRadius: '50%',
        backgroundColor: color,
        transform: `translate(${cursorPosition.x + Math.cos(angle) * orbit - size / 2}px, ${
        cursorPosition.y + Math.sin(angle) * orbit - size / 2
        }px)`,
        transition: 'transform 0.1s linear',
    };
    
    return <div style={planetStyle} />;
};

function App() {

    const navigate = useNavigate();
    const [text, setText] = useState('');
    const [wordIndex, setWordIndex] = useState(0);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isDeleting, setIsDeleting] = useState(false);
    const planets = [
        { size: 10, color: '#FFB900', orbit: 40, speed: 0.05 },
        { size: 15, color: '#3AA4F6', orbit: 50, speed: 0.03 },
        { size: 12, color: '#FF4136', orbit: 60, speed: 0.02 },
        { size: 20, color: '#7FDBFF', orbit: 70, speed: 0.01 },
      ];
    const [isVisible, setIsVisible] = useState(false);
    const words = ['Efficiency', 'Productivity', 'Competency', 'Consistency'];
    const typingSpeed = 170;
    const deletingSpeed = 130;
    const pauseTime = 2000;
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState(""); // State for user input
    const messageRef = useRef(null);
    const messagesEndRef = useRef(null);

    // Price states
    const [smallPrice, setSmallPrice] = useState(399);
    const [mediumPrice, setMediumPrice] = useState(759);
    const [largePrice, setLargePrice] = useState(1199);
    const [smallGPTToggle, setSmallGPTToggle] = useState(false);
    const [mediumGPTToggle, setMediumGPTToggle] = useState(false);
    const [largeGPTToggle, setLargeGPTToggle] = useState(false);
    const [smallTranslateToggle, setSmallTranslateToggle] = useState(false);
    const [mediumTranslateToggle, setMediumTranslateToggle] = useState(false);
    const [largeTranslateToggle, setLargeTranslateToggle] = useState(false);
    const GPT_PRICE = {
        small: 400,
        medium: 640,
        large: 800
    };

    const TRANSLATE_PRICE = {
        small: 200,
        medium: 340,
        large: 500
    };

    
    const scrollToBottom = () => {
        if (messageRef.current) {
            const scrollHeight = messageRef.current.scrollHeight;
            const height = messageRef.current.clientHeight;
            const maxScrollTop = scrollHeight - height;
            messageRef.current.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
          }
    }

    useEffect(() => {
        if (localStorage.getItem('userToken')) {
            navigate('/main/inbox/')
        }
    }, []);
    
    useEffect(() => {
        const updatePosition = (e) => {
            setPosition({ x: e.clientX, y: e.clientY });
        };

        window.addEventListener('mousemove', updatePosition);

        return () => window.removeEventListener('mousemove', updatePosition);
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
          ([entry]) => {
            // Check if the element is in view
            if (entry.isIntersecting) {
              setIsVisible(true);
            }
          },
          {
            threshold: 0.5, // Trigger when 50% of the element is visible
          }
        );
    
        if (messageRef.current) {
          observer.observe(messageRef.current);
        }
    
        return () => {
          // Cleanup observer on component unmount
          if (messageRef.current) {
            observer.unobserve(messageRef.current);
          }
        };
    }, []);

    useEffect(() => {
        const timeout = setTimeout(() => {
          const currentWord = words[wordIndex];
          
          if (!isDeleting && text === currentWord) {
            setTimeout(() => setIsDeleting(true), pauseTime);
            return;
          }
          
          if (isDeleting && text === '') {
            setIsDeleting(false);
            setWordIndex((prev) => (prev + 1) % words.length);
            return;
          }
          
          const newText = isDeleting
            ? currentWord.substring(0, text.length - 1)
            : currentWord.substring(0, text.length + 1);
          
          setText(newText);
        }, isDeleting ? deletingSpeed : typingSpeed);
    
        return () => clearTimeout(timeout);
    }, [text, isDeleting, wordIndex]);

    useEffect(() => {
        scrollToBottom();
    }, [messages]);


    useEffect(() => {
        const calculatePrice = (basePrice, gptToggle, translateToggle, gptPrice, translatePrice) => {
            let newPrice = basePrice;
            if (gptToggle) newPrice += gptPrice;
            if (translateToggle) newPrice += translatePrice;
            return newPrice;
        };

        setSmallPrice(calculatePrice(399, smallGPTToggle, smallTranslateToggle, GPT_PRICE.small, TRANSLATE_PRICE.small));
        setMediumPrice(calculatePrice(759, mediumGPTToggle, mediumTranslateToggle, GPT_PRICE.medium, TRANSLATE_PRICE.medium));
        setLargePrice(calculatePrice(1199, largeGPTToggle, largeTranslateToggle, GPT_PRICE.large, TRANSLATE_PRICE.large));

    }, [smallGPTToggle, smallTranslateToggle, mediumGPTToggle, mediumTranslateToggle, largeGPTToggle, largeTranslateToggle]);

    

    const handleButtonClick = (question) => {
        let response = "";
        if (question === "What are the pricing plans for StreamHost?") {
            response = "We provide 4 different tiers which are Free, Small, Medium, and Large. Prices are Free, RM399+/ month, RM759+/ month, RM1199+/ month. For more information, please navigate to our Pricing page.";
        } else if (question === "How does StreamHost help Short Rental Hosts?") {
            response = "StreamHost contains functions like automated check in messages, and schedule check out messages, Rule based chatbot, separated accounts for salesperson, supervisors, and more. Not only that, we provide WhatsApp solutions as Official WhatsApp Business Solution Partner, offering seamless integration of your WhatsApp Business Account to StreamHost";
        } else if (question === "What kind of chatbots does StreamHost create?") {
            response = "StreamHost has rule-based chatbots where users can customize their flow in the ChatFlow page. ChatGPT models are still under development to ensure that it fits the Short Rental Hosts’ needs.";
        }
        

        setMessages([
            ...messages,
            { user: true, text: question, isNew: true },
            { user: false, text: response, isNew: true },
        ]);
    };


    const handleSendMessage = () => {
        if (newMessage.trim() === "") return;

        const updatedMessages = [...messages, { user: true, text: newMessage.trim(), isNew: true }];
        setMessages(updatedMessages);
        setNewMessage(""); // Clear input after sending
    };
    

    return (
        <body>
            <Helmet>
                <title>WhatsApp management channel | StreamHost</title>
            </Helmet>
        
            <MainHeader/>
            <main>
                {/* Cursor animation */}
                <div className='index-container' style={{ position: 'relative', width: '100%', height: '100%'}}>
                    <div style={{ position: 'fixed', top: 0, left: 0, width: '100dvw', height: '100dvh', pointerEvents: 'none' }}>
                        {planets.map((planet, index) => (
                        <Planet key={index} {...planet} cursorPosition={position} />
                        ))}
                    </div>

                    {/* Main Hero Section */}
                    <section className="content">
                        <div className="main_text">
                            <h1>Automate your engagement and Elevate your {' '}
                                <span className="generate-text">{text}</span>
                                <span className="blink-cursor">|</span>
                            </h1>
                            <p>Elevate your short-term rental business with <span className='company-text'>StreamHost</span>, the ultimate platform designed specifically for rental hosts.</p>
                            <button onClick={() => navigate('/login')}>Start now</button>
                        </div>
                    </section>

                    {/* Product Section */}
                    {/* TODO: Add GPT function */}
                    <section id='product' className='product-section' style={{zIndex: 101}}>
                        <div className='product-header'>
                            <img src={require('../assets/transparent_logo.png')} style={{height: '3rem'}}/>
                            <div className='product-name'>
                                <h1>StreamHost</h1>
                                <img src={require('../assets/whatsapp_tick.png')} style={{height: '1.75rem', background: 'none'}}/>
                            </div>
                        </div>
                        <div className='product-chat' ref={messageRef}>
                            <div className={`bot-message-container new-message ${isVisible ? 'message-out' : 'message-invisible'}`}>
                                <div>
                                    <img src={require('../assets/transparent_logo.png')} alt="Profile" className="profile-pic" />
                                </div>
                                <div className={"bot-message"}>
                                    Welcome to StreamHost! Elevate your short-term rental business with StreamHost, the ultimate platform designed specifically for rental hosts. Reach and engage with 2.7 billion WhatsApp users effortlessly.
                                </div>
                            </div>

                            {/* Apply class to the parent of the buttons */}
                            <div className={`product-options ${isVisible ? 'message-out' : 'message-invisible'}`}>
                                <button className={'bot-btn'} onClick={() => handleButtonClick("What are the pricing plans for StreamHost?")}>
                                    What are the pricing plans for StreamHost?
                                </button>
                                <button className={'bot-btn'} onClick={() => handleButtonClick("How does StreamHost help Short Rental Hosts?")}>
                                    How does StreamHost help Short Rental Hosts?
                                </button>
                                <button className={'bot-btn'} onClick={() => handleButtonClick("What kind of chatbots does StreamHost create?")}>
                                    What kind of chatbots does StreamHost create?
                                </button>
                            </div>
                            {messages.map((msg, index) => (
                                <div key={index} className={`${msg.user ? "user-message-container" : "bot-message-container"} new-message`}>
                                    <div>
                                        {!msg.user && <img src={require('../assets/transparent_logo.png')} alt="Profile" className="profile-pic" />}
                                    </div>
                                    <div className={`${msg.user ? "user-message" : "bot-message"} ${msg.user ? "sent-message" : ""}`}>
                                        {msg.text}
                                    </div>
                                </div>
                            ))}
                            <div ref={messagesEndRef}/>
                        </div>

                        <div className='product-input'>
                            <div className='input-container'>
                                <input type="text" id="myInput" placeholder="Enter text message" value={newMessage} onChange={(e) => setNewMessage(e.target.value)} onKeyDown={(e) => { if (e.key === 'Enter' && newMessage.trim()) { handleSendMessage(); }
                             }}style={{border:'1px solid black'}}/>
                                <label for="myInput">Enter text message</label>
                            </div>
                            <button onClick={handleSendMessage} disabled={!newMessage.trim()} className="send-button">
                                <IoIosSend />
                            </button>
                        </div>
                    </section>


                    {/* Testimonial Section */}
                    <section className='testimonial-section'>
                        <p>Helping hosts to chat smarter at</p>
                        <div className='companies'>
                            <div className='logo-wrapper'>
                                <img src={require('../assets/luma_logo.png')}/>
                                <div className="nametag">LUMA Suites</div>
                            </div>
                            <div className='logo-wrapper'>
                                <img src={require('../assets/veeduhauz_logo.png')}/>
                                <div className="nametag">Veeduhauz</div>
                            </div>
                            <div className='logo-wrapper'>
                                <img src={require('../assets/alma_logo.png')}/>
                                <div className="nametag">Alma Collective Haus</div>
                            </div>
                        </div>
                    </section>

                    {/* Bento Box Section */}
                    <section className="bento-box-section" id='bento-box'>
                        <div className="bento-box-title">
                            <h1><span>Generative AI</span> and Workflow made for <span className='company-text' style={{color:'black'}}>Short Rental Hosts</span></h1>
                            <p>StreamHost unlocks the potential of AI-powered WhatsApp chats</p>
                        </div>
                        <div className="features-grid">
                            <div className="feature-item feature-hover">
                                <img src={require("../assets/transparent_logo.png")} alt="Logo" style={{width: '5rem'}}/>
                                <h1>STREAMHOST</h1>
                            </div>
                            <div className="feature-item1 feature-hover">
                                <h3>24/7</h3>
                                <p>On Call</p>
                            </div>
                            <div className="feature-item2 feature-hover">
                                <h5>Official<br></br>WhatsApp<br></br> Business<br></br> Solution Partner</h5>
                                <img src={require("../assets/whatsapp_nicon.png")} alt="icon" class="top" 
                                style={{width: '4rem'}}/>
                                <img src={require("../assets/streamhost_bento.jpg")} alt="icon" class="bottom" 
                                style={{width: '20rem'}}/>
                            </div>
                            <div className="feature-item3 feature-hover">
                                <h2>Understand Your<br></br>Customers</h2>
                                <img src={require("../assets/message_sen.jpg")} alt="Sent" 
                                />
                            </div>
                            <div className="feature-item4 feature-hover">
                                <h4>90%</h4> 
                                <p1>Cost Saved</p1>
                            </div>
                            <div className="feature-item5 feature-hover">
                                <h6>Customize Your Chatbot</h6>
                                <img src={require("../assets/bento_chatbox.jpg")} alt="Sent" 
                                />
                            </div>
                        </div>
                    </section>

                    {/* Pricing Section */}
                    <section className='pricing-section' id='pricing'>
                        <div className='pricing-header'>
                            <p><span className="gradient-text">[</span> Get started with StreamHost <span className="gradient-text">]</span></p>
                            <h1>Flexible plans , Pay monthly , Enjoy always</h1>
                        </div>

                        <div className='pricing-body'>
                            <div className='pricing-free'>
                                <div className='pricing-content-header'>
                                    <p>Free</p>
                                    <h1><span className='price'>RM 0</span> /month</h1>
                                    <button onClick={() => navigate('/login')}>Sign up now</button>
                                </div>
                                <div className='pricing-content-body'>
                                    <p><IoIosCheckmark className='tick-icon'/>25 customer contacts</p>
                                    <p><IoIosCheckmark className='tick-icon'/>Unlimited user</p>
                                    <p><IoIosCheckmark className='tick-icon'/>1 WhatsApp Business Accounts</p>
                                    <p><IoIosCheckmark className='tick-icon'/>Free onboarding support</p>
                                    <p><IoIosCheckmark className='tick-icon'/>Basic support</p>
                                </div>
                            </div>
                            {/* Small Package */}
                            <div className='pricing-small'>
                                <div className='pricing-content-header'>
                                    <p>Small</p>
                                    <h1><span className='price'>RM {smallPrice}</span> /month</h1>
                                    <button onClick={() => navigate('/login')}>Sign up now</button>
                                </div>
                                <div className='pricing-content-selection'>
                                    <div className='switch-div'>
                                        <label class="switch">
                                            <input type="checkbox" id="toggleButton" value={smallGPTToggle} onChange={() => setSmallGPTToggle(!smallGPTToggle)}/>
                                            <span class="slider-btn"></span>
                                        </label>
                                        <p>GPT Package</p>
                                    </div>
                                    <div className='switch-div'>
                                        <label class="switch">
                                            <input type="checkbox" id="toggleButton" value={smallTranslateToggle} onChange={() => setSmallTranslateToggle(!smallTranslateToggle)}/>
                                            <span class="slider-btn"></span>
                                        </label>
                                        <p>Translation Package</p>
                                    </div>
                                </div>
                                <div className='pricing-content-body'>
                                    <p><IoIosCheckmark className='tick-icon'/>5,000 customer contacts</p>
                                    <p><IoIosCheckmark className='tick-icon'/>Unlimited user</p>
                                    <p><IoIosCheckmark className='tick-icon'/>2 WhatsApp Business Accounts</p>
                                    <p><IoIosCheckmark className='tick-icon'/>Free onboarding support</p>
                                    <p><IoIosCheckmark className='tick-icon'/>Basic support</p>
                                </div>
                            </div>

                            {/* Medium package */}
                            <div className='pricing-medium'>
                                <div className='pricing-content-header'>
                                    <div className='tier'>
                                        <p>Medium</p>
                                        <div className='popular-tag'>
                                            Most Popular
                                        </div>
                                    </div>
                                    <h1><span className='price'>RM {mediumPrice}</span> /month</h1>
                                    <button onClick={() => navigate('/login')}>Sign up now</button>
                                </div>
                                <div className='pricing-content-selection'>
                                    <div className='switch-div'>
                                        <label class="switch">
                                            <input type="checkbox" id="toggleButton" value={mediumGPTToggle} onChange={() => setMediumGPTToggle(!mediumGPTToggle)}/>
                                            <span class="slider-btn"></span>
                                        </label>
                                        <p>GPT Package</p>
                                    </div>
                                    <div className='switch-div'>
                                        <label class="switch">
                                            <input type="checkbox" id="toggleButton" value={mediumTranslateToggle} onChange={() => setMediumTranslateToggle(!mediumTranslateToggle)}/>
                                            <span class="slider-btn"></span>
                                        </label>
                                        <p>Translation Package</p>
                                    </div>
                                </div>
                                <div className='pricing-content-body'>
                                    <p><IoIosCheckmark className='tick-icon'/>10,000 customer contacts</p>
                                    <p><IoIosCheckmark className='tick-icon'/>Unlimited user</p>
                                    <p><IoIosCheckmark className='tick-icon'/>2 WhatsApp Business Accounts</p>
                                    <p><IoIosCheckmark className='tick-icon'/>Free onboarding support</p>
                                    <p><IoIosCheckmark className='tick-icon'/>Advanced support</p>
                                </div>
                            </div>

                            {/* Large package */}
                            <div className='pricing-large'>
                                <div className='pricing-content-header'>
                                    <p>Large</p>
                                    <h1><span className='price'>RM {largePrice}</span> /month</h1>
                                    <button onClick={() => navigate('/login')}>Sign up now</button>
                                </div>
                                <div className='pricing-content-selection'>
                                    <div className='switch-div'>
                                        <label class="switch">
                                            <input type="checkbox" id="toggleButton" value={largeGPTToggle} onChange={() => setLargeGPTToggle(!largeGPTToggle)}/>
                                            <span class="slider-btn"></span>
                                        </label>
                                        <p>GPT Package</p>
                                    </div>
                                    <div className='switch-div'>
                                        <label class="switch">
                                            <input type="checkbox" id="toggleButton" value={largeTranslateToggle} onChange={() => setLargeTranslateToggle(!largeTranslateToggle)}/>
                                            <span class="slider-btn"></span>
                                        </label>
                                        <p>Translation Package</p>
                                    </div>
                                </div>
                                <div className='pricing-content-body'>
                                    <p><IoIosCheckmark className='tick-icon'/> 20,000 customer contacts</p>
                                    <p><IoIosCheckmark className='tick-icon'/>Unlimited user</p>
                                    <p><IoIosCheckmark className='tick-icon'/>2 WhatsApp Business Accounts</p>
                                    <p><IoIosCheckmark className='tick-icon'/>Free onboarding support</p>
                                    <p><IoIosCheckmark className='tick-icon'/>Priority support</p>
                                </div>
                            </div>
                        </div>
                    </section>


                    {/* Call to action section */}
                    <section className='call-to-action-section'>
                        <h1 className='company-text'>Sign up for free today</h1>
                        <p>Allow StreamHost to help you unlock the potential of AI-powered applications in your industry</p>

                        <div className='call-to-action-btn'>
                            <button onClick={() => navigate('/login')}>
                                Get for free
                            </button>

                            <h3 onClick={() => navigate('/contact_sales')}>Learn More <FaArrowRight className='call-to-action-right'/></h3>
                        </div>
                    </section>
                </div>
            </main>

            <MainFooter/>

        </body>
    );
}


export default App;

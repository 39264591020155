
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import App from "./pages/App";
import {Auth} from "./pages/auth/Login";
import { ResetPassword, Activate, ForgotPassword } from "./pages/auth/Auth";
import Link from "./pages/Link";
import { Settings, General, AccountManagement, QuickResponse, PlansAndBilling, PasswordAndSecurity, WhatsAppTemplate, Chatbot, NotificationSettings } from "./pages/Settings";
import Inbox from "./pages/Inbox";
import ChatFlowList from './pages/ChatFlowList';
import StreamFlow from './pages/StreamFlow';
import ToDoList from './pages/ToDoList';
import PrivacyPolicy from "./pages/PrivacyPolicy";
import { Payment, PaymentSuccess }from './pages/Payment';
import Contacts from "./pages/Contacts";
import Notification from "./pages/Notifications";
import FAQ from './pages/FAQ';
import Error from './pages/Error';
import { ContactSales } from './pages/ContactSales';

import Posts from './pages/Posts';
import PostDetail from './pages/PostDetail';
import PostDetail2 from './pages/PostDetail2';
import AuthorPosts from './pages/AuthorPosts';
import CategoryPosts from './pages/CategoryPosts';



import { createHashRouter, RouterProvider } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import Dashboard from "./pages/Dashboard";
import { UserProvider } from "./user-context";
import Pricing from "./pages/Pricing";
import { CookiesProvider } from 'react-cookie';
import { WebSocketProvider } from './pages/Websocket';

import * as serviceWorkerRegistration from './serviceWorkerRegistration';

// Create router for different pages in app
const router = createHashRouter([

    { path: "/", element: <App />,},
    { path: "/pricing", element: <Pricing />,},
    { path: "/privacypolicy", element: <PrivacyPolicy/>,},
    { path: "/FAQ", element: <FAQ />,},
    { path: "/contact_sales", element: <ContactSales />,},
    { path: "/Error", element: <Error />,},  
    { path: "/Posts", element: <Posts />,},  
    { path: "/PostDetail", element: <PostDetail />,}, 
    { path: "/PostDetail2", element: <PostDetail2 />,},   
    { path: "/AuthorPosts", element: <AuthorPosts />,},  
    { path: "/CategoryPosts/:category", element: <CategoryPosts />,},   
    
    { path: "/payment", element: <Payment/>},
    { path: "/payment/success/:session_id", element: <PaymentSuccess/>},
    
    { path: "/login", element: <Auth />,},
    { path: '/forgot-password', element: <ForgotPassword />,}, 
    { path: '/password/reset/confirm/:uid/:token', element: <ResetPassword />,},
    { path: '/activate/:uid/:token', element: <Activate />,},

    { path: "/main/link", element: <Link />,},
    { path: "/main/inbox/:phone_number?", element: <Inbox />,},
    { path: "/main/dashboard", element: <Dashboard/>,},
    { path: "/main/notifications", element: <Notification />,},
    { path: "/main/contact", element: <Contacts />,},
    { path: '/main/chatflow', element: <ChatFlowList/> },
    { path: '/main/streamflow/:chatflow_id/:chatflow_name/:chatflow_type', element: <StreamFlow/>},
    { path: '/main/todolist', element: <ToDoList/>},
    { path: "/main/settings", element: <Settings />,},
    { path: "/main/settings/general", element: <General />,},
    { path: '/main/settings/account-management', element: <AccountManagement />,},
    { path: '/main/settings/quick-response', element: <QuickResponse />,},
    { path: '/main/settings/whatsapp-template', element: <WhatsAppTemplate />,},
    { path: "/main/settings/plans-and-billing", element: <PlansAndBilling/>,},
    { path: "/main/settings/password-and-security", element: <PasswordAndSecurity/>,},
    { path: "/main/settings/chatbot", element: <Chatbot />,},
    { path: "/main/settings/notification-settings", element: <NotificationSettings />,},
    

    // Catch-all route for 404 errors
    { path: "*", element: <Error /> }

])

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // Removed React.StrictMode
    <GoogleOAuthProvider clientId="8799379593-mrrjmk5rbq6o6sqhm7pui599cc7iioa7.apps.googleusercontent.com">
        <CookiesProvider>
            <UserProvider>
                <WebSocketProvider> {/* Wrap RouterProvider with WebSocketProvider */}
                    <RouterProvider router={router} />
                </WebSocketProvider>
            </UserProvider>
        </CookiesProvider>
    </GoogleOAuthProvider>
);

// Register the service worker for PWA support and push notifications
serviceWorkerRegistration.register();
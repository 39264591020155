import React, { useEffect, useState, useContext, useRef} from 'react';
import { UserContext } from "../user-context.js";
import axios from 'axios';
import { API } from "../api-service.js";
import './css/ChatArea.css';
import { Globe, Play, Pause } from 'lucide-react';
import { Bounce, ToastContainer, toast} from 'react-toastify';

// ChatBubble Component
function ChatBubble({ message, isRightAligned, formattedTime }) {
    const { translateFunction } = useContext(UserContext);
    const [translatedText, setTranslatedText] = useState('');
    const [showContextMenu, setShowContextMenu] = useState(false);
    const [contextMenuPosition, setContextMenuPosition] = useState({ x: 0, y: 0 });
    const [currentLanguage, setCurrentLanguage] = useState(null); // Track current translation language
    const [showErrorMessage, setShowErrorMessage] = useState(false); // Error message state
    const isTextMessage = message.media_type === 'text';
    const [isMobile, setIsMobile] = useState(false);
    const bubbleRef = useRef(null);
    const [progress, setProgress] = useState(0);
    const [isPlaying, setIsPlaying] = useState(false);
    const audioRef = useRef(null);
    const [audioMimeType, setAudioMimeType] = useState(null);
    const [playbackError, setPlaybackError] = useState(null);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 867);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        document.addEventListener('click', handleClickOutside);
        window.addEventListener('scroll', handleClickOutside, true);

        return () => {
            window.removeEventListener('resize', handleResize);
            document.removeEventListener('click', handleClickOutside);
            window.removeEventListener('scroll', handleClickOutside, true);
        };
    }, []);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        window.addEventListener('scroll', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside);
            window.removeEventListener('scroll', handleClickOutside, true);
            
        };
    }, []);

    // useEffect(() => {
    //     if (message.media_type === 'audio' && audioRef.current) {
    //         audioRef.current.onerror = (e) => {
    //             let errorMessage;
    //             const mediaError = e.target.error;
    
    //             if (mediaError) {
    //                 switch (mediaError.code) {
    //                     case mediaError.MEDIA_ERR_ABORTED:
    //                         errorMessage = 'You aborted the media playback.';
    //                         break;
    //                     case mediaError.MEDIA_ERR_NETWORK:
    //                         errorMessage = 'A network error caused the audio download to fail.';
    //                         break;
    //                     case mediaError.MEDIA_ERR_DECODE:
    //                         errorMessage = 'The audio format is unsupported or the media file is corrupt.';
    //                         break;
    //                     case mediaError.MEDIA_ERR_SRC_NOT_SUPPORTED:
    //                         errorMessage = 'The audio source is not supported by the browser.';
    //                         break;
    //                     default:
    //                         errorMessage = 'An unknown error occurred during playback.';
    //                         break;
    //                 }
    //             } else {
    //                 errorMessage = 'Unknown error during audio playback.';
    //             }
    
    //             setPlaybackError(errorMessage); // Update your state with the error message
    //             console.error('Audio playback error:', errorMessage);
    //             alert(`Unable to play audio: ${errorMessage}`);
    //         };
    //     }
    // }, [message]);

    useEffect(() => {
        if (playbackError) {
            toast.error(`Unable to play audio: ${playbackError}`, {
                toastId: 'audio-error', // Prevents duplicate toasts
            });
        }
    }, [playbackError]);


    const renderTextWithLineBreaks = (text) => {
        return text.split('\n').map((line, index) => (
            <React.Fragment key={index}>
                {line}
                {index < text.split('\n').length - 1 && <br />}
            </React.Fragment>
        ));
    };

    

    const translateText = async (text, targetLang) => {
        const apiKey = 'AIzaSyAP854a-9kEJG5PvxKd2N7czNWXNH6y76o'; // Replace with your actual API key
        const translateUrl = `https://translation.googleapis.com/language/translate/v2?key=${apiKey}`;

        try {
            const translateResponse = await axios.post(translateUrl, {
                q: text,
                target: targetLang,
            });

            return translateResponse.data.data.translations[0].translatedText;
        } catch (error) {
            console.error('Error translating text:', error);
            return 'Translation error';
        }
    };

    const handleClick = (e) => {
        if (isMobile) {
            e.preventDefault();
            if (!translateFunction) return;

            const rect = e.target.getBoundingClientRect();
            setContextMenuPosition({ x: rect.left, y: rect.bottom });
            setShowContextMenu(true);
        }
    };

    const handleContextMenu = (e) => {
        if (!isMobile) {
            e.preventDefault();
            if (!translateFunction) return;

            setContextMenuPosition({ x: e.clientX, y: e.clientY });
            setShowContextMenu(true);
        }
    };

    const handleTranslate = async (targetLang) => {
        if (currentLanguage !== targetLang) {
            const textToTranslate = message.text || message.image;
            const translated = await translateText(textToTranslate, targetLang);
            setTranslatedText(translated);
            setCurrentLanguage(targetLang);
        } else {
            setTranslatedText(message.text || message.image);
            setCurrentLanguage(null);
        }
        setShowContextMenu(false);
    };

    const handleClickOutside = (event) => {
        if (bubbleRef.current && !bubbleRef.current.contains(event.target)) {
            setShowContextMenu(false);
        }
    };

    const updateProgress = () => {
        if (audioRef.current) {
            const { currentTime, duration } = audioRef.current;
            const progressPercent = (currentTime / duration) * 100;
            setProgress(progressPercent);
        }
    };

    const seekAudio = (e) => {
        if (audioRef.current) {
            const progressBar = e.currentTarget;
            const clickPosition = (e.pageX - progressBar.offsetLeft) / progressBar.offsetWidth;
            audioRef.current.currentTime = clickPosition * audioRef.current.duration;
        }
    };

    const renderContent = () => {
        switch (message.media_type) {
            case 'image':
                const imageUrl = String(message.image);
                const imageExtension = imageUrl.split('.').pop().toLowerCase();
                return ['jpg', 'jpeg', 'png', 'gif'].includes(imageExtension) ?
                    <img src={message.image} alt="file" className="chat-bubble-image" /> :
                    <p>{imageUrl}</p>;

            case 'video':
                const videoUrl = String(message.image);
                const videoExtension = videoUrl.split('.').pop().toLowerCase();
                return ['mp4', 'webm', 'ogg'].includes(videoExtension) ?
                    <video controls className="chat-bubble-video">
                        <source src={videoUrl} />
                        Your browser does not support the video tag.
                    </video> :
                    <p>Unsupported video type</p>;

            case 'document':
                const documentUrl = String(message.image);
                const documentName = documentUrl.split('/').pop();
                return (
                    <a href={documentUrl} target="_blank" rel="noopener noreferrer" className="chat-bubble-document">
                        <i className="document-icon"></i>
                        <span>{documentName}</span>
                    </a>
                );
            case 'audio':
                return (
                    <div >
                        {isMobile ? (
                            <div className="chat-bubble-audio-mobile">
                                <audio src={message.audio || message.image}  controls />
                                
                            </div>
                        ) : (
                            <div className="chat-bubble-audio">
                                <audio 
                                    ref={audioRef} 
                                    src={message.audio || message.image} 
                                    onTimeUpdate={updateProgress}
                                    onEnded={() => setIsPlaying(false)} 
                                />
                                <button onClick={toggleAudio} className="audio-control-button">
                                    {isPlaying ? <Pause size={24} /> : <Play size={24} />}
                                </button>
                                <div className="audio-progress-container" onClick={seekAudio}>
                                    <div 
                                        className="audio-progress-bar" 
                                        style={{ width: `${progress}%` }}
                                    ></div>
                                </div>
                            </div>
                        )}
                    </div>
                );

            case 'text':
            default:
                return <p className="chat-bubble-message">{renderTextWithLineBreaks(currentLanguage ? translatedText : message.text)}</p>;
        }
    };

    const toggleAudio = async () => {
        try {
            if (audioRef.current) {
                if (isPlaying) {
                    await audioRef.current.pause();
                } else {
                    await audioRef.current.play();
                }
                setIsPlaying(!isPlaying);
            }
        } catch (error) {
            console.error('Error toggling audio:', error);
            toast.error(`Error playing audio: ${error.message}`);
        }
    };

    return (
        <div 
            ref={bubbleRef}
            className={`chat-bubble ${isRightAligned ? 'chat-bubble-right' : 'chat-bubble-left'}`} 
            onContextMenu={handleContextMenu}
            onClick={handleClick}
        >
            <div className="chat-bubble-wrapper">
                {message.error && (
                    <div 
                        className="chat-bubble-error"
                        onMouseEnter={() => setShowErrorMessage(true)}
                        onMouseLeave={() => setShowErrorMessage(false)}
                    >
                        <span>!</span>
                        {showErrorMessage && (
                            <div className="error-tooltip">
                                {message.error}
                            </div>
                        )}
                    </div>
                )}
                <div className={`chat-bubble-content ${isTextMessage ? 'text' : 'media'}`}>
                    {renderContent()}
                    <div className="chat-bubble-time">{formattedTime}</div>
                </div>
            </div>
            {showContextMenu && (
                <div 
                    className="context-menu" 
                    style={{ 
                        position: 'fixed', 
                        top: `${contextMenuPosition.y}px`, 
                        left: `${contextMenuPosition.x}px`,
                        zIndex: 1000
                    }}
                >
                    {!currentLanguage && (
                        <>
                            <button onClick={() => handleTranslate('en')} className="translate-button">
                                <Globe size={16} />
                                <span>Translate to English</span>
                            </button>
                            <button onClick={() => handleTranslate('id')} className="translate-button">
                                <Globe size={16} />
                                <span>Translate to Indonesian</span>
                            </button>
                            <button onClick={() => handleTranslate('ms')} className="translate-button">
                                <Globe size={16} />
                                <span>Translate to Malay</span>
                            </button>
                        </>
                    )}
                    {currentLanguage && (
                        <button onClick={() => handleTranslate(currentLanguage)} className="translate-button">
                            <Globe size={16} />
                            <span>Show Original</span>
                        </button>
                    )}
                </div>
            )}
        </div>
    );
}

function ChatArea({ chatRoomId, sendMessage, sendImage, AudioToChatArea, isSendingFile, setIsSendingFile, setSelectedFile, setPreviewUrl }) {

    const socketRef = useRef();
    const { wabaId, waPhoneNumberId, SystemUserToken, userToken, chatGPTFunction } = useContext(UserContext);
    const [chatRoomDetails, setChatRoomDetails] = useState([]);
    const [chatRoomDetails2, setChatRoomDetails2] = useState([]); //dummy state to force re-render one time
    const [currentParticipantPhoneNumber, setCurrentParticipantPhoneNumber] = useState();
    const [currentMessages, setCurrentMessages] = useState([]);
    const chatAreaRef = useRef(null);

    const [hasMore, setHasMore] = useState();
    const [page, setPage] = useState(1);

    const [fetchedMessages, setFetchedMessages] = useState([]); // New state to hold fetched messages

    const fetchChatRoomMessages = async(chatRoomId, userToken) => {
        try {
            const response = await API.getChatRoomMessagesNew(chatRoomId, userToken, 1)
            console.log(response)
            setChatRoomDetails(response.messages ?? []);
            setChatRoomDetails2(response.messages ?? []); // Ensure it's an array
            setHasMore(response.next);
            setCurrentParticipantPhoneNumber(response.participant_phone_numbers); 
        } catch (error) {
            console.error(error);
        }
    }
    
    const handleScroll = async () => {
        
        if (chatAreaRef.current.scrollTop === 0) {
            
            if (hasMore) {
                return fetch(hasMore, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Token ${userToken}`  // Include the user token in the Authorization header
                    }
                }).then(resp => resp.json())
                    .then(data => {
                        console.log(data)
                        setHasMore(data.next);
                        setFetchedMessages(data.messages); 
                       
                        setPage(page + 1); 
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            }
        }
    };

    useEffect(() => {
        if (fetchedMessages.length > 0) {
            setChatRoomDetails(prevMessages => [...prevMessages, ...fetchedMessages]);
            setFetchedMessages([]) // Update chatRoomDetails when fetchedMessages changes
        }
    }, [hasMore]);

    useEffect(() => {
        if (AudioToChatArea) {
            const newMessage = {
                audio: AudioToChatArea.url,
                senderId: AudioToChatArea.senderId,
                timestamp: AudioToChatArea.timestamp,
                media_type: 'audio',
            };
            setCurrentMessages((prevMessages) => [...prevMessages, newMessage]);
        }
    }, [AudioToChatArea]);


    useEffect(() => {
        setCurrentMessages([]); 
        setHasMore(false);
        setPage(1);
        socketRef.current = new WebSocket(`wss://streamhost-django-80bc172b3b26.herokuapp.com/ws/chat/${chatRoomId}/`);
        
        fetchChatRoomMessages(chatRoomId, userToken);
        
        socketRef.current.onopen = () => {
            console.log("[chatArea] Connection established");
            setInterval(function() {
              if (socketRef.current.readyState === WebSocket.OPEN) {
                // send a "heartbeat" message every 30 seconds
                const heartbeatMessage = JSON.stringify({ message: 'heartbeat', senderId: wabaId, chatRoomId: chatRoomId, message_type: "heartbeat"});
                socketRef.current.send(heartbeatMessage);
              }
            }, 60000);
        };
        

        socketRef.current.onerror = (event) => {
            console.error('WebSocket error:', event);
        };
        

        socketRef.current.onclose = function(event) {
            if (event.wasClean) {
              console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
            } else {
              // e.g. server process killed or network down
              // event.code is usually 1006 in this case
              console.log('[close] Connection died');
            }
          };
          

        // Clean up the WebSocket connection when the component unmounts or the chat room ID changes
        return () => {
            socketRef.current.close();
        };
            
    }, [chatRoomId, userToken, wabaId]);

    useEffect(() => {
        
        if (socketRef.current) {
            socketRef.current.onmessage = (event) => {
                const message = JSON.parse(event.data);
                const currentTime = new Date();
                const baseUrl = "https://streamhost-media.s3.ap-southeast-2.amazonaws.com/";
                const formattedCurrentTime = currentTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
               
               
                // Check message type and prepend URL if it's a file
                let messageType = message.message_type;

                let messageContent = message.message;
                if (message.message === 'heartbeat') {
                    return;
                }
                
                if (messageType === 'error_message' ) {
                    let error_code_message = messageContent;
                    setCurrentMessages((prevMessages) => {
                        const updatedMessages = [...prevMessages];
                        if (messageType === 'error_message' && updatedMessages.length > 0) {
                            // Modify the last message
                            updatedMessages[updatedMessages.length - 1] = {
                                ...updatedMessages[updatedMessages.length - 1],
                                error: error_code_message
                            };
                        }
                        // Add the new message
                        return [...updatedMessages];
                    });
                    API.modifyErrorMessage(chatRoomId, userToken, error_code_message);
                }
                else if (messageType === 'text') {
                    const newMessage = {
                        text: messageContent,
                        senderId: message.senderId,
                        timestamp: formattedCurrentTime,
                        media_type: messageType
                    };
                    setCurrentMessages((prevMessages) => [...prevMessages, newMessage]);
                }
                else {
                    const newMessage = {
                        text: messageContent,
                        senderId: message.senderId,
                        timestamp: formattedCurrentTime,
                        media_type: messageType,
                        image: baseUrl + messageContent
                    };

                    setCurrentMessages((prevMessages) => [...prevMessages, newMessage]);
                }

                
              
            };
        }
    }, [currentParticipantPhoneNumber, chatRoomId, userToken]);

    useEffect(() => {
        // Attach the scroll event listener
        const chatAreaElement = chatAreaRef.current;
        if (chatAreaElement) {
            chatAreaElement.addEventListener('scroll', handleScroll);
        }

        // Cleanup the event listener
        return () => {
            if (chatAreaElement) {
                chatAreaElement.removeEventListener('scroll', handleScroll);
            }
        };
    }, [handleScroll]); // Empty dependency array means this effect runs once on mount

    useEffect(() => {
        // Scroll to bottom whenever chatRoomDetails or currentMessages change
        if (chatAreaRef.current ) {
            chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
        }
    }, [chatRoomDetails2, currentMessages]);
    
    useEffect(() => {
        if (sendMessage !== null && sendMessage !== '') {
            const currentTime = new Date();
            const formattedCurrentTime = currentTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
            const newMessage = {
                text: sendMessage,
                senderId: wabaId,
                timestamp: formattedCurrentTime,
                media_type: 'text',
            };
            setCurrentMessages((prevMessages) => [...prevMessages, newMessage]);
        }
    }, [sendMessage, wabaId]);

   

    useEffect(() => {
        const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

        const checkImageUrl = async (url, maxAttempts = 7, delayMs = 2000) => {
            for (let attempt = 1; attempt <= maxAttempts; attempt++) {
                try {
                    const response = await fetch(url, { method: 'HEAD' });
                    if (response.ok) {
                        console.log(`Attempt ${attempt}: URL check successful`);
                        return true;
                    }
                    console.log(`Attempt ${attempt}: URL not ready (status: ${response.status})`);
                } catch (error) {
                    console.error(`Attempt ${attempt}: Error checking media URL:`, error);
                }
                
                if (attempt < maxAttempts) {
                    console.log(`Retrying in ${delayMs / 500} seconds...`);
                    await delay(delayMs);
                }
            }
            console.error(`Failed to validate URL after ${maxAttempts} attempts`);
            return false;
        };
    
        const sendMediaToWhatsApp = async () => {
            if (!sendImage) return;
    
            // Extract the file extension
            const fileExtension = sendImage.split('.').pop().toLowerCase();
            const imageExtensions = ['jpg', 'jpeg', 'png', 'gif'];
            const videoExtensions = ['mp4', 'avi', 'mov', 'wmv'];
            const docExtensions = ['pdf', 'doc', 'docx', 'xls', 'xlsx'];
    
            let mediaType = '';
    
            
    
            // Check if the URL is valid
            const isUrlValid = await checkImageUrl(sendImage);
            if (!isUrlValid) {
                console.error('Invalid media URL:', sendImage);
                return;
            }
    
            try {
                // Determine the media type based on the file extension
                if (imageExtensions.includes(fileExtension)) {
                    await API.sendImageToWhatsapp(sendImage, waPhoneNumberId, SystemUserToken, currentParticipantPhoneNumber, wabaId);
                    console.log(sendImage);
                    mediaType = 'image';
                } else if (videoExtensions.includes(fileExtension)) {
                    await API.sendVideoToWhatsapp(sendImage, waPhoneNumberId, SystemUserToken, currentParticipantPhoneNumber, wabaId);
                    mediaType = 'video';
                } else if (docExtensions.includes(fileExtension)) {
                    await API.sendDocumentsToWhatsapp(sendImage, waPhoneNumberId, SystemUserToken, currentParticipantPhoneNumber, wabaId);
                    mediaType = 'document';
                } else {
                    console.error('Unsupported file extension:', fileExtension);
                    return; // Exit if the file extension is not supported
                }
    
                const currentTime = new Date();
                const formattedCurrentTime = currentTime.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                
                const newMessage = {
                    senderId: wabaId,
                    timestamp: formattedCurrentTime,
                    media_type: mediaType,
                    image: sendImage // Dynamically set the media property
                };
                console.log(newMessage);
                setCurrentMessages((prevMessages) => [...prevMessages, newMessage]);
            } catch (error) {
                console.error('Error sending media to WhatsApp:', error);
            } finally {
                setIsSendingFile(false); // Re-enable the send file button
                setSelectedFile(null); // Clear the selected file
                setPreviewUrl('');  // Re-enable the send file button
            }
        };
    
        if (isSendingFile) {
            sendMediaToWhatsApp();
        }
    
    }, [sendImage, SystemUserToken, currentParticipantPhoneNumber, wabaId, waPhoneNumberId, setIsSendingFile, setSelectedFile, setPreviewUrl]);



    return (
        
        <div className='chat-area-container'>
            
            <div className='chat-area' ref={chatAreaRef} onScroll={handleScroll}>
                <div className='chat-area-section'>
                    {chatRoomDetails?.sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)).map((message, index) => {
                        const timestamp = new Date(message.timestamp);
                        const formattedTime = timestamp.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
                        
                        return <ChatBubble key={index}  message={message} isRightAligned={!message.sender.phone_number} formattedTime={formattedTime} />;
                    })}
                    {currentMessages.map((message, index) => {
                        return <ChatBubble key={`current-${index}`} message={message} isRightAligned={message.senderId === wabaId} formattedTime={message.timestamp} />;
                    })}
                </div>
            </div>
        </div>
    );
}

export default ChatArea;
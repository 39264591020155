import React, { useContext, useEffect, useState } from 'react';
import { FaBell, FaBellSlash } from 'react-icons/fa';
import { UserContext } from "../user-context.js";
import { API } from '../api-service';
import { Bounce, ToastContainer, toast} from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';


const CheckNotificationSubscription = () => {
    const { profileId } = useContext(UserContext);
    const [isSubscriptionInData, setIsSubscriptionInData] = useState(false)
    const [isSubscribed, setIsSubscribed] = useState(false);

    const deviceId = localStorage.getItem('deviceId');
    useEffect(() => {
        const checkSubscriptionStatus = async () => {
            try {
                const registration = await navigator.serviceWorker.ready;
                const sub = await registration.pushManager.getSubscription();
                
                if (profileId) {    
                    API.getNotificationSubscription(profileId).then((data) => {
                        if (sub && deviceId) {
                            console.log("data:", data);
                            console.log("deviceId: ", deviceId)
                            // Check if the current subscription is in the data
                            const isSubInData = data.some(item => item.deviceId === deviceId);
                            console.log('Is subscription already in data:', isSubInData);
                            
                            // You can use this boolean to update your UI or state as needed
                            setIsSubscriptionInData(isSubInData);
                            setIsSubscribed(isSubInData);
                        }
                    });
                }
              
            } catch (error) {
              console.error('Error checking subscription status:', error);
            }
        };
    
        checkSubscriptionStatus();
    }, [profileId]);


    // Notification API
    const subscribeToPushNotifications = async (profileId) => {
        if ('serviceWorker' in navigator && 'PushManager' in window) {
            try {
                // Register service worker
                const registration = await navigator.serviceWorker.register('/sw.js');
                console.log('Service Worker registered');
        
                // Get VAPID public key
                const response = await fetch(`https://streamhost-django-80bc172b3b26.herokuapp.com/api/vapidPublicKey/`);
                const { publicKey } = await response.json();
        
                // Subscribe to push notifications
                const subscription = await registration.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(publicKey)
                });
                console.log("subscription", subscription);
                console.log("profileId", profileId);

                // Generate a unique device ID and store it in local storage
                const deviceId = uuidv4();
                localStorage.setItem('deviceId', deviceId);

                // Send subscription to backend
                console.log(isSubscribed);
                const response2 = await fetch(`https://streamhost-django-80bc172b3b26.herokuapp.com/api/subscribe/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    'subscription': subscription.toJSON(), // Convert subscription to JSON
                    'profileId': profileId,
                    'isSubscribed': !isSubscribed,
                    'deviceId': deviceId
                })
                });
        
                if (!response2.ok) {
                    const errorData = await response2.json();
                    throw new Error(errorData.error || 'Failed to subscribe to push notifications');
                }
        
                const result = await response2.json();
                console.log('Push notification subscription result:', result);
                return result;
            } catch (error) {
                console.error('Error subscribing to push notifications:', error);
                throw error;
            }
        } else {
            console.error('Push notifications are not supported in this browser');
            throw new Error('Push notifications not supported');
        }
    }
      
    function urlBase64ToUint8Array(base64String) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/\-/g, '+')
            .replace(/_/g, '/');
        
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
        
        for (let i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }


    const handleSubscribe = async () => {
        setIsSubscribed(!isSubscribed);
        if (Notification.permission === 'denied') {
            alert('You have blocked notifications. Please enable them in your browser settings to subscribe.');
            return;
        }

        if (Notification.permission === 'default') {
            const permission = await Notification.requestPermission();

            if (permission === 'denied') {
                alert('You have denied notifications. Please enable them in your browser settings to subscribe.');
                return;
            }
        }

        try {
            await subscribeToPushNotifications(localStorage.getItem('profileId')).catch(error => {
                toast.error('Error subscribing to push notifications:', error);
                return;
            });
            toast.success('Notification Enabled');
          
        } catch (error) {
            toast.error('Error subscribing to push notifications:', error);
        }
      };




    return (
        <div>            
            <div className='switch-div'>
                <label className="switch">
                    <input type="checkbox" id="toggleButton" checked={isSubscribed ? isSubscriptionInData: isSubscribed} onChange={(e) => {handleSubscribe()}}/>
                    <span className="slider-btn"></span>
                </label>
                <p style={{fontSize:'1rem'}}>Enable Notification</p>
            </div>
        </div>
    )
}

export default CheckNotificationSubscription